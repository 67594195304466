// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
var firebase = require("firebase/app");

// Add the Firebase products that you want to use
require("firebase/auth");
require("firebase/firestore");
require("firebase/storage");

const firebaseConfig = 
{
   apiKey: "AIzaSyARfLt_gU74F3OM0211y8bElCuWNLbmGRQ",
  authDomain: "eduarticleanalysis.firebaseapp.com",
  projectId: "eduarticleanalysis",
  storageBucket: "eduarticleanalysis.appspot.com",
  messagingSenderId: "271817134591",
  appId: "1:271817134591:web:b00948177b8f228e7d57ca",
  measurementId: "G-2TSZNPVK4Z"
};


// Initialize Firebase
firebase.initializeApp(firebaseConfig);


export default firebase;