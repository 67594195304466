import {SystemType} from "Util/SystemType.js";
import Buttons from "views/Components/Buttons.js";
import Calendar from "views/Calendar/Calendar.js";
import Charts from "views/Charts/Charts.js";
import Dashboard from "views/Dashboard/Dashboard.js";
import ErrorPage from "views/Pages/ErrorPage.js";
import ExtendedForms from "views/Forms/ExtendedForms.js";
import ExtendedTables from "views/Tables/ExtendedTables.js";
import FullScreenMap from "views/Maps/FullScreenMap.js";
import GoogleMaps from "views/Maps/GoogleMaps.js";
import GridSystem from "views/Components/GridSystem.js";
import Icons from "views/Components/Icons.js";
import LockScreenPage from "views/Pages/LockScreenPage.js";
import AdminLoginPage from "views/Login/AdminLoginPage.js";
import StaffLoginPage from "views/Login/StaffLoginPage.js";
import CustomerPage from "views/Login/CustomerPage.js";

import Notifications from "views/Components/Notifications.js";
import Panels from "views/Components/Panels.js";
import PricingPage from "views/Pages/PricingPage.js";
import RTLSupport from "views/Pages/RTLSupport.js";
import ReactTables from "views/Tables/ReactTables.js";
import RegisterPage from "views/Pages/RegisterPage.js";
import RegularForms from "views/Forms/RegularForms.js";
import RegularTables from "views/Tables/RegularTables.js";
import SweetAlert from "views/Components/SweetAlert.js";
import TimelinePage from "views/Pages/Timeline.js";
import Typography from "views/Components/Typography.js";
import UserProfile from "views/Pages/UserProfile.js";
import ValidationForms from "views/Forms/ValidationForms.js";
import Widgets from "views/Widgets/Widgets.js";
import Wizard from "views/Forms/Wizard.js";

import Customer_add from "views/CustomerManagement/Customer_add.js"
import Customer_coupon_management from "views/CustomerManagement/Customer_coupon_management.js"
import Customer_list from "views/CustomerManagement/Customer_list.js"
import Customer_setting from "views/CustomerManagement/Customer_setting.js"

import Staff_add from "views/StaffManagement/Staff_add.js"
import Staff_list from "views/StaffManagement/Staff_list.js"
import Staff_onDuty_list from "views/StaffManagement/Staff_onDuty_list.js"

import Admin_list from "views/Admin/Admin_list.js"
import Admin_add from "views/Admin/Admin_add.js"
import Admin_permission_list from "views/Admin/Admin_permission_list.js"
import Admin_permission_add from "views/Admin/Admin_permission_add.js"



import Product_add from "views/ProductManagement/Product_item_add.js"
import Product_list from "views/ProductManagement/Product_item_list.js"
import Product_category_add from "views/ProductManagement/Product_category_add.js"
import Product_category_list from "views/ProductManagement/Product_category_list.js"
import Product_coupon_add from "views/ProductManagement/Product_coupon_add.js"
import Product_coupon_list from "views/ProductManagement/Product_coupon_list.js"
import Product_package_add from "views/ProductManagement/Product_package_add.js"
import Product_package_list from "views/ProductManagement/Product_package_list.js"

import Inventory_in_add from "views/ProductManagement/Inventory_in_add.js"
import Inventory_in_list from "views/ProductManagement/Inventory_in_list.js"
import Inventory_balance from "views/ProductManagement/Inventory_balance.js"

import Store_list from "views/SystemSetting/Store_list.js"
import Store_new from "views/SystemSetting/Store_add.js"
import Payment_list from "views/SystemSetting/Payment_list.js"
import Payment_new from "views/SystemSetting/Payment_add.js"
import System_config from  "views/SystemSetting/System_config.js"

import DataSetManagement_list from "views/SystemSetting/Data_set_management_list"
import DataSetManagement_new from "views/SystemSetting/Data_set_management_add"
import DataSetManagementType_list from "views/SystemSetting/Data_set_management_type_list"
import DataSetManagementType_new from "views/SystemSetting/Data_set_management_type_add"

import Expenditure_list from "views/ExpenditureManagement/Expenditure_list.js"
import Expenditure_new from "views/ExpenditureManagement/Expenditure_add.js"
import Receivable_list from "views/ExpenditureManagement/Receivable_list.js"
import Receivable_balance from "views/ExpenditureManagement/Receivable_balance.js"
import Receivable_new from "views/ExpenditureManagement/Receivable_add.js"
import Commission_list from "views/ExpenditureManagement/Commission_list.js"
import Commission_balance from "views/ExpenditureManagement/Commission_balance.js"
import Commission_new from "views/ExpenditureManagement/Commission_add.js"

import Sales_list from "views/SalesManagement/Sales_list.js"
import Sales_new from "views/SalesManagement/Sales_add.js"

import Report_operating from "views/Report/Report_operating.js"
import ActionLog_list from "views/Report/ActionLog_list.js"

import Staff_attendance from "views/Staff/Staff_attendance.js"
import PaymentsReport from "views/Payments/Payments_report.js"

import Segment_list from "views/Segmentation/Segment_list.js"
import Segment_add from "views/Segmentation/Segment_add.js"
import Segment_list_without_sdp from "views/Segmentation/Segment_list_without_sdp.js"
import Segment_add_without_sdp from "views/Segmentation/Segment_add_without_sdp.js"

// @material-ui/icons
import Apps from "@material-ui/icons/Apps";
import DashboardIcon from "@material-ui/icons/Dashboard";
import DateRange from "@material-ui/icons/DateRange";
import GridOn from "@material-ui/icons/GridOn";
import Image from "@material-ui/icons/Image";
import Place from "@material-ui/icons/Place";
import Timeline from "@material-ui/icons/Timeline";
import WidgetsIcon from "@material-ui/icons/Widgets";
import Loc from "localization";

import ICON_PERSONNEL from "@material-ui/icons/RecentActors";
import ICON_PRODUCT from "@material-ui/icons/ShoppingBasket";
import ICON_SALES from "@material-ui/icons/AttachMoney";
import ICON_SETTING from "@material-ui/icons/Settings";
import ICON_FINACIAL from "@material-ui/icons/AccountBalanceWallet";
import ICON_INVENTORY from "@material-ui/icons/AllInbox";
import ICON_REPORT from "@material-ui/icons/Assessment";

import Custom_setting_config_list from 'views/SystemSetting/Custom_setting_config_list.js';
import Custom_setting_config_add from 'views/SystemSetting/Custom_setting_config_add.js';


var routes = [
  {
    flag: SystemType.Default,
    isShowInSide: true,
    path: "/dashboard",
    name: Loc.dashboard,
    icon: DashboardIcon,
    component: Dashboard,
    layout: "/admin"
  },
  {
    flag: SystemType.Default,
    path: "/login-admin",
    name: Loc.admin_login,
    mini: "L",
    component: AdminLoginPage,
    layout: "/admin-auth"
  },
  {
    flag: SystemType.Default,
    path: "/login-staff",
    name: Loc.staff_login,
    mini: "L",
    component: StaffLoginPage,
    layout: "/auth"
  },
  {
    flag: SystemType.Default,
    path: "/register-page",
    name: "Register Page",
    mini: "R",
    component: RegisterPage,
    layout: "/auth"
  },

  {
    flag: SystemType.ERP,
    isShowInSide: true,
    path: "/attendance",
    name: Loc.staff_attendance,
    mini: "SA",
    component: Staff_attendance,
    layout: "/staff"
  }, 
  {
    flag: SystemType.ERP,
    isShowInSide: true,
    path: "/purchase",
    name: 'Purchase',
    mini: "P",
    component: CustomerPage,
    layout: "/customer"
  }, 

  {
    collapse: true,
    isShowInSide: true,
    name: Loc.personnel_management,
    icon: ICON_PERSONNEL,
    state: "personnelManagementCollapse",
    views: [
      {
        flag: SystemType.ERP,
        isShowInSide: false,
        path: "/customer-new",
        name: Loc.customer_new,
        mini: "NC",
        component: Customer_add,
        layout: "/admin"
      },
      {
        flag: SystemType.ERP,
        isShowInSide: false,
        path: "/customer-coupon",
        name: Loc.coupon_on_hand,
        mini: "CCM",
        component: Customer_coupon_management,
        layout: "/admin"
      },
      {
        flag: SystemType.ERP,
        isShowInSide: true,
        path: "/customer-list",
        name: Loc.customer_management,
        mini: "CL",
        component: Customer_list, 
        layout: "/admin"
      },
      {
        flag: SystemType.ERP,
        isShowInSide: false,
        path: "/customer-setting",
        name: Loc.setting,
        mini: "S",
        component: Customer_setting, 
        layout: "/admin"
      },
      {
        flag: SystemType.ERP,
        isShowInSide: false,
        path: "/staff-new",
        name: Loc.staff_new,
        mini: "SC",
        component: Staff_add,
        layout: "/admin"
      },
      {
        flag: SystemType.ERP,
        isShowInSide: true,
        path: "/Staff-list",
        name: Loc.staff_list,
        mini: "SL",
        component: Staff_list, 
        layout: "/admin"
      },
      {
        flag: SystemType.Default,
        isShowInSide: false,
        path: "/admin-new",
        name: Loc.create_new_admin,
        mini: "AC",
        component: Admin_add,
        layout: "/admin"
      },
      {
        flag: SystemType.Default,
        isShowInSide: true,
        path: "/admin-list",
        name: Loc.admin_management,
        mini: "AL",
        component: Admin_list, 
        layout: "/admin"
      },
      {
        flag: SystemType.Default,
        isShowInSide: false,
        path: "/admin-permission-new",
        name: Loc.add_admin_level,
        mini: "APC",
        component: Admin_permission_add,
        layout: "/admin"
      },
      {
        flag: SystemType.Default,
        isShowInSide: true,
        path: "/admin-permission-list",
        name: Loc.admin_level,
        mini: "APL",
        component: Admin_permission_list, 
        layout: "/admin"
      },
      {
        flag: SystemType.ERP,
        isShowInSide: true,
        path: "/admin-staff-onDuty-list",
        name: Loc.staff_onDuty_list,
        mini: "SDL",
        component: Staff_onDuty_list, 
        layout: "/admin"
      },
    ]
  },
  {
    collapse: true,
    isShowInSide: true,
    name: Loc.product_management,
    icon: ICON_PRODUCT,
    state: "productManagementCollapse",
    views: [
      {
        flag: SystemType.ERP,
        isShowInSide: false,
        path: "/product-new",
        name: Loc.product_new,
        mini: "PC",
        component: Product_add,
        layout: "/admin"
      },
      {
        flag: SystemType.ERP,
        isShowInSide: true,
        path: "/product-list",
        name: Loc.product_list,
        mini: "PL",
        component: Product_list,
        layout: "/admin"
      },
      {
        flag: SystemType.ERP,
        isShowInSide: false,
        path: "/product-coupon-new",
        name: Loc.Product_coupon_new,
        mini: "CC",
        component: Product_coupon_add,
        layout: "/admin"
      },
      {
        flag: SystemType.ERP,
        isShowInSide: true,
        path: "/product-coupon-list",
        name: Loc.product_coupon_list,
        mini: "CL",
        component: Product_coupon_list,
        layout: "/admin"
      },
      {
        flag: SystemType.ERP,
        isShowInSide: false,
        path: "/product-package-new",
        name: Loc.package_new,
        mini: "PC",
        component: Product_package_add,
        layout: "/admin"
      },
      {
        flag: SystemType.ERP,
        isShowInSide: true,
        path: "/product-package-list",
        name: Loc.package_list,
        mini: "PL",
        component: Product_package_list,
        layout: "/admin"
      },
    ]
  },
  {
    collapse: true,
    isShowInSide: true,
    name: Loc.sales_management,
    icon: ICON_SALES,
    state: "salesManagementCollapse",
    views: [
      {
        flag: SystemType.ERP,
        isShowInSide: false,
        path: "/sales-new",
        name: Loc.sales_add,
        mini: "PC",
        component: Sales_new,
        layout: "/admin"
      },
      {
        flag: SystemType.ERP,
        isShowInSide: true,
        path: "/sales-list",
        name: Loc.sales_records,
        mini: "PL",
        component: Sales_list,
        layout: "/admin"
      },
    ]
  },
  {
    collapse: true,
    isShowInSide: true,
    name: Loc.inventory_management,
    icon: ICON_INVENTORY,
    state: "inventory_management",
    views: [
      {
        flag: SystemType.ERP,
        isShowInSide: false,
        path: "/inventory-in-new",
        name: Loc.inventory_in_add,
        mini: "IN",
        component: Inventory_in_add,
        layout: "/admin"
      },
      {
        flag: SystemType.ERP,
        isShowInSide: true,
        path: "/inventory-in-list",
        name: Loc.inventory_records,
        mini: "IAL",
        component: Inventory_in_list,
        layout: "/admin"
      },
      {
        flag: SystemType.ERP,
        isShowInSide: true,
        path: "/inventory-balance",
        name: Loc.inventory_balance,
        mini: "IB",
        component: Inventory_balance,
        layout: "/admin"
      },
      
    ]
  },
  {
    collapse: true,
    isShowInSide: true,
    name: Loc.segment_system,
    icon: ICON_FINACIAL,
    state: "segment_system",
    views: [
      {
        flag: SystemType.Default,
        isShowInSide:true,
        path:"/no-sdp-segment-list",
        name:Loc.segment_record+" V1",
        mini:"SLNS",
        component:Segment_list_without_sdp,
        layout:"/admin"
      },
      {
        flag: SystemType.Custom,
        isShowInSide:false,
        path:"/segment-new-no-sdp",
        name:"Segmentation+",
        mini:"SANS",
        component:Segment_add_without_sdp,
        layout:"/admin"
      },
      {
      flag: SystemType.Default,
      isShowInSide:true,
      path:"/segment-list",
      name:Loc.segment_record+" V2",
      mini:"SL",
      component:Segment_list,
      layout:"/admin"
      },
      {
        flag: SystemType.Custom,
        isShowInSide:false,
        path:"/segment-new",
        name:"Segmentation++",
        mini:"SA",
        component:Segment_add,
        layout:"/admin"
      },
 

    ]
  },
  {
    collapse: true,
    isShowInSide: true,
    name: Loc.financial_management,
    icon: ICON_FINACIAL,
    state: "financial_management",
    views: [
      {
        flag: SystemType.ERP,
        isShowInSide: true,
        path: "/payments",
        name: Loc.payments,
        mini: "PR",
        component:PaymentsReport,
        layout: "/admin"
      },
      {
        flag: SystemType.ERP,
        isShowInSide: false,
        path: "/expenditure-new",
        name: Loc.expenditure_add,
        mini: "EN",
        component: Expenditure_new,
        layout: "/admin"
      },
      {
        flag: SystemType.ERP,
        isShowInSide: true,
        path: "/expenditure-list",
        name: Loc.expenditure_records,
        mini: "EL",
        component: Expenditure_list,
        layout: "/admin"
      },
      {
        flag: SystemType.ERP,
        isShowInSide: false,
        path: "/receivable-new",
        name: Loc.receivable_add,
        mini: "RN",
        component: Receivable_new,
        layout: "/admin"
      },
      {
        flag: SystemType.ERP,
        isShowInSide: true,
        path: "/receivable-list",
        name: Loc.receivable_list,
        mini: "RM",
        component: Receivable_list,
        layout: "/admin"
      },
      {
        flag: SystemType.ERP,
        isShowInSide: true,
        path: "/receivable-balance",
        name: Loc.receivable_balance,
        mini: "RB",
        component: Receivable_balance,
        layout: "/admin"
      },
      {
        flag: SystemType.ERP,
        isShowInSide: false,
        path: "/commission-new",
        name: Loc.commission_add,
        mini: "CN",
        component: Commission_new,
        layout: "/admin"
      },
      {
        flag: SystemType.ERP,
        isShowInSide: true,
        path: "/commission-list",
        name: Loc.commission_record,
        mini: "CM",
        component: Commission_list,
        layout: "/admin"
      },
      {
        flag: SystemType.ERP,
        isShowInSide: true,
        path: "/commission-balance",
        name: Loc.commission_balance,
        mini: "CM",
        component: Commission_balance,
        layout: "/admin"
      },

      
    ]
  },
  {
    collapse: true,
    isShowInSide: true,
    name: Loc.report_management,
    icon: ICON_REPORT,
    state: "report_management",
    views: [
      {
        flag: SystemType.ERP,
        isShowInSide: true,
        path: "/report-operating",
        name: Loc.report_operating,
        mini: "OR",
        component: Report_operating,
        layout: "/admin"
      },
    ]
  },











  
  {
    collapse: true,
    isShowInSide: true,
    name: Loc.system_setting,
    icon: ICON_SETTING,
    state: "systemManagementCollapse",
    views: [
      {
        flag: SystemType.ERP,
        isShowInSide: false,
        path: "/product-category-new",
        name: Loc.Product_category_new,
        mini: "CC",
        component: Product_category_add,
        layout: "/admin"
      },
      {
        flag: SystemType.ERP,
        isShowInSide: true,
        path: "/product-category-list",
        name: Loc.product_category_management,
        mini: "CL",
        component: Product_category_list,
        layout: "/admin"
      },
      {
        flag: SystemType.ERP,
        isShowInSide: false,
        path: "/store-new",
        name: Loc.store_new,
        mini: "SN",
        component: Store_new,
        layout: "/admin"
      },
      {
        flag: SystemType.ERP,
        isShowInSide: true,
        path: "/store-list",
        name: Loc.store_management,
        mini: "SM",
        component: Store_list,
        layout: "/admin"
      },
      {
        flag: SystemType.ERP,
        isShowInSide: false,
        path: "/payment-new",
        name: Loc.payment_new,
        mini: "PN",
        component: Payment_new,
        layout: "/admin"
      },
      {
        flag: SystemType.ERP,
        isShowInSide: true,
        path: "/payment-list",
        name: Loc.payment_management,
        mini: "PM",
        component: Payment_list,
        layout: "/admin"
      },
      {
        flag: SystemType.Default,
        isShowInSide: true,
        path: "/actionLog-list",
        name: Loc.actionLog_list,
        mini: "AL",
        component: ActionLog_list,
        layout: "/admin"
      },
      {
        flag: SystemType.Default,
        isShowInSide: true,
        path: "/system-config",
        name: Loc.system_config,
        mini: "SC",
        component: System_config,
        layout: "/admin"
      },
      {
        flag: SystemType.Custom,
        isShowInSide: false,
        path: "/data-set-management-type-new",
        name: Loc.data_set_management,
        mini: "DSMN",
        component: DataSetManagementType_new,
        layout: "/admin"
      },
      {
        flag: SystemType.Custom,
        isShowInSide: true,
        path: "/data-set-management-type-list",
        name: Loc.data_set_management_type,
        mini: "DSTML",
        component: DataSetManagementType_list,
        layout: "/admin"
      },
      {
        flag: SystemType.Custom,
        isShowInSide: false,
        path: "/data-set-management-new",
        name: Loc.data_set_management,
        mini: "DSMN",
        component: DataSetManagement_new,
        layout: "/admin"
      },
      {
        flag: SystemType.Custom,
        isShowInSide: true,
        path: "/data-set-management-list",
        name: Loc.data_set_management,
        mini: "DSML",
        component: DataSetManagement_list,
        layout: "/admin"
      },
      {
        flag: SystemType.Custom,
        isShowInSide:true,
        path:"/custom-setting-config-list",
        name:Loc.custom_setting_config_list,
        mini:"CSC",
        component:Custom_setting_config_list,
        layout:"/admin"
      },
      {
        flag: SystemType.Custom,
        isShowInSide:false,
        path:"/custom-setting-config-new",
        name:"Custom Setting Config",
        mini:"CSC",
        component:Custom_setting_config_add,
        layout:"/admin"
      },
     
    ]
  },
  
];

export {routes};
// export {sideMenuRoutes};
