import {SystemType} from "Util/SystemType.js";
import {core_config_version} from "itchi_core_config.js";


const config = {
    targetFlags: [
        SystemType.Default,
        SystemType.Custom, 
        // SystemType.ERP //No need to use preload if not ERP (e.g. school project)
    ],
    app_name: "Readcorpus",
    version: core_config_version,
    language: "zh_Hant",
    // language: "en",
    // serverBaseUrl: "http://175.159.244.247:1111/"
    // serverBaseUrl: "http://192.168.100.199:1111/"
    serverBaseUrl: "https://api-readcorpus.secphillab.com/"
    // serverBaseUrl: "https://us-central1-eduarticleanalysis.cloudfunctions.net/app/"
    

};
export default config;
