/*eslint-disable*/
import React from "react";
import { Route, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";

// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
import Contacts from "@material-ui/icons/Contacts";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Dropdown from "views/CommonUseComponents/Dropdown.js";
import Switch from "@material-ui/core/Switch";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles_switch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

import PictureUpload from "components/CustomUpload/PictureUpload.js";

import ICON_BACK from "@material-ui/icons/ArrowBackIos";
import ICON_DONE from "@material-ui/icons/Done";
import ICON_DOWN from "@material-ui/icons/ArrowDownward";
import ICON_DOWNLOAD from "@material-ui/icons/ArrowDownward";
import ICON_RENEW from "@material-ui/icons/Autorenew";

import { ExportToCsv } from "export-to-csv";

const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);
const useStyles_switch = makeStyles(styles_switch);

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";
import { keys } from "@material-ui/core/styles/createBreakpoints";

// 载入模块
// const jieba = require("jieba-js");

export default function Segment_add_without_sdp(props) {
  const [isEdit, setIsEdit] = React.useState(false);
  const [isChangePassword, setIsChangePassword] = React.useState(false);

  const [data_id, setData_id] = React.useState("");
  const [data_ip, setData_ip] = React.useState("");
  const [data_port, setData_port] = React.useState("");
  const [data_task, setData_task] = React.useState("");
  const [data_conll_result, setData_conll_result] = React.useState("");

  const [data_adminLevel, setData_adminLevel] = React.useState("");
  const [data_adminLevelName, setData_adminLevelName] = React.useState("");
  const [data_adminLevelList, setData_adminLevelList] = React.useState(null);
  const [data_storeID, setData_storeID] = React.useState("");
  const [data_storeName, setData_storeName] = React.useState("");
  const [isLoading, set_isLoading] = React.useState(false);

  const [pathToRedirect, setRedirect] = React.useState("");

  const [adminNameState, setAdminNameState] = React.useState("");
  const [adminEmailState, setAdminEmailState] = React.useState("");
  const [adminPasswordState, setAdminPasswordState] = React.useState("");
  const [
    adminConfirmPasswrodState,
    setAdminConfirmPasswordState,
  ] = React.useState("");
  const [data_dataListArray, setData_dataListArray] = React.useState(null);

  const [isPreLoadedData, setIsPreLoadedData] = React.useState(false);
  const [fetchingStatus, setFetchingStatus] = React.useState("");
  const [historyState, setHistoryState] = React.useState(null);
  const [isERP, setIsERP] = React.useState(false);
  const [targetText, setTargetText] = React.useState(null);
  const [resultText, setResultText] = React.useState(null);
  const [resultTag, setResultTag] = React.useState(null);
  const [showSave, setShowSave] = React.useState(false);
  const classes = useStyles();
  const classes_alert = useStyles_alert();
  const classes_switch = useStyles_switch();

  const [data_preloadData, setData_preloadData] = React.useState(null);

  //WORDS_CH
  const [cncorpusWordList, set_cncorpusWordList] = React.useState(null);
  const [subtlex_chWordList, set_subtlex_chWordList] = React.useState(null);
  const [sinicaWordList, set_sinicaWordList] = React.useState(null);
  const [dajunWordList, set_dajunWordList] = React.useState(null);
  const [rt_error_rateList, set_rt_error_rateList] = React.useState(null);
  const [causalTypeList, set_causalTypeList] = React.useState(null);
  //Stroke_char
  const [
    stroke_appearance_voiceList,
    set_stroke_appearance_voiceList,
  ] = React.useState(null);
  //Char
  const [cncorpusCharList, set_cncorpusCharList] = React.useState(null);
  const [subtlex_chCharList, set_subtlex_chCharList] = React.useState(null);
  const [sinicaCharList, set_sinicaCharList] = React.useState(null);
  const [dajunCharList, set_dajunCharList] = React.useState(null);
  const [cuhkCharList, set_cuhkCharList] = React.useState(null);
  //AOA
  const [aoaList, set_aoaList] = React.useState(null);
  //FirstGrade
  const [firstGradeList, set_firstGradeList] = React.useState(null);
  const [progressCount, set_progressCount] = React.useState(0);
  const [totalProgressCount, set_totalProgressCount] = React.useState(0);

  // alert
  const [alert_save, setSaveAlert] = React.useState(null);
  const hideAlert = () => {
    setSaveAlert(null);
  };
  //   const jieba = () => {
  //     importScript("https://pulipulichen.github.io/jieba-js/require-jieba-js.js");
  //   }

  React.useEffect(() => {
    var promise_preLoad = null;
    setHistoryState(props.history.location.state);

    var preloadListStr = localStorage.getItem("preloadDataList");
    // console.log("targetText  " + targetText);

    // pre load data for edit
    if (props.location.state !== undefined && props.location.state != null && props.location.state.data !== undefined && props.location.state.data != null) {
      var dataToEdit = props.location.state.data;
      setIsEdit(true);
      setData_id(dataToEdit.id);
      if (resultText == null) {
        setResultText(dataToEdit.segmentResult);
      }

      if (targetText == null) {
        setTargetText(dataToEdit.origin_article);
      }

      if (resultTag == null) {
        if (dataToEdit.segmentTags) {
          setResultTag(dataToEdit.segmentTags);
        }
      }
    }

    // //Preload all the collections first
    // getListOfCollection(collectionsToLoad, {}).then(
    //   (returnList) => {
    //     // console.log(`Return list  ${JSON.stringify(returnList)}`)
    //     setData_preloadData(returnList);
    //     // setPreloadDataToLocal(JSON.stringify(returnList));
    //   }
    // );

    // Specify how to clean up after this effect:
    return function cleanup() {
      promise_preLoad = null;
    };
  }, [targetText, resultText, resultTag, showSave, isEdit, progressCount]);

  const validCheck = () => {
    var result = true;
    if (resultText === "") {
      setAdminNameState("error");
      showAlert(false, Loc.failed, Loc.data_save_failed);
      result = false;
    }

    if (!isEdit) {
      if (resultText === "") {
        setAdminPasswordState("error");
        showAlert(false, Loc.failed, Loc.data_save_failed);
        result = false;
      }
    }
    return result;
  };

  function getWordResultDataList(wordList, tagList, onHoldList) {
    var newList = wordList;
    var newTagList = tagList;
    var newOnHoldList = onHoldList;
    var newCount = 0;

    var result = {};
    const totalTopProces = wordList.length;

    // console.log("getWordResultDataList "+JSON.stringify(newList));
    return new Promise((onDone) => {
      wordList.map((word, key) => {
        var tag = newTagList[key];

        getWordResultData(word, tag, (data) => {
          result[key] = data;
          // console.log("getWordResultData: index:"+key)
          newCount++;
          set_progressCount(newCount);
          if (Object.keys(result).length == totalTopProces) {
            onDone(result);
            set_progressCount(0);
          }
        });
      });

      // var word = newList[0];
      // var tag = newTagList[0];
      // getWordResultData(word,tag, (data) => {
      //   newCount += 1;
      //   set_progressCount(newCount);
      //   newOnHoldList.push(data);
      //   newList.splice(0, 1);
      //   newTagList.splice(0, 1);

      //   if (newList.length > 0) {
      //     getWordResultDataList(newList,newTagList, newOnHoldList,newCount).then((finalOnHoldList) => {
      //       onDone(finalOnHoldList);
      //     });
      //   } else {
      //     onDone(newOnHoldList);
      //   }

      // })
    });
  }

  function getWordResultData(_word, _part_of_speech, callback) {
    const body = {
      word: _word,
      part_of_speech: _part_of_speech,
    };

    // fetchAPI.do_fetch_fast_api("post", body).then(
    fetchAPI
      .do_fetch_withoutTokenID("post", "user/get_segment_data", body)
      .then(
        (res) => {
          console.log("do_fetch_fast_api: ", res);

          callback(res.data);
        },
        (error) => {
          console.log("failed: ", error);
          callback(null);
        }
      );
  }

  const backClicked = () => {
    setRedirect("/admin/dashboard");
  };

  const doneClicked = () => {
    setFetchingStatus("fetching");
    if (validCheck()) {
      const idToPass = isEdit ? data_id : null;
      console.log("idToPass: ", resultText);
      var body = {
          id: idToPass,
          targetCollection:"SegmentRecord_v_1",
        content: {
          
          origin_article: targetText,
          segmentResult: resultText,
          segmentTags: resultTag,
        },
      };
      set_isLoading(true);
      fetchAPI.do_fetch("post", "admin/create-segment-chinese", body).then(
        (res) => {
          set_isLoading(false);

          console.log("success: ", res);
          setFetchingStatus("");

          showAlert(true, Loc.success, Loc.data_save_success);
        },
        (error) => {
          console.log("failed: ", error);
          set_isLoading(false);

          setFetchingStatus("");
          showAlert(false, Loc.failed, Loc.data_save_failed);
        }
      );
    } else {
      setFetchingStatus("");
    }
  };

  function showAlert(issuccess, title, content) {
    setSaveAlert(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        closeOnClickOutside={false}
        onConfirm={() => {
          if (issuccess) {
            // hideAlert()
            setRedirect("/admin/no-sdp-segment-list");
          } else {
            hideAlert();
          }
        }}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.info}
        onCancel={() => {
          if (issuccess) {
            setTargetText("");
            setResultText("");

            hideAlert();
          } else {
            hideAlert();
          }
        }}
        confirmBtnText={issuccess ? Loc.done : Loc.confirm}
        cancelBtnText={Loc.continue}
        btnSize="lg"
        showCancel={issuccess && !isEdit}
      >
        {content}
      </SweetAlert>
    );
  }

  const showloading = () => {
    if (isLoading) {
      return (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          closeOnClickOutside={false}
          title={Loc.loading}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnText={Loc.confirm}
          confirmBtnCssClass={
            classes_alert.button + " " + classes_alert.success
          }
          btnSize="lg"
          showConfirm={false}
          showCancel={false}
        >
          {Loc.please_wait}
          {totalProgressCount > 0 && (
            <GridItem xs={12}>
              {progressCount + "/" + totalProgressCount}
            </GridItem>
          )}
        </SweetAlert>
      );
    } else {
      return null;
    }
  };

  const check_redirect = () => {
      if (pathToRedirect != "") {
        
      return (
        <Redirect
          to={{
            pathname: pathToRedirect,
            state: historyState !== undefined && historyState !== null ? historyState.config:null,
          }}
        />
      );
    } else {
      return null;
    }
  };

  function checkSpecialCharacters(word) {
    var isSpecial = false;

    if (
      word == "。" ||
      word == "！" ||
      word == "，" ||
      word == "、" ||
      word == "（" ||
      word == "）" ||
      word == "《" ||
      word == "》" ||
      word == "「" ||
      word == "」" ||
      word == "；" ||
      word == " "
    ) {
      isSpecial = true;
    }
    return isSpecial;
  }

  function checkSentence(word) {
    var isSentence = false;

    if (
      word == "。" ||
      word == "！" ||
      // word == "，" ||
      word == "—" ||
      word == "……" ||
      word == "…" ||
      word == "？"
    ) {
      isSentence = true;
    }
    return isSentence;
  }

  function downloadResult(_resultText, _resultTag) {
    // var SentencePairCount_Adj = 0; //NOTE Count of number of word_ch with Sentence# +1 of corresponding row
    // var SentencePairCount_All = 0; //NOTE Count of number of word_ch with Sentence# < Sentence# of corresponding row
    // var ParagraphPairCount_Adj = 0;//NOTE Count of number of word_ch with Paragraph# +1 of corresponding row
    // var ParagraphPairCount_All = 0;//NOTE Count of number of word_ch with Paragraph# < Paragraph# of corresponding row
    // var SentencePair_Adj = 0;//NOTE Sum of number of same word in word_ch with Sentence# +1 of corresponding row
    // var SentencePair_All = 0;//NOTE Sum of number of same word in word_ch with Sentence# > Sentence# of corresponding row
    // var WeightedSentencePair_All = 0;//NOTE Sum of (number of same word in word_ch with Sentence# > Sentence# of corresponding row) weighted with 1/Sentence distance+1
    // var ParagraphPair_Adj = 0;//NOTE Sum of number of same word in word_ch with Paragraph# +1 of corresponding row
    // var ParagraphPair_All = 0;//NOTE Sum of number of same word in word_ch with Paragraph# > Paragraph# of corresponding row
    // var WeightedParagraphPair_All = 0;//NOTE Sum of number of same word in word_ch with Paragraph# > Paragraph# of corresponding row weighted with 1/Sentence distance+1
    set_isLoading(true);
    var dataList = _resultText.split(",");
    set_totalProgressCount(dataList.length);
    getWordResultDataList([...dataList], [..._resultTag], []).then(
      (resultList) => {
        // console.log("resultList: ", JSON.stringify(resultList));
        set_isLoading(false);
        set_progressCount(0);
        set_totalProgressCount(0);
        var csvData = [];

        var notWordList = [];
        var wordLength = 0;

        var paragraphCount = 1;
        var sentenceCount = 1;
        var needAddSentenceCount = false;
        //avoid excel show undefined value
        // const wordLengthList = dataList.map((obj) => {
        //   return obj.length;
        // });
        // const maxWordLength = Math.max(...wordLengthList);

        dataList.map((word, index) => {
          var resultData = resultList[index];
          wordLength += word.length;

          if (
            checkSpecialCharacters(word) ||
            checkSentence(word) ||
            word == "\n"
          ) {
            //Not Count
            notWordList.push("0");
            wordLength = 0;
          } else {
            // notWordList.push("");
            notWordList.push(wordLength);
            wordLength = 0;
          }

          // if (word == "") {
          //   paragraphCount++;
          // }
          if (word == "「") {
            if (index > 1) {
              var lastWord = dataList[index - 1];
              if (lastWord == "」") {
                sentenceCount++;
              }
            }
          } else {
            if (needAddSentenceCount) {
              sentenceCount++;
              needAddSentenceCount = false;
            }
            if (checkSentence(word)) {
              needAddSentenceCount = true;
            }
          }

          const count = notWordList[index];
          console.log("count: " + count);

          //Char 1 - 8
          var stringList = [];
          for (var i = 0; i < 8; i++) {
            stringList.push(word.charAt(i) ? word.charAt(i) : "");
          }

          var cantonese_py =
            resultData.Cantonese_Pinyin_char1 +
            resultData.Cantonese_Pinyin_char2 +
            resultData.Cantonese_Pinyin_char3 +
            resultData.Cantonese_Pinyin_char4 +
            resultData.Cantonese_Pinyin_char5 +
            resultData.Cantonese_Pinyin_char6 +
            resultData.Cantonese_Pinyin_char7 +
            resultData.Cantonese_Pinyin_char8;

          if (_resultTag != null && _resultTag.length > 0) {
            console.log("resultTag is not null");
            const tag = _resultTag[index];

            csvData.push([
              index + 1,
              word,
              tag,
              paragraphCount,
              sentenceCount,
              ...stringList,
              count ? count : "",
              resultData.WordFreq_CNCORPUS,
              resultData.WordFreq_SUBTLEX_CH,
              resultData.WordFreq_Sinica_Corpus,
              resultData.WordFreq_DaJun,
              resultData.RT,
              resultData.RTSD,
              resultData.ERR,
              resultData.Stroke_char1,
              resultData.Stroke_char2,
              resultData.Stroke_char3,
              resultData.Stroke_char4,
              resultData.Stroke_char5,
              resultData.Stroke_char6,
              resultData.Stroke_char7,
              resultData.Stroke_char8,
              resultData.Freq_CNCORPUS_char1,
              resultData.Freq_CNCORPUS_char2,
              resultData.Freq_CNCORPUS_char3,
              resultData.Freq_CNCORPUS_char4,
              resultData.Freq_CNCORPUS_char5,
              resultData.Freq_CNCORPUS_char6,
              resultData.Freq_CNCORPUS_char7,
              resultData.Freq_CNCORPUS_char8,
              resultData.Freq_SUBTLEX_CH_char1,
              resultData.Freq_SUBTLEX_CH_char2,
              resultData.Freq_SUBTLEX_CH_char3,
              resultData.Freq_SUBTLEX_CH_char4,
              resultData.Freq_SUBTLEX_CH_char5,
              resultData.Freq_SUBTLEX_CH_char6,
              resultData.Freq_SUBTLEX_CH_char7,
              resultData.Freq_SUBTLEX_CH_char8,
              resultData.Freq_Sinica_char1,
              resultData.Freq_Sinica_char2,
              resultData.Freq_Sinica_char3,
              resultData.Freq_Sinica_char4,
              resultData.Freq_Sinica_char5,
              resultData.Freq_Sinica_char6,
              resultData.Freq_Sinica_char7,
              resultData.Freq_Sinica_char8,
              resultData.Freq_DaJun_CH_char1,
              resultData.Freq_DaJun_CH_char2,
              resultData.Freq_DaJun_CH_char3,
              resultData.Freq_DaJun_CH_char4,
              resultData.Freq_DaJun_CH_char5,
              resultData.Freq_DaJun_CH_char6,
              resultData.Freq_DaJun_CH_char7,
              resultData.Freq_DaJun_CH_char8,
              resultData.Freq_CUHK_char1,
              resultData.Freq_CUHK_char2,
              resultData.Freq_CUHK_char3,
              resultData.Freq_CUHK_char4,
              resultData.Freq_CUHK_char5,
              resultData.Freq_CUHK_char6,
              resultData.Freq_CUHK_char7,
              resultData.Freq_CUHK_char8,
              resultData.AOA_Cai_char1,
              resultData.AOA_Cai_char2,
              resultData.AOA_Cai_char3,
              resultData.AOA_Cai_char4,
              resultData.AOA_Cai_char5,
              resultData.AOA_Cai_char6,
              resultData.AOA_Cai_char7,
              resultData.AOA_Cai_char8,
              resultData.AOA_Cai2_char1,
              resultData.AOA_Cai2_char2,
              resultData.AOA_Cai2_char3,
              resultData.AOA_Cai2_char4,
              resultData.AOA_Cai2_char5,
              resultData.AOA_Cai2_char6,
              resultData.AOA_Cai2_char7,
              resultData.AOA_Cai2_char8,
              resultData.AOA_Cai3_char1,
              resultData.AOA_Cai3_char2,
              resultData.AOA_Cai3_char3,
              resultData.AOA_Cai3_char4,
              resultData.AOA_Cai3_char5,
              resultData.AOA_Cai3_char6,
              resultData.AOA_Cai3_char7,
              resultData.AOA_Cai3_char8,
              resultData.AOA_Shu_char1,
              resultData.AOA_Shu_char2,
              resultData.AOA_Shu_char3,
              resultData.AOA_Shu_char4,
              resultData.AOA_Shu_char5,
              resultData.AOA_Shu_char6,
              resultData.AOA_Shu_char7,
              resultData.AOA_Shu_char8,
              resultData.AOA_Liu_char1,
              resultData.AOA_Liu_char2,
              resultData.AOA_Liu_char3,
              resultData.AOA_Liu_char4,
              resultData.AOA_Liu_char5,
              resultData.AOA_Liu_char6,
              resultData.AOA_Liu_char7,
              resultData.AOA_Liu_char8,
              resultData.nMeaning_char1,
              resultData.nMeaning_char2,
              resultData.nMeaning_char3,
              resultData.nMeaning_char4,
              resultData.nMeaning_char5,
              resultData.nMeaning_char6,
              resultData.nMeaning_char7,
              resultData.nMeaning_char8,
              resultData.RT_char1,
              resultData.RT_char2,
              resultData.RT_char3,
              resultData.RT_char4,
              resultData.RT_char5,
              resultData.RT_char6,
              resultData.RT_char7,
              resultData.RT_char8,
              resultData.RTSD_char1,
              resultData.RTSD_char2,
              resultData.RTSD_char3,
              resultData.RTSD_char4,
              resultData.RTSD_char5,
              resultData.RTSD_char6,
              resultData.RTSD_char7,
              resultData.RTSD_char8,
              resultData.ERR_char1,
              resultData.ERR_char2,
              resultData.ERR_char3,
              resultData.ERR_char4,
              resultData.ERR_char5,
              resultData.ERR_char6,
              resultData.ERR_char7,
              resultData.ERR_char8,
              cantonese_py,
              resultData.Cantonese_Pinyin_char1,
              resultData.Cantonese_Pinyin_char2,
              resultData.Cantonese_Pinyin_char3,
              resultData.Cantonese_Pinyin_char4,
              resultData.Cantonese_Pinyin_char5,
              resultData.Cantonese_Pinyin_char6,
              resultData.Cantonese_Pinyin_char7,
              resultData.Cantonese_Pinyin_char8,
              resultData.Homophone_Char1,
              resultData.Homophone_Char2,
              resultData.Homophone_Char3,
              resultData.Homophone_Char4,
              resultData.Homophone_Char5,
              resultData.Homophone_Char6,
              resultData.Homophone_Char7,
              resultData.Homophone_Char8,
              resultData.HomphoneFreq_CNCORPUS_Char1,
              resultData.HomphoneFreq_CNCORPUS_Char2,
              resultData.HomphoneFreq_CNCORPUS_Char3,
              resultData.HomphoneFreq_CNCORPUS_Char4,
              resultData.HomphoneFreq_CNCORPUS_Char5,
              resultData.HomphoneFreq_CNCORPUS_Char6,
              resultData.HomphoneFreq_CNCORPUS_Char7,
              resultData.HomphoneFreq_CNCORPUS_Char8,
              resultData.HomphoneFreq_SUBTLEX_CH_Char1,
              resultData.HomphoneFreq_SUBTLEX_CH_Char2,
              resultData.HomphoneFreq_SUBTLEX_CH_Char3,
              resultData.HomphoneFreq_SUBTLEX_CH_Char4,
              resultData.HomphoneFreq_SUBTLEX_CH_Char5,
              resultData.HomphoneFreq_SUBTLEX_CH_Char6,
              resultData.HomphoneFreq_SUBTLEX_CH_Char7,
              resultData.HomphoneFreq_SUBTLEX_CH_Char8,
              resultData.HomphoneFreq_Sinica_Char1,
              resultData.HomphoneFreq_Sinica_Char2,
              resultData.HomphoneFreq_Sinica_Char3,
              resultData.HomphoneFreq_Sinica_Char4,
              resultData.HomphoneFreq_Sinica_Char5,
              resultData.HomphoneFreq_Sinica_Char6,
              resultData.HomphoneFreq_Sinica_Char7,
              resultData.HomphoneFreq_Sinica_Char8,
              resultData.HomphoneFreq_DaJun_Char1,
              resultData.HomphoneFreq_DaJun_Char2,
              resultData.HomphoneFreq_DaJun_Char3,
              resultData.HomphoneFreq_DaJun_Char4,
              resultData.HomphoneFreq_DaJun_Char5,
              resultData.HomphoneFreq_DaJun_Char6,
              resultData.HomphoneFreq_DaJun_Char7,
              resultData.HomphoneFreq_DaJun_Char8,
              resultData.HomphoneFreq_CUHK_Char1,
              resultData.HomphoneFreq_CUHK_Char2,
              resultData.HomphoneFreq_CUHK_Char3,
              resultData.HomphoneFreq_CUHK_Char4,
              resultData.HomphoneFreq_CUHK_Char5,
              resultData.HomphoneFreq_CUHK_Char6,
              resultData.HomphoneFreq_CUHK_Char7,
              resultData.HomphoneFreq_CUHK_Char8,
              resultData.First_appearance_keys_Book_Char1,
              resultData.First_appearance_keys_Book_Char2,
              resultData.First_appearance_keys_Book_Char3,
              resultData.First_appearance_keys_Book_Char4,
              resultData.First_appearance_keys_Book_Char5,
              resultData.First_appearance_keys_Book_Char6,
              resultData.First_appearance_keys_Book_Char7,
              resultData.First_appearance_keys_Book_Char8,
              resultData.First_appearance_keys_Year_Char1,
              resultData.First_appearance_keys_Year_Char2,
              resultData.First_appearance_keys_Year_Char3,
              resultData.First_appearance_keys_Year_Char4,
              resultData.First_appearance_keys_Year_Char5,
              resultData.First_appearance_keys_Year_Char6,
              resultData.First_appearance_keys_Year_Char7,
              resultData.First_appearance_keys_Year_Char8,
              resultData.First_appearance_now_Book_Char1,
              resultData.First_appearance_now_Book_Char2,
              resultData.First_appearance_now_Book_Char3,
              resultData.First_appearance_now_Book_Char4,
              resultData.First_appearance_now_Book_Char5,
              resultData.First_appearance_now_Book_Char6,
              resultData.First_appearance_now_Book_Char7,
              resultData.First_appearance_now_Book_Char8,
              resultData.First_appearance_now_Year_Char1,
              resultData.First_appearance_now_Year_Char2,
              resultData.First_appearance_now_Year_Char3,
              resultData.First_appearance_now_Year_Char4,
              resultData.First_appearance_now_Year_Char5,
              resultData.First_appearance_now_Year_Char6,
              resultData.First_appearance_now_Year_Char7,
              resultData.First_appearance_now_Year_Char8,
              resultData.First_appearance_ephhk_Book_Char1,
              resultData.First_appearance_ephhk_Book_Char2,
              resultData.First_appearance_ephhk_Book_Char3,
              resultData.First_appearance_ephhk_Book_Char4,
              resultData.First_appearance_ephhk_Book_Char5,
              resultData.First_appearance_ephhk_Book_Char6,
              resultData.First_appearance_ephhk_Book_Char7,
              resultData.First_appearance_ephhk_Book_Char8,
              resultData.First_appearance_ephhk_Year_Char1,
              resultData.First_appearance_ephhk_Year_Char2,
              resultData.First_appearance_ephhk_Year_Char3,
              resultData.First_appearance_ephhk_Year_Char4,
              resultData.First_appearance_ephhk_Year_Char5,
              resultData.First_appearance_ephhk_Year_Char6,
              resultData.First_appearance_ephhk_Year_Char7,
              resultData.First_appearance_ephhk_Year_Char8,
              resultData.CausalType,
            ]);

            console.log("csv data with tag " + JSON.stringify(csvData));
          } else {
            console.log("resultTag is null");
            csvData.push([
              index + 1,
              word,
              "",
              paragraphCount,
              sentenceCount,
              ...stringList,
              count ? count : "",
              resultData.WordFreq_CNCORPUS,
              resultData.WordFreq_SUBTLEX_CH,
              resultData.WordFreq_Sinica_Corpus,
              resultData.WordFreq_DaJun,
              resultData.RT,
              resultData.RTSD,
              resultData.ERR,
              resultData.Stroke_char1,
              resultData.Stroke_char2,
              resultData.Stroke_char3,
              resultData.Stroke_char4,
              resultData.Stroke_char5,
              resultData.Stroke_char6,
              resultData.Stroke_char7,
              resultData.Stroke_char8,
              resultData.Freq_CNCORPUS_char1,
              resultData.Freq_CNCORPUS_char2,
              resultData.Freq_CNCORPUS_char3,
              resultData.Freq_CNCORPUS_char4,
              resultData.Freq_CNCORPUS_char5,
              resultData.Freq_CNCORPUS_char6,
              resultData.Freq_CNCORPUS_char7,
              resultData.Freq_CNCORPUS_char8,
              resultData.Freq_SUBTLEX - CH_char1,
              resultData.Freq_SUBTLEX - CH_char2,
              resultData.Freq_SUBTLEX - CH_char3,
              resultData.Freq_SUBTLEX - CH_char4,
              resultData.Freq_SUBTLEX - CH_char5,
              resultData.Freq_SUBTLEX - CH_char6,
              resultData.Freq_SUBTLEX - CH_char7,
              resultData.Freq_SUBTLEX - CH_char8,
              resultData.Freq_Sinica_char1,
              resultData.Freq_Sinica_char2,
              resultData.Freq_Sinica_char3,
              resultData.Freq_Sinica_char4,
              resultData.Freq_Sinica_char5,
              resultData.Freq_Sinica_char6,
              resultData.Freq_Sinica_char7,
              resultData.Freq_Sinica_char8,
              resultData.Freq_DaJun - CH_char1,
              resultData.Freq_DaJun - CH_char2,
              resultData.Freq_DaJun - CH_char3,
              resultData.Freq_DaJun - CH_char4,
              resultData.Freq_DaJun - CH_char5,
              resultData.Freq_DaJun - CH_char6,
              resultData.Freq_DaJun - CH_char7,
              resultData.Freq_DaJun - CH_char8,
              resultData.Freq_CUHK_char1,
              resultData.Freq_CUHK_char2,
              resultData.Freq_CUHK_char3,
              resultData.Freq_CUHK_char4,
              resultData.Freq_CUHK_char5,
              resultData.Freq_CUHK_char6,
              resultData.Freq_CUHK_char7,
              resultData.Freq_CUHK_char8,
              resultData.AOA_Cai_char1,
              resultData.AOA_Cai_char2,
              resultData.AOA_Cai_char3,
              resultData.AOA_Cai_char4,
              resultData.AOA_Cai_char5,
              resultData.AOA_Cai_char6,
              resultData.AOA_Cai_char7,
              resultData.AOA_Cai_char8,
              resultData.AOA_Cai2_char1,
              resultData.AOA_Cai2_char2,
              resultData.AOA_Cai2_char3,
              resultData.AOA_Cai2_char4,
              resultData.AOA_Cai2_char5,
              resultData.AOA_Cai2_char6,
              resultData.AOA_Cai2_char7,
              resultData.AOA_Cai2_char8,
              resultData.AOA_Cai3_char1,
              resultData.AOA_Cai3_char2,
              resultData.AOA_Cai3_char3,
              resultData.AOA_Cai3_char4,
              resultData.AOA_Cai3_char5,
              resultData.AOA_Cai3_char6,
              resultData.AOA_Cai3_char7,
              resultData.AOA_Cai3_char8,
              resultData.AOA_Shu_char1,
              resultData.AOA_Shu_char2,
              resultData.AOA_Shu_char3,
              resultData.AOA_Shu_char4,
              resultData.AOA_Shu_char5,
              resultData.AOA_Shu_char6,
              resultData.AOA_Shu_char7,
              resultData.AOA_Shu_char8,
              resultData.AOA_Liu_char1,
              resultData.AOA_Liu_char2,
              resultData.AOA_Liu_char3,
              resultData.AOA_Liu_char4,
              resultData.AOA_Liu_char5,
              resultData.AOA_Liu_char6,
              resultData.AOA_Liu_char7,
              resultData.AOA_Liu_char8,
              resultData.nMeaning_char1,
              resultData.nMeaning_char2,
              resultData.nMeaning_char3,
              resultData.nMeaning_char4,
              resultData.nMeaning_char5,
              resultData.nMeaning_char6,
              resultData.nMeaning_char7,
              resultData.nMeaning_char8,
              resultData.RT_char1,
              resultData.RT_char2,
              resultData.RT_char3,
              resultData.RT_char4,
              resultData.RT_char5,
              resultData.RT_char6,
              resultData.RT_char7,
              resultData.RT_char8,
              resultData.RTSD_char1,
              resultData.RTSD_char2,
              resultData.RTSD_char3,
              resultData.RTSD_char4,
              resultData.RTSD_char5,
              resultData.RTSD_char6,
              resultData.RTSD_char7,
              resultData.RTSD_char8,
              resultData.ERR_char1,
              resultData.ERR_char2,
              resultData.ERR_char3,
              resultData.ERR_char4,
              resultData.ERR_char5,
              resultData.ERR_char6,
              resultData.ERR_char7,
              resultData.ERR_char8,
              cantonese_py,
              resultData.Cantonese_Pinyin_char1,
              resultData.Cantonese_Pinyin_char2,
              resultData.Cantonese_Pinyin_char3,
              resultData.Cantonese_Pinyin_char4,
              resultData.Cantonese_Pinyin_char5,
              resultData.Cantonese_Pinyin_char6,
              resultData.Cantonese_Pinyin_char7,
              resultData.Cantonese_Pinyin_char8,
              resultData.Homophone_Char1,
              resultData.Homophone_Char2,
              resultData.Homophone_Char3,
              resultData.Homophone_Char4,
              resultData.Homophone_Char5,
              resultData.Homophone_Char6,
              resultData.Homophone_Char7,
              resultData.Homophone_Char8,
              resultData.HomphoneFreq_CNCORPUS_Char1,
              resultData.HomphoneFreq_CNCORPUS_Char2,
              resultData.HomphoneFreq_CNCORPUS_Char3,
              resultData.HomphoneFreq_CNCORPUS_Char4,
              resultData.HomphoneFreq_CNCORPUS_Char5,
              resultData.HomphoneFreq_CNCORPUS_Char6,
              resultData.HomphoneFreq_CNCORPUS_Char7,
              resultData.HomphoneFreq_CNCORPUS_Char8,
              resultData.HomphoneFreq_SUBTLEX_CH_Char1,
              resultData.HomphoneFreq_SUBTLEX_CH_Char2,
              resultData.HomphoneFreq_SUBTLEX_CH_Char3,
              resultData.HomphoneFreq_SUBTLEX_CH_Char4,
              resultData.HomphoneFreq_SUBTLEX_CH_Char5,
              resultData.HomphoneFreq_SUBTLEX_CH_Char6,
              resultData.HomphoneFreq_SUBTLEX_CH_Char7,
              resultData.HomphoneFreq_SUBTLEX_CH_Char8,
              resultData.HomphoneFreq_Sinica_Char1,
              resultData.HomphoneFreq_Sinica_Char2,
              resultData.HomphoneFreq_Sinica_Char3,
              resultData.HomphoneFreq_Sinica_Char4,
              resultData.HomphoneFreq_Sinica_Char5,
              resultData.HomphoneFreq_Sinica_Char6,
              resultData.HomphoneFreq_Sinica_Char7,
              resultData.HomphoneFreq_Sinica_Char8,
              resultData.HomphoneFreq_DaJun_Char1,
              resultData.HomphoneFreq_DaJun_Char2,
              resultData.HomphoneFreq_DaJun_Char3,
              resultData.HomphoneFreq_DaJun_Char4,
              resultData.HomphoneFreq_DaJun_Char5,
              resultData.HomphoneFreq_DaJun_Char6,
              resultData.HomphoneFreq_DaJun_Char7,
              resultData.HomphoneFreq_DaJun_Char8,
              resultData.HomphoneFreq_CUHK_Char1,
              resultData.HomphoneFreq_CUHK_Char2,
              resultData.HomphoneFreq_CUHK_Char3,
              resultData.HomphoneFreq_CUHK_Char4,
              resultData.HomphoneFreq_CUHK_Char5,
              resultData.HomphoneFreq_CUHK_Char6,
              resultData.HomphoneFreq_CUHK_Char7,
              resultData.HomphoneFreq_CUHK_Char8,
              resultData.First_appearance_keys_Book_Char1,
              resultData.First_appearance_keys_Book_Char2,
              resultData.First_appearance_keys_Book_Char3,
              resultData.First_appearance_keys_Book_Char4,
              resultData.First_appearance_keys_Book_Char5,
              resultData.First_appearance_keys_Book_Char6,
              resultData.First_appearance_keys_Book_Char7,
              resultData.First_appearance_keys_Book_Char8,
              resultData.First_appearance_keys_Year_Char1,
              resultData.First_appearance_keys_Year_Char2,
              resultData.First_appearance_keys_Year_Char3,
              resultData.First_appearance_keys_Year_Char4,
              resultData.First_appearance_keys_Year_Char5,
              resultData.First_appearance_keys_Year_Char6,
              resultData.First_appearance_keys_Year_Char7,
              resultData.First_appearance_keys_Year_Char8,
              resultData.First_appearance_now_Book_Char1,
              resultData.First_appearance_now_Book_Char2,
              resultData.First_appearance_now_Book_Char3,
              resultData.First_appearance_now_Book_Char4,
              resultData.First_appearance_now_Book_Char5,
              resultData.First_appearance_now_Book_Char6,
              resultData.First_appearance_now_Book_Char7,
              resultData.First_appearance_now_Book_Char8,
              resultData.First_appearance_now_Year_Char1,
              resultData.First_appearance_now_Year_Char2,
              resultData.First_appearance_now_Year_Char3,
              resultData.First_appearance_now_Year_Char4,
              resultData.First_appearance_now_Year_Char5,
              resultData.First_appearance_now_Year_Char6,
              resultData.First_appearance_now_Year_Char7,
              resultData.First_appearance_now_Year_Char8,
              resultData.First_appearance_ephhk_Book_Char1,
              resultData.First_appearance_ephhk_Book_Char2,
              resultData.First_appearance_ephhk_Book_Char3,
              resultData.First_appearance_ephhk_Book_Char4,
              resultData.First_appearance_ephhk_Book_Char5,
              resultData.First_appearance_ephhk_Book_Char6,
              resultData.First_appearance_ephhk_Book_Char7,
              resultData.First_appearance_ephhk_Book_Char8,
              resultData.First_appearance_ephhk_Year_Char1,
              resultData.First_appearance_ephhk_Year_Char2,
              resultData.First_appearance_ephhk_Year_Char3,
              resultData.First_appearance_ephhk_Year_Char4,
              resultData.First_appearance_ephhk_Year_Char5,
              resultData.First_appearance_ephhk_Year_Char6,
              resultData.First_appearance_ephhk_Year_Char7,
              resultData.First_appearance_ephhk_Year_Char8,
              resultData.CausalType,
            ]);
            // console.log("csv data no tag "+JSON.stringify(csvData))
          }
          if (word == "\n") {
            paragraphCount++;
          }
          // console.log("csv result : " + JSON.stringify(notWordList));
        });
        //Set Header of CSV

        var csvHeader = [
          "",
          "Words_CH",
          "Part_of_Speech",
          "Paragraph#",
          "Sentence#",
          "Char1",
          "Char2",
          "Char3",
          "Char4",
          "Char5",
          "Char6",
          "Char7",
          "Char8",
          "Word length",
          "WordFreq_CNCORPUS",
          "WordFreq_SUBTLEX-CH",
          "WordFreq_Sinica Corpus",
          "WordFreq_DaJun",
          "Word_RT",
          "Word_RTSD",
          "Word_ERR",
          "Stroke_char1",
          "Stroke_char2",
          "Stroke_char3",
          "Stroke_char4",
          "Stroke_char5",
          "Stroke_char6",
          "Stroke_char7",
          "Stroke_char8",
          "Freq_CNCORPUS_char1",
          "Freq_CNCORPUS_char2",
          "Freq_CNCORPUS_char3",
          "Freq_CNCORPUS_char4",
          "Freq_CNCORPUS_char5",
          "Freq_CNCORPUS_char6",
          "Freq_CNCORPUS_char7",
          "Freq_CNCORPUS_char8",
          "Freq_SUBTLEX-CH_char1",
          "Freq_SUBTLEX-CH_char2",
          "Freq_SUBTLEX-CH_char3",
          "Freq_SUBTLEX-CH_char4",
          "Freq_SUBTLEX-CH_char5",
          "Freq_SUBTLEX-CH_char6",
          "Freq_SUBTLEX-CH_char7",
          "Freq_SUBTLEX-CH_char8",
          "Freq_Sinica_char1",
          "Freq_Sinica_char2",
          "Freq_Sinica_char3",
          "Freq_Sinica_char4",
          "Freq_Sinica_char5",
          "Freq_Sinica_char6",
          "Freq_Sinica_char7",
          "Freq_Sinica_char8",
          "Freq_DaJun-CH_char1",
          "Freq_DaJun-CH_char2",
          "Freq_DaJun-CH_char3",
          "Freq_DaJun-CH_char4",
          "Freq_DaJun-CH_char5",
          "Freq_DaJun-CH_char6",
          "Freq_DaJun-CH_char7",
          "Freq_DaJun-CH_char8",
          "Freq_CUHK_char1",
          "Freq_CUHK_char2",
          "Freq_CUHK_char3",
          "Freq_CUHK_char4",
          "Freq_CUHK_char5",
          "Freq_CUHK_char6",
          "Freq_CUHK_char7",
          "Freq_CUHK_char8",
          "AOA_Cai_char1",
          "AOA_Cai_char2",
          "AOA_Cai_char3",
          "AOA_Cai_char4",
          "AOA_Cai_char5",
          "AOA_Cai_char6",
          "AOA_Cai_char7",
          "AOA_Cai_char8",
          "AOA_Cai2_char1",
          "AOA_Cai2_char2",
          "AOA_Cai2_char3",
          "AOA_Cai2_char4",
          "AOA_Cai2_char5",
          "AOA_Cai2_char6",
          "AOA_Cai2_char7",
          "AOA_Cai2_char8",
          "AOA_Cai3_char1",
          "AOA_Cai3_char2",
          "AOA_Cai3_char3",
          "AOA_Cai3_char4",
          "AOA_Cai3_char5",
          "AOA_Cai3_char6",
          "AOA_Cai3_char7",
          "AOA_Cai3_char8",
          "AOA_Shu_char1",
          "AOA_Shu_char2",
          "AOA_Shu_char3",
          "AOA_Shu_char4",
          "AOA_Shu_char5",
          "AOA_Shu_char6",
          "AOA_Shu_char7",
          "AOA_Shu_char8",
          "AOA_Liu_char1",
          "AOA_Liu_char2",
          "AOA_Liu_char3",
          "AOA_Liu_char4",
          "AOA_Liu_char5",
          "AOA_Liu_char6",
          "AOA_Liu_char7",
          "AOA_Liu_char8",
          "nMeaning_char1",
          "nMeaning_char2",
          "nMeaning_char3",
          "nMeaning_char4",
          "nMeaning_char5",
          "nMeaning_char6",
          "nMeaning_char7",
          "nMeaning_char8",
          "RT_char1",
          "RT_char2",
          "RT_char3",
          "RT_char4",
          "RT_char5",
          "RT_char6",
          "RT_char7",
          "RT_char8",
          "RTSD_char1",
          "RTSD_char2",
          "RTSD_char3",
          "RTSD_char4",
          "RTSD_char5",
          "RTSD_char6",
          "RTSD_char7",
          "RTSD_char8",
          "ERR_char1",
          "ERR_char2",
          "ERR_char3",
          "ERR_char4",
          "ERR_char5",
          "ERR_char6",
          "ERR_char7",
          "ERR_char8",
          "Cantonese_Pinyin_Word",
          "Cantonese_Pinyin_char1",
          "Cantonese_Pinyin_char2",
          "Cantonese_Pinyin_char3",
          "Cantonese_Pinyin_char4",
          "Cantonese_Pinyin_char5",
          "Cantonese_Pinyin_char6",
          "Cantonese_Pinyin_char7",
          "Cantonese_Pinyin_char8",
          "Homophone#_Char1",
          "Homophone#_Char2",
          "Homophone#_Char3",
          "Homophone#_Char4",
          "Homophone#_Char5",
          "Homophone#_Char6",
          "Homophone#_Char7",
          "Homophone#_Char8",
          "HomphoneFreq_CNCORPUS_Char1",
          "HomphoneFreq_CNCORPUS_Char2",
          "HomphoneFreq_CNCORPUS_Char3",
          "HomphoneFreq_CNCORPUS_Char4",
          "HomphoneFreq_CNCORPUS_Char5",
          "HomphoneFreq_CNCORPUS_Char6",
          "HomphoneFreq_CNCORPUS_Char7",
          "HomphoneFreq_CNCORPUS_Char8",
          "HomphoneFreq_SUBTLEX_CH_Char1",
          "HomphoneFreq_SUBTLEX_CH_Char2",
          "HomphoneFreq_SUBTLEX_CH_Char3",
          "HomphoneFreq_SUBTLEX_CH_Char4",
          "HomphoneFreq_SUBTLEX_CH_Char5",
          "HomphoneFreq_SUBTLEX_CH_Char6",
          "HomphoneFreq_SUBTLEX_CH_Char7",
          "HomphoneFreq_SUBTLEX_CH_Char8",
          "HomphoneFreq_Freq_Sinica_Char1",
          "HomphoneFreq_Freq_Sinica_Char2",
          "HomphoneFreq_Freq_Sinica_Char3",
          "HomphoneFreq_Freq_Sinica_Char4",
          "HomphoneFreq_Freq_Sinica_Char5",
          "HomphoneFreq_Freq_Sinica_Char6",
          "HomphoneFreq_Freq_Sinica_Char7",
          "HomphoneFreq_Freq_Sinica_Char8",
          "HomphoneFreq_DaJun_Char1",
          "HomphoneFreq_DaJun_Char2",
          "HomphoneFreq_DaJun_Char3",
          "HomphoneFreq_DaJun_Char4",
          "HomphoneFreq_DaJun_Char5",
          "HomphoneFreq_DaJun_Char6",
          "HomphoneFreq_DaJun_Char7",
          "HomphoneFreq_DaJun_Char8",
          "HomphoneFreq_CUHK_Char1",
          "HomphoneFreq_CUHK_Char2",
          "HomphoneFreq_CUHK_Char3",
          "HomphoneFreq_CUHK_Char4",
          "HomphoneFreq_CUHK_Char5",
          "HomphoneFreq_CUHK_Char6",
          "HomphoneFreq_CUHK_Char7",
          "HomphoneFreq_CUHK_Char8",
          "First appearance_啟思_Book_Char1",
          "First appearance_啟思_Book_Char2",
          "First appearance_啟思_Book_Char3",
          "First appearance_啟思_Book_Char4",
          "First appearance_啟思_Book_Char5",
          "First appearance_啟思_Book_Char6",
          "First appearance_啟思_Book_Char7",
          "First appearance_啟思_Book_Char8",
          "First appearance_啟思_Year_Char1",
          "First appearance_啟思_Year_Char2",
          "First appearance_啟思_Year_Char3",
          "First appearance_啟思_Year_Char4",
          "First appearance_啟思_Year_Char5",
          "First appearance_啟思_Year_Char6",
          "First appearance_啟思_Year_Char7",
          "First appearance_啟思_Year_Char8",
          "First appearance_現代_Book_Char1",
          "First appearance_現代_Book_Char2",
          "First appearance_現代_Book_Char3",
          "First appearance_現代_Book_Char4",
          "First appearance_現代_Book_Char5",
          "First appearance_現代_Book_Char6",
          "First appearance_現代_Book_Char7",
          "First appearance_現代_Book_Char8",
          "First appearance_現代_Year_Char1",
          "First appearance_現代_Year_Char2",
          "First appearance_現代_Year_Char3",
          "First appearance_現代_Year_Char4",
          "First appearance_現代_Year_Char5",
          "First appearance_現代_Year_Char6",
          "First appearance_現代_Year_Char7",
          "First appearance_現代_Year_Char8",
          "First appearance_我愛學語文_Book_Char1",
          "First appearance_我愛學語文_Book_Char2",
          "First appearance_我愛學語文_Book_Char3",
          "First appearance_我愛學語文_Book_Char4",
          "First appearance_我愛學語文_Book_Char5",
          "First appearance_我愛學語文_Book_Char6",
          "First appearance_我愛學語文_Book_Char7",
          "First appearance_我愛學語文_Book_Char8",
          "First appearance_我愛學語文_Year_Char1",
          "First appearance_我愛學語文_Year_Char2",
          "First appearance_我愛學語文_Year_Char3",
          "First appearance_我愛學語文_Year_Char4",
          "First appearance_我愛學語文_Year_Char5",
          "First appearance_我愛學語文_Year_Char6",
          "First appearance_我愛學語文_Year_Char7",
          "First appearance_我愛學語文_Year_Char8",
          "CausalType",
        ];

        csvData.unshift(csvHeader);
        console.log("downloadResult " + JSON.stringify(csvData));
        // setData_csvData(csvData)

        const options = {
          fieldSeparator: ",",
          quoteStrings: '"',
          decimalSeparator: ".",
          showLabels: false,
          showTitle: false,
          title: "",
          useTextFile: false,
          useBom: true,
          useKeysAsHeaders: false,
          filename: "切詞結果",
          // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
        };

        const csvExporter = new ExportToCsv(options);

        csvExporter.generateCsv(csvData);

        downloadCountResult(csvData);
      }
    );
  }

  function downloadCountResult(_resultCsvDataList) {
    var csvData = [];

    var totalWordLength = 0; //total number of characters
    // var totalDataCount = _resultCsvDataList.length; //total number of rows

    //Part 1
    var WordLength_Mean_token_account = 0;
    var One_character_word_token_account = 0;
    var Two_character_word_token_account = 0;
    var Three_character_token_account = 0;
    var Four_character_word_token_account = 0;
    var Five_and_More_character_word_token_account = 0;
    var WordLength_Mean_type_account = 0;
    var One_character_word_type_account = 0;
    var Two_character_word_type_account = 0;
    var Three_character_type_account = 0;
    var Four_character_word_type_account = 0;
    var Five_and_More_character_word_type_account = 0;
    //Part 2
    var totalWordFreq_CNCORPUS = 0;
    var totalWordFreq_SUBTLEX_CH = 0;
    var totalWordFreq_Sinica = 0;
    var totalWordFreq_Dajun = 0;

    var WordFreq_CNCORPUS_Mean_token_count = 0;
    var WordFreq_CNCORPUS_Difficult_words_token_count = 0;
    var WordFreq_CNCORPUS_Difficult_words_percentage_token_count = 0;
    var WordFreq_CNCORPUS_Mean_type_count = 0;
    var WordFreq_CNCORPUS_Difficult_words_type_count = 0;
    var WordFreq_CNCORPUS_Difficult_words_percentage_type_count = 0;
    var WordFreq_SUBTLEX_CH_Mean_token_count = 0;
    var WordFreq_SUBTLEX_CH_Difficult_words_token_count = 0;
    var WordFreq_SUBTLEX_CH_Difficult_words_percentage_token_count = 0;
    var WordFreq_SUBTLEX_CH_Mean_type_count = 0;
    var WordFreq_SUBTLEX_CH_Difficult_words_type_count = 0;
    var WordFreq_SUBTLEX_CH_Difficult_words_percentage_type_count = 0;
    var WordFreq_Sinica_Mean_token_count = 0;
    var WordFreq_Sinica_Difficult_words_token_count = 0;
    var WordFreq_Sinica_Difficult_words_percentage_token_count = 0;
    var WordFreq_Sinica_Mean_type_count = 0;
    var WordFreq_Sinica_Difficult_words_type_count = 0;
    var WordFreq_Sinica_Difficult_words_percentage_type_count = 0;
    var WordFreq_Dajun_Mean_token_count = 0;
    var WordFreq_Dajun_Difficult_words_token_count = 0;
    var WordFreq_Dajun_Difficult_words_percentage_token_count = 0;
    var WordFreq_Dajun_Mean_type_count = 0;
    var WordFreq_Dajun_Difficult_words_type_count = 0;
    var WordFreq_Dajun_Difficult_words_percentage_type_count = 0;

    var WordFreq_CNCORPUS_invalid_count = 0;
    var WordFreq_SUBTLEX_CH_invalid_count = 0;
    var WordFreq_Sinica_invalid_count = 0;
    var WordFreq_Dajun_invalid_count = 0;

    //Part 3
    var RawNo_token_count_word = 0;
    var RawNo_type_count_word = 0;
    var Ratio_type_token_word = 0;
    var Once_word = 0;

    var RawNo_token_count_character = 0;
    var RawNo_type_count_character = 0;
    var Ratio_type_token_character = 0;
    var Once_character = 0;
    //Part 4
    var totalWord_RT = 0;
    var totalWord_RTSD = 0;
    var totalWord_ERR = 0;

    var Word_RT_token_count_Mean = 0;
    var Word_RTSD_token_count_Mean = 0;
    var Word_ERR_token_count_Mean = 0;
    var Word_RT_type_count_Mean = 0;
    var Word_RTSD_type_count_Mean = 0;
    var Word_ERR_type_count_Mean = 0;
    var RawNo_low_frequent_words_token_count = 0;
    var RawNo_low_frequent_words_type_count = 0;
    var Proportion_low_frequent_words_token_count = 0;
    var Proportion_low_frequent_words_type_count = 0;

    var Word_RT_invalid_count = 0;
    var Word_RTSD_invalid_count = 0;
    var Word_ERR_invalid_count = 0;

    //Part 5
    var totalStroke = 0;
    var totalStroke_less_than_ten = 0;
    var totalStroke_ten_to_twenty = 0;
    var totalStroke_greater_than_twenty = 0;

    var Stroke_Mean_token_count = 0;
    var less_than_10_percentage_token_count = 0;
    var between_10_and_20_percentage_token_count = 0;
    var more_than_20_percentage_token_count = 0;
    var Stroke_Mean_type_count = 0;
    var less_than_10_percentage_type_count = 0;
    var between_10_and_20_percentage_type_count = 0;
    var more_than_20_percentage_type_count = 0;

    var stroke_invalid_count = 0;
    //Part_6
    var totalCharacterFreq_CNCORPUS = 0;
    var totalCharacterFreq_SUBTLEX_CH = 0;
    var totalCharacterFreq_Sinica = 0;
    var totalCharacterFreq_Dajun = 0;
    var totalCharacterFreq_CUHK = 0;

    var CharacterFreq_CNCORPUS_Mean_token_count = 0;
    var CharacterFreq_CNCORPUS_Difficult_words_token_count = 0;
    var CharacterFreq_CNCORPUS_Difficult_words_percentage_token_count = 0;
    var CharacterFreq_CNCORPUS_Mean_type_count = 0;
    var CharacterFreq_CNCORPUS_Difficult_words_type_count = 0;
    var CharacterFreq_CNCORPUS_Difficult_words_percentage_type_count = 0;
    var CharacterFreq_SUBTLEX_CH_Mean_token_count = 0;
    var CharacterFreq_SUBTLEX_CH_Difficult_words_token_count = 0;
    var CharacterFreq_SUBTLEX_CH_Difficult_words_percentage_token_count = 0;
    var CharacterFreq_SUBTLEX_CH_Mean_type_count = 0;
    var CharacterFreq_SUBTLEX_CH_Difficult_words_type_count = 0;
    var CharacterFreq_SUBTLEX_CH_Difficult_words_percentage_type_count = 0;
    var CharacterFreq_Sinica_Mean_token_count = 0;
    var CharacterFreq_Sinica_Difficult_words_token_count = 0;
    var CharacterFreq_Sinica_Difficult_words_percentage_token_count = 0;
    var CharacterFreq_Sinica_Mean_type_count = 0;
    var CharacterFreq_Sinica_Difficult_words_type_count = 0;
    var CharacterFreq_Sinica_Difficult_words_percentage_type_count = 0;
    var CharacterFreq_DaJun_Mean_token_count = 0;
    var CharacterFreq_DaJun_Difficult_words_token_count = 0;
    var CharacterFreq_DaJun_Difficult_words_percentage_token_count = 0;
    var CharacterFreq_DaJun_Mean_type_count = 0;
    var CharacterFreq_DaJun_Difficult_words_type_count = 0;
    var CharacterFreq_DaJun_Difficult_words_percentage_type_count = 0;
    var CharacterFreq_CUHK_Mean_token_count = 0;
    var CharacterFreq_CUHK_Difficult_words_token_count = 0;
    var CharacterFreq_CUHK_Difficult_words_percentage_token_count = 0;
    var CharacterFreq_CUHK_Mean_type_count = 0;
    var CharacterFreq_CUHK_Difficult_words_type_count = 0;
    var CharacterFreq_CUHK_Difficult_words_percentage_type_count = 0;

    //Part_7
    var totalAOA_Cai = 0;
    var totalAOA_Cai2 = 0;
    var totalAOA_Cai3 = 0;
    var totalAOA_Shu = 0;
    var totalAOA_Liu = 0;
    var totalnMeaing = 0;

    var totalChar_RT = 0;
    var totalChar_ERR = 0;
    var totalHomophone = 0;

    var AOA_Cai_token_count = 0;
    var AOA_Cai2_token_count = 0;
    var AOA_Cai3_token_count = 0;
    var AOA_Shu_token_count = 0;
    var AOA_Liu_token_count = 0;
    var AOA_Cai_type_count = 0;
    var AOA_Cai2_type_count = 0;
    var AOA_Cai3_type_count = 0;
    var AOA_Shu_type_count = 0;
    var AOA_Liu_type_count = 0;
    var nMeaing_token_count = 0;
    var nMeaing_type_count = 0;
    var RawNo_OneCharcWord_token_count = 0;
    var RawNo_OneCharcWord_type_count = 0;
    var SummedFreq_OneCharcWord_CNCORPUS_token_count = 0;
    var SummedFreq_OneCharcWord_CNCORPUS_type_count = 0;
    var SummedFreq_OneCharcWord_SUBTLEX_CH_token_count = 0;
    var SummedFreq_OneCharcWord_SUBTLEX_CH_type_count = 0;
    var SummedFreq_OneCharcWord_Sinica_token_count = 0;
    var SummedFreq_OneCharcWord_Sinica_type_count = 0;
    var SummedFreq_OneCharcWord_DaJun_token_count = 0;
    var SummedFreq_OneCharcWord_DaJun_type_count = 0;
    var SummedFreq_OneCharcWord_CUHK_token_count = 0;
    var SummedFreq_OneCharcWord_CUHK_type_count = 0;
    var RT_character_token_count = 0;
    var RT_character_type_count = 0;
    var ERR_character_token_count = 0;
    var ERR_character_type_count = 0;
    var HomophonesNo_of_a_character_token_count = 0;
    var HomophonesNo_of_a_character_type_count = 0;
    var SummedFreq_Homophones_CNCORPUS_token_count = 0;
    var SummedFreq_Homophones_CNCORPUS_type_count = 0;
    var SummedFreq_Homophones_SUBTLEX_CH_token_count = 0;
    var SummedFreq_Homophones_SUBTLEX_CH_type_count = 0;
    var SummedFreq_Homophones_Sinica_token_count = 0;
    var SummedFreq_Homophones_Sinica_type_count = 0;
    var SummedFreq_Homophones_DaJun_token_count = 0;
    var SummedFreq_Homophones_DaJun_type_count = 0;
    var SummedFreq_Homophones_CUHK_token_count = 0;
    var SummedFreq_Homophones_CUHK_type_count = 0;

    var AOA_Cai_invalid_count = 0;
    var AOA_Cai2_invalid_count = 0;
    var AOA_Cai3_invalid_count = 0;
    var AOA_Shu_invalid_count = 0;
    var AOA_Liu_invalid_count = 0;
    var nMeaning_invalid_count = 0;
    var RT_invalid_count = 0;
    var ERR_invalid_count = 0;
    var HomophonesNo_of_a_character_invalid_count = 0;
    //Part_8
    var totalKeysBook = 0;
    var totalKeysYear = 0;
    var totalNowBook = 0;
    var totalNowYear = 0;
    var totalEphhkBook = 0;
    var totalEphhkYear = 0;

    var First_appearance_keys_Book_token_count = 0;
    var First_appearance_keys_Year_token_count = 0;
    var First_appearance_now_Book_token_count = 0;
    var First_appearance_now_Year_token_count = 0;
    var First_appearance_ephhk_Book_token_count = 0;
    var First_appearance_ephhk_Year_token_count = 0;
    var First_appearance_keys_Book_type_count = 0;
    var First_appearance_keys_Year_type_count = 0;
    var First_appearance_now_Book_type_count = 0;
    var First_appearance_now_Year_type_count = 0;
    var First_appearance_ephhk_Book_type_count = 0;
    var First_appearance_ephhk_Year_type_count = 0;

    var First_appearance_keys_Book_invalid_count = 0;
    var First_appearance_keys_Year_invalid_count = 0;
    var First_appearance_now_Book_invalid_count = 0;
    var First_appearance_now_Year_invalid_count = 0;
    var First_appearance_ephhk_Book_invalid_count = 0;
    var First_appearance_ephhk_Year_invalid_count = 0;
    //Part_9
    var totalNumberOfSpecialSentence = 0;
    // var totalNumberOfSpecialWordsSentence = 0;
    var totalNumberOfSpecialClause = 0;
    // var totalNumberOfSpecialWordsClause = 0;

    var CharacterNo_Sentence = 0;
    var WordNo_Sentence = 0;
    var CharacterNo_Clause = 0;
    var WordNo_Clause = 0;
    //Part_10
    var total_part_of_speech = 0;

    var Adjective_RawNo = 0;
    var Conjunction_RawNo = 0;
    var Postposition_RawNo = 0;
    var Adverb_RawNo = 0;
    var Noun_RawNo = 0;
    var Determiner_RawNo = 0;
    var Particle_RawNo = 0;
    var Preposition_RawNo = 0;
    var Verb_RawNo = 0;
    var Content_word_RawNo = 0;
    var Adjective_percentage = 0;
    var Conjunction_percentage = 0;
    var Postposition_percentage = 0;
    var Adverb_percentage = 0;
    var Noun_percentage = 0;
    var Determiner_percentage = 0;
    var Particle_percentage = 0;
    var Preposition_percentage = 0;
    var Verb_percentage = 0;
    var Content_word_Percentage = 0;
    //Part_12
    var precedent_RawNO = 0;
    var cause_RawNO = 0;
    var adversative_RawNO = 0;
    var coordination_RawNO = 0;
    var additive_RawNO = 0;
    var successor_RawNO = 0;
    var inference_RawNO = 0;
    var condition_RawNO = 0;
    var supposition_RawNO = 0;
    var concession_RawNO = 0;
    var purpose_RawNO = 0;
    var frequency_RawNO = 0;
    var parenthesis_RawNO = 0;
    var abandonment_RawNO = 0;
    var result_RawNO = 0;
    var comparative_RawNO = 0;
    var preference_RawNO = 0;
    var summary_RawNO = 0;
    var recount_RawNO = 0;
    var temporal_RawNO = 0;

    //Token Case
    _resultCsvDataList.map((item, index) => {
      // console.log("item " + JSON.stringify(item));
      if (index > 0) {
        //the first row should be header
        var word = item[1];
        var word_length = Number(item[13]);
        // console.log("Word Length: " + word_length);
        totalWordLength += word_length;
        //Token
        if (word_length == 1) {
          One_character_word_token_account += 1;
          RawNo_OneCharcWord_token_count += 1;
        } else if (word_length == 2) {
          Two_character_word_token_account += 1;
        } else if (word_length == 3) {
          Three_character_token_account += 1;
        } else if (word_length == 4) {
          Four_character_word_token_account += 1;
        } else if (word_length >= 5) {
          Five_and_More_character_word_token_account += 1;
        }
        var WordFreq_CNCORPUS = item[14];
        if (WordFreq_CNCORPUS != "N/A") {
          totalWordFreq_CNCORPUS += Number(WordFreq_CNCORPUS);
        } else {
          if (word_length > 0) {
            WordFreq_CNCORPUS_invalid_count += 1;
          }
        }

        if (WordFreq_CNCORPUS == null) {
          WordFreq_CNCORPUS_Difficult_words_token_count += 1;
        }
        var WordFreq_SUBTLEX_CH = item[15];
        if (WordFreq_SUBTLEX_CH != "N/A") {
          totalWordFreq_SUBTLEX_CH += Number(WordFreq_SUBTLEX_CH);
        } else {
          if (word_length > 0) {
            WordFreq_SUBTLEX_CH_invalid_count += 1;
          }
        }
        if (WordFreq_SUBTLEX_CH == null) {
          WordFreq_SUBTLEX_CH_Difficult_words_token_count += 1;
        }
        var WordFreq_Sinica = item[16];
        if (WordFreq_Sinica != "N/A") {
          totalWordFreq_Sinica += Number(WordFreq_Sinica);
        } else {
          if (word_length > 0) {
            WordFreq_Sinica_invalid_count += 1;
          }
        }
        if (WordFreq_Sinica == null) {
          WordFreq_Sinica_Difficult_words_token_count += 1;
        }
        var WordFreq_Dajun = item[17];
        if (WordFreq_Dajun != "N/A") {
          totalWordFreq_Dajun += Number(WordFreq_Dajun);
        } else {
          if (word_length > 0) {
            WordFreq_Dajun_invalid_count += 1;
          }
        }
        if (WordFreq_Dajun == null) {
          WordFreq_Dajun_Difficult_words_token_count += 1;
        }

        if (
          word != undefined &&
          word != null &&
          word != "" &&
          word_length > 0
        ) {
          RawNo_token_count_word += 1;
        }

        var Word_RT = item[18];
        if (Word_RT != "N/A") {
          totalWord_RT += Number(Word_RT);
        } else {
          if (word_length > 0) {
            Word_RT_invalid_count += 1;
          }
        }
        if (Word_RT == null) {
          RawNo_low_frequent_words_token_count += 1;
        }
        var Word_RTSD = item[19];
        if (Word_RTSD != "N/A") {
          totalWord_RTSD += Number(Word_RTSD);
        } else {
          if (word_length > 0) {
            Word_RTSD_invalid_count += 1;
          }
        }
        var Word_ERR = item[20];
        if (Word_ERR != "N/A") {
          totalWord_ERR += Number(Word_ERR);
        } else {
          if (word_length > 0) {
            Word_ERR_invalid_count += 1;
          }
        }

        // totalStroke = GetCharCount(item, 22, 29);
        for (var i = 21; i < 28; i++) {
          var stroke = item[i];
          if (stroke != "N/A") {
            totalStroke += Number(stroke);
          } else {
            if (word_length > 0) {
              stroke_invalid_count += 1;
            }
          }
          if (typeof stroke == "number") {
            if (stroke < 10) {
              // console.log("stroke is < 10 " + stroke+ typeof(stroke));
              totalStroke_less_than_ten += 1;
            }
            if (stroke >= 10 && stroke <= 20) {
              totalStroke_ten_to_twenty += 1;
            }
            if (stroke > 20) {
              totalStroke_greater_than_twenty += 1;
            }
          }
        }

        // totalCharacterFreq_CNCORPUS = GetCharCount(item, 30, 37);
        for (var i = 29; i < 36; i++) {
          var data = item[i];
          if (data != "N/A" && word_length > 0) {
            totalCharacterFreq_CNCORPUS += Number(data);
          } else {
            if (word_length > 0) {
              CharacterFreq_CNCORPUS_Difficult_words_token_count += 1;
            }
          }
          // if (data == null) {
          //   CharacterFreq_CNCORPUS_Difficult_words_token_count += 1;
          // }
        }
        // totalCharacterFreq_SUBTLEX_CH = GetCharCount(item,38,45);
        for (var i = 37; i < 44; i++) {
          var data = item[i];
          if (data != "N/A" && word_length > 0) {
            totalCharacterFreq_SUBTLEX_CH += Number(data);
          } else {
            if (word_length > 0) {
              CharacterFreq_SUBTLEX_CH_Difficult_words_token_count += 1;
            }
          }

          // if (data == null) {
          //   WordFreq_CNCORPUS_Difficult_words_token_count += 1;
          // }
        }
        // totalCharacterFreq_Sinica = GetCharCount(item,46,53);
        for (var i = 45; i < 52; i++) {
          var data = item[i];
          if (data != "N/A" && word_length > 0) {
            totalCharacterFreq_Sinica += Number(data);
          } else {
            if (word_length > 0) {
              CharacterFreq_Sinica_Difficult_words_token_count += 1;
            }
          }
          // if (data == null) {
          //   CharacterFreq_Sinica_Difficult_words_token_count += 1;
          // }
        }
        // totalCharacterFreq_Dajun = GetCharCount(item,54,61);
        for (var i = 53; i < 60; i++) {
          var data = item[i];
          if (data != "N/A" && word_length > 0) {
            totalCharacterFreq_Dajun += Number(data);
          } else {
            if (word_length > 0) {
              CharacterFreq_DaJun_Difficult_words_token_count += 1;
            }
          }

          // if (data == null) {
          //   CharacterFreq_DaJun_Difficult_words_token_count += 1;
          // }
        }
        // totalCharacterFreq_CUHK = GetCharCount(item,62,69);
        for (var i = 61; i < 68; i++) {
          var data = item[i];
          if (data != "N/A" && word_length > 0) {
            totalCharacterFreq_CUHK += Number(data);
          } else {
            if (word_length > 0) {
              CharacterFreq_CUHK_Difficult_words_token_count += 1;
            }
          }
        }

        totalAOA_Cai += GetCharCount(item, 69, 76);
        totalAOA_Cai2 += GetCharCount(item, 77, 84);
        totalAOA_Cai3 += GetCharCount(item, 85, 92);
        totalAOA_Shu += GetCharCount(item, 93, 100);
        totalAOA_Liu += GetCharCount(item, 101, 108);
        totalnMeaing += GetCharCount(item, 109, 116);
        totalChar_RT += GetCharCount(item, 117, 124);
        totalChar_ERR += GetCharCount(item, 133, 140);

        AOA_Cai_invalid_count += GetCharInvalidCount(item, 69, 76, word_length);
        AOA_Cai2_invalid_count += GetCharInvalidCount(
          item,
          77,
          84,
          word_length
        );
        AOA_Cai3_invalid_count += GetCharInvalidCount(
          item,
          85,
          92,
          word_length
        );
        AOA_Shu_invalid_count += GetCharInvalidCount(
          item,
          93,
          100,
          word_length
        );
        AOA_Liu_invalid_count += GetCharInvalidCount(
          item,
          101,
          108,
          word_length
        );
        nMeaning_invalid_count += GetCharInvalidCount(
          item,
          109,
          116,
          word_length
        );
        RT_invalid_count += GetCharInvalidCount(item, 117, 124, word_length);
        ERR_invalid_count += GetCharInvalidCount(item, 133, 140, word_length);

        if (typeof item[29] == "number" && word_length == 1) {
          SummedFreq_OneCharcWord_CNCORPUS_token_count += item[29];
        }
        if (typeof item[37] == "number" && word_length == 1) {
          SummedFreq_OneCharcWord_SUBTLEX_CH_token_count += item[37];
        }
        if (typeof item[45] == "number" && word_length == 1) {
          SummedFreq_OneCharcWord_Sinica_token_count += item[45];
        }
        if (typeof item[53] == "number" && word_length == 1) {
          SummedFreq_OneCharcWord_DaJun_token_count += item[53];
        }
        if (typeof item[61] == "number" && word_length == 1) {
          SummedFreq_OneCharcWord_CUHK_token_count += item[61];
        }

        totalHomophone += GetCharCount(item, 150, 157);
        HomophonesNo_of_a_character_token_count += GetCharInvalidCount(item, 150, 157, word_length);
        SummedFreq_Homophones_CNCORPUS_token_count += GetCharCount(
          item,
          158,
          165
        );
        SummedFreq_Homophones_SUBTLEX_CH_token_count += GetCharCount(
          item,
          166,
          173
        );
        SummedFreq_Homophones_Sinica_token_count += GetCharCount(
          item,
          174,
          181
        );
        SummedFreq_Homophones_DaJun_token_count += GetCharCount(item, 182, 189);
        SummedFreq_Homophones_CUHK_token_count += GetCharCount(item, 190, 197);

        totalKeysBook += GetCharCount(item, 198, 205);
        totalKeysYear += GetCharCount(item, 206, 213);
        totalNowBook += GetCharCount(item, 214, 221);
        totalNowYear += GetCharCount(item, 222, 229);
        totalEphhkBook += GetCharCount(item, 230, 237);
        totalEphhkYear += GetCharCount(item, 238, 245);

        First_appearance_keys_Book_invalid_count += GetCharInvalidCount(
          item,
          198,
          205,
          word_length
        );
        First_appearance_keys_Year_invalid_count += GetCharInvalidCount(
          item,
          206,
          213,
          word_length
        );
        First_appearance_now_Book_invalid_count += GetCharInvalidCount(
          item,
          214,
          221,
          word_length
        );
        First_appearance_now_Year_invalid_count += GetCharInvalidCount(
          item,
          222,
          229,
          word_length
        );
        First_appearance_ephhk_Book_invalid_count += GetCharInvalidCount(
          item,
          230,
          237,
          word_length
        );
        First_appearance_ephhk_Year_invalid_count += GetCharInvalidCount(
          item,
          238,
          245,
          word_length
        );

        var part_of_speech = item[2];
        switch (part_of_speech) {
          case "A":
            Adjective_RawNo += 1;
            break;
          case "C":
            Conjunction_RawNo += 1;
            break;
          case "POST":
            Postposition_RawNo += 1;
            break;
          case "ADV":
          case "ASP":
            Adverb_RawNo += 1;
            break;
          case "N":
          case "M":
            Noun_RawNo += 1;
            break;
          case "DET":
            Determiner_RawNo += 1;
            break;
          case "T":
            Particle_RawNo += 1;
            break;
          case "P":
            Preposition_RawNo += 1;
            break;
          case "Nv":
          case "Vi":
          case "Vt":
            Verb_RawNo += 1;
            break;
          default:
            break;
        }

        if (
          word == "。" ||
          word == "！" ||
          word == "——" ||
          word == "……" ||
          word == "？"
        ) {
          totalNumberOfSpecialSentence += 1;
          totalNumberOfSpecialClause += 1;
        }

        if (word == "，" || word == "：" || word == "；") {
          totalNumberOfSpecialClause += 1;
        }

        var CausalType = item[246];
        console.log("CausalType: " + CausalType);
        switch (CausalType) {
          case "Precedent":
            precedent_RawNO += 1;
            break;
          case "Cause":
            cause_RawNO += 1;
            break;
          case "Adversative":
            adversative_RawNO += 1;
            break;
          case "Coordination":
            coordination_RawNO += 1;
            break;
          case "Additive":
            additive_RawNO += 1;
            break;
          case "Successor":
            successor_RawNO += 1;
            break;
          case "Inference":
            inference_RawNO += 1;
            break;
          case "Condition":
            condition_RawNO += 1;
            break;
          case "Supposition":
            supposition_RawNO += 1;
            break;
          case "Concession":
            concession_RawNO += 1;
            break;
          case "Purpose":
            purpose_RawNO += 1;
            break;
          case "Frequency":
            frequency_RawNO += 1;
            break;
          case "Parenthesis":
            parenthesis_RawNO += 1;
            break;
          case "Abandonment":
            abandonment_RawNO += 1;
            break;
          case "Results":
            result_RawNO += 1;
            break;
          case "Comparative":
            comparative_RawNO += 1;
            break;
          case "Preference":
            preference_RawNO += 1;
            break;
          case "Summary":
            summary_RawNO += 1;
            break;
          case "Recount":
            recount_RawNO += 1;
            break;
          case "Temporal":
            temporal_RawNO += 1;
            break;
          default:
            break;
        }
      }
    });

    RawNo_token_count_character = totalWordLength;
    WordLength_Mean_token_account = totalWordLength / RawNo_token_count_word;
    // console.log("WordLength_Mean_token_account totalWordLength " + totalWordLength+ " RawNo_token_count_word " + RawNo_token_count_word);

    One_character_word_token_account =
      (100 * One_character_word_token_account) / RawNo_token_count_word;
    Two_character_word_token_account =
      (100 * Two_character_word_token_account) / RawNo_token_count_word;
    Three_character_token_account =
      (100 * Three_character_token_account) / RawNo_token_count_word;
    Four_character_word_token_account =
      (100 * Four_character_word_token_account) / RawNo_token_count_word;
    Five_and_More_character_word_token_account =
      (100 * Five_and_More_character_word_token_account) /
      RawNo_token_count_word;

    // console.log("One_character_word_token_account " + One_character_word_token_account+" Two_character_word_token_account "+Two_character_word_token_account+" Three_character_token_account "+Three_character_token_account+" Four_character_word_token_account "+Four_character_word_token_account+" Five_and_More_character_word_token_account "+Five_and_More_character_word_token_account);

    WordFreq_CNCORPUS_Difficult_words_token_count = WordFreq_CNCORPUS_invalid_count;
    var valid_CNCORPUS_RawNo_token_count_word = GetValidTokenCount(
      RawNo_token_count_word,
      WordFreq_CNCORPUS_invalid_count
    ); // Need to minus the N/A word's count
    WordFreq_CNCORPUS_Mean_token_count =
      totalWordFreq_CNCORPUS / valid_CNCORPUS_RawNo_token_count_word;
    // console.log("WordFreq_CNCORPUS_Mean_token_count totalWordFreq_CNCORPUS: " + totalWordFreq_CNCORPUS + " valid_CNCORPUS_RawNo_token_count_word: " + valid_CNCORPUS_RawNo_token_count_word + " WordFreq_CNCORPUS_Mean_token_count " + WordFreq_CNCORPUS_Mean_token_count);
    WordFreq_CNCORPUS_Difficult_words_percentage_token_count =
      (100 * WordFreq_CNCORPUS_invalid_count) / RawNo_token_count_word;
    // console.log("WordFreq_CNCORPUS_Difficult_words_percentage_token_count RawNo_token_count_word: " + RawNo_token_count_word + " WordFreq_CNCORPUS_invalid_count: " + WordFreq_CNCORPUS_invalid_count + " WordFreq_CNCORPUS_Difficult_words_percentage_token_count " + WordFreq_CNCORPUS_Difficult_words_percentage_token_count);

    WordFreq_SUBTLEX_CH_Difficult_words_token_count = WordFreq_SUBTLEX_CH_invalid_count;
    var valid_SUBTLEX_CH_RawNo_token_count_word = GetValidTokenCount(
      RawNo_token_count_word,
      WordFreq_SUBTLEX_CH_invalid_count
    ); // Need to minus the N/A word's count
    WordFreq_SUBTLEX_CH_Mean_token_count =
      totalWordFreq_SUBTLEX_CH / valid_SUBTLEX_CH_RawNo_token_count_word;
    // console.log("WordFreq_SUBTLEX_CH_Mean_token_count "+WordFreq_SUBTLEX_CH_Mean_token_count+" totalWordFreq_SUBTLEX_CH "+totalWordFreq_SUBTLEX_CH+" valid_SUBTLEX_CH_RawNo_token_count_word "+valid_SUBTLEX_CH_RawNo_token_count_word);
    WordFreq_SUBTLEX_CH_Difficult_words_percentage_token_count =
      (100 * WordFreq_SUBTLEX_CH_invalid_count) / RawNo_token_count_word;
    // console.log("WordFreq_SUBTLEX_CH_Difficult_words_percentage_token_count "+WordFreq_SUBTLEX_CH_Difficult_words_percentage_token_count+" WordFreq_SUBTLEX_CH_Difficult_words_token_count "+WordFreq_SUBTLEX_CH_Difficult_words_token_count+" totalWordFreq_SUBTLEX_CH "+totalWordFreq_SUBTLEX_CH);

    WordFreq_Sinica_Difficult_words_token_count = WordFreq_Sinica_invalid_count;
    var valid_Sinica_RawNo_token_count_word = GetValidTokenCount(
      RawNo_token_count_word,
      WordFreq_Sinica_invalid_count
    ); // Need to minus the N/A word's count
    WordFreq_Sinica_Mean_token_count =
      totalWordFreq_Sinica / valid_Sinica_RawNo_token_count_word;
    // console.log("WordFreq_Sinica_Mean_token_count "+WordFreq_Sinica_Mean_token_count+" totalWordFreq_Sinica "+totalWordFreq_Sinica+" RawNo_token_count_word "+RawNo_token_count_word);
    WordFreq_Sinica_Difficult_words_percentage_token_count =
      (100 * WordFreq_Sinica_invalid_count) / RawNo_token_count_word;
    // console.log("WordFreq_Sinica_Difficult_words_percentage_token_count WordFreq_Sinica_Difficult_words_token_count: " + WordFreq_Sinica_Difficult_words_token_count + " totalWordFreq_Sinica: " + totalWordFreq_Sinica + " WordFreq_Sinica_Difficult_words_percentage_token_count " + WordFreq_Sinica_Difficult_words_percentage_token_count);

    WordFreq_Dajun_Difficult_words_token_count = WordFreq_Dajun_invalid_count;
    var valid_Dajun_RawNo_token_count_word = GetValidTokenCount(
      RawNo_token_count_word,
      WordFreq_Dajun_invalid_count
    ); // Need to minus the N/A word's count
    WordFreq_Dajun_Mean_token_count =
      totalWordFreq_Dajun / valid_Dajun_RawNo_token_count_word;
    // console.log("WordFreq_Dajun_Mean_token_count "+WordFreq_Dajun_Mean_token_count+" totalWordFreq_Dajun "+totalWordFreq_Dajun+" RawNo_token_count_word "+RawNo_token_count_word);
    WordFreq_Dajun_Difficult_words_percentage_token_count =
      (100 * WordFreq_Dajun_invalid_count) / RawNo_token_count_word;
    // console.log("WordFreq_Dajun_Difficult_words_percentage_token_count " + WordFreq_Dajun_Difficult_words_percentage_token_count + " WordFreq_Dajun_invalid_count " + WordFreq_Dajun_invalid_count + " RawNo_token_count_word " + RawNo_token_count_word);

    Word_RT_token_count_Mean =
      totalWord_RT /
      GetValidTokenCount(RawNo_token_count_word, Word_RT_invalid_count);
    Word_RTSD_token_count_Mean =
      totalWord_RTSD /
      GetValidTokenCount(RawNo_token_count_word, Word_RTSD_invalid_count);
    Word_ERR_token_count_Mean =
      totalWord_ERR /
      GetValidTokenCount(RawNo_token_count_word, Word_ERR_invalid_count);

    // console.log("Word_RT_token_count_Mean " + Word_RT_token_count_Mean + " Word_RTSD_token_count_Mean: " + Word_RTSD_token_count_Mean + " Word_ERR_token_count_Mean " + Word_ERR_token_count_Mean);

    RawNo_low_frequent_words_token_count = Word_RT_invalid_count;
    Proportion_low_frequent_words_token_count =
      RawNo_low_frequent_words_token_count / RawNo_token_count_word;
    // console.log("RawNo_low_frequent_words_token_count " + RawNo_low_frequent_words_token_count + " Proportion_low_frequent_words_token_count: " + Proportion_low_frequent_words_token_count);

    Stroke_Mean_token_count =
      totalStroke / GetValidTokenCount(totalWordLength, stroke_invalid_count);
    
    less_than_10_percentage_token_count =
      (100 * totalStroke_less_than_ten) / totalWordLength;//GetValidTokenCount(totalWordLength, stroke_invalid_count);
      // (100 * totalStroke_ten_to_twenty) / totalWordLength;
      more_than_20_percentage_token_count =
      (100 * totalStroke_greater_than_twenty) / totalWordLength;// GetValidTokenCount(totalWordLength, stroke_invalid_count);
      console.log("less_than_10_percentage_token_count " + less_than_10_percentage_token_count + " between_10_and_20_percentage_token_count: " + between_10_and_20_percentage_token_count+" more_than_20_percentage_token_count "+more_than_20_percentage_token_count );
      between_10_and_20_percentage_token_count = (100 * totalStroke_ten_to_twenty) / totalWordLength;//100 - less_than_10_percentage_token_count - more_than_20_percentage_token_count;

    CharacterFreq_CNCORPUS_Mean_token_count =
      totalCharacterFreq_CNCORPUS / GetValidTokenCount(totalWordLength, CharacterFreq_CNCORPUS_Difficult_words_token_count); 
      console.log("Test CharacterFreq_CNCORPUS_Mean_token_count "+totalCharacterFreq_CNCORPUS +" GetValidTokenCount "+GetValidTokenCount(totalWordLength, CharacterFreq_CNCORPUS_Difficult_words_token_count)+" totalWordLength "+totalWordLength+" CharacterFreq_CNCORPUS_Difficult_words_type_count "+CharacterFreq_CNCORPUS_Difficult_words_token_count )
    
    CharacterFreq_CNCORPUS_Difficult_words_percentage_token_count =
      (100 * CharacterFreq_CNCORPUS_Difficult_words_token_count) / totalWordLength;
      //GetValidTokenCount(totalWordLength, CharacterFreq_CNCORPUS_Difficult_words_token_count); 
    
      // console.log("totalCharacterFreq_CNCORPUS: "+totalCharacterFreq_CNCORPUS+" "+" CharacterFreq_CNCORPUS_Difficult_words_token_count " + CharacterFreq_CNCORPUS_Difficult_words_token_count+" after handle: "+GetValidTokenCount(totalWordLength, CharacterFreq_CNCORPUS_Difficult_words_token_count));
    
    
    CharacterFreq_SUBTLEX_CH_Mean_token_count =
      totalCharacterFreq_SUBTLEX_CH / GetValidTokenCount(totalWordLength, CharacterFreq_SUBTLEX_CH_Difficult_words_token_count);
    CharacterFreq_SUBTLEX_CH_Difficult_words_percentage_token_count =
      (100 * CharacterFreq_SUBTLEX_CH_Difficult_words_token_count) /totalWordLength;
      // GetValidTokenCount(totalWordLength, CharacterFreq_SUBTLEX_CH_Difficult_words_token_count);
    CharacterFreq_Sinica_Mean_token_count =
      totalCharacterFreq_Sinica / GetValidTokenCount(totalWordLength, CharacterFreq_Sinica_Difficult_words_token_count);
    CharacterFreq_Sinica_Difficult_words_percentage_token_count =
      (100 * CharacterFreq_Sinica_Difficult_words_token_count) /totalWordLength;
      // GetValidTokenCount(totalWordLength, CharacterFreq_Sinica_Difficult_words_token_count);
    CharacterFreq_DaJun_Mean_token_count =
      totalCharacterFreq_Dajun / GetValidTokenCount(totalWordLength, CharacterFreq_DaJun_Difficult_words_token_count);
    CharacterFreq_DaJun_Difficult_words_percentage_token_count =
      (100 * CharacterFreq_DaJun_Difficult_words_token_count) / totalWordLength;//GetValidTokenCount(totalWordLength, CharacterFreq_DaJun_Difficult_words_token_count);
    CharacterFreq_CUHK_Mean_token_count =
      totalCharacterFreq_CUHK / GetValidTokenCount(totalWordLength, CharacterFreq_CUHK_Difficult_words_token_count);
    CharacterFreq_CUHK_Difficult_words_percentage_token_count =
      (100 * CharacterFreq_CUHK_Difficult_words_token_count) / totalWordLength;//GetValidTokenCount(totalWordLength, CharacterFreq_CUHK_Difficult_words_token_count);

    // console.log("CharacterFreq_CNCORPUS_Mean_token_count: " + CharacterFreq_CNCORPUS_Mean_token_count + " CharacterFreq_CNCORPUS_Difficult_words_percentage_token_count: " + CharacterFreq_CNCORPUS_Difficult_words_percentage_token_count + " CharacterFreq_SUBTLEX_CH_Mean_token_count " + CharacterFreq_SUBTLEX_CH_Mean_token_count);
    // console.log("CharacterFreq_SUBTLEX_CH_Difficult_words_percentage_token_count: " + CharacterFreq_SUBTLEX_CH_Difficult_words_percentage_token_count + " CharacterFreq_Sinica_Mean_token_count: " + CharacterFreq_Sinica_Mean_token_count + " CharacterFreq_DaJun_Mean_token_count " + CharacterFreq_DaJun_Mean_token_count);
    // console.log("CharacterFreq_CUHK_Mean_token_count: " + CharacterFreq_CUHK_Mean_token_count + " CharacterFreq_CUHK_Difficult_words_percentage_token_count: " + CharacterFreq_CUHK_Difficult_words_percentage_token_count );

    AOA_Cai_token_count =
      totalAOA_Cai / GetValidTokenCount(totalWordLength, AOA_Cai_invalid_count);
    AOA_Cai2_token_count =
      totalAOA_Cai2 /
      GetValidTokenCount(totalWordLength, AOA_Cai2_invalid_count);
    AOA_Cai3_token_count =
      totalAOA_Cai3 /
      GetValidTokenCount(totalWordLength, AOA_Cai3_invalid_count);
    AOA_Shu_token_count =
      totalAOA_Shu / GetValidTokenCount(totalWordLength, AOA_Shu_invalid_count);
    AOA_Liu_token_count =
      totalAOA_Liu / GetValidTokenCount(totalWordLength, AOA_Liu_invalid_count);

    // console.log(" AOA_Cai_token_count: " + AOA_Cai_token_count + " AOA_Cai2_token_count: " + AOA_Cai2_token_count + " AOA_Cai3_token_count " + AOA_Cai3_token_count + " AOA_Shu_token_count " + AOA_Shu_token_count + " AOA_Liu_token_count " + AOA_Liu_token_count);
    nMeaing_token_count =
      totalnMeaing /
      GetValidTokenCount(totalWordLength, nMeaning_invalid_count);
    RT_character_token_count =
      totalChar_RT / GetValidTokenCount(totalWordLength, RT_invalid_count);
    ERR_character_token_count =
      totalChar_ERR / GetValidTokenCount(totalWordLength, ERR_invalid_count);
    // console.log(" nMeaing_token_count "+nMeaing_token_count+" RT_character_token_count "+RT_character_token_count+" ERR_character_token_count "+ERR_character_token_count);

    // console.log(" SummedFreq_Homophones_CNCORPUS_token_count " + SummedFreq_Homophones_CNCORPUS_token_count+" SummedFreq_Homophones_SUBTLEX_CH_token_count "+SummedFreq_Homophones_SUBTLEX_CH_token_count+" SummedFreq_Homophones_Sinica_token_count "+SummedFreq_Homophones_Sinica_token_count+" SummedFreq_Homophones_DaJun_token_count "+SummedFreq_Homophones_DaJun_token_count+" SummedFreq_Homophones_CUHK_token_count "+SummedFreq_Homophones_CUHK_token_count);

    HomophonesNo_of_a_character_token_count = totalHomophone / GetValidTokenCount(totalWordLength,HomophonesNo_of_a_character_token_count);
    // console.log(" HomophonesNo_of_a_character_token_count " + HomophonesNo_of_a_character_token_count);

    First_appearance_keys_Book_token_count =
      totalKeysBook /
      GetValidTokenCount(
        totalWordLength,
        First_appearance_keys_Book_invalid_count
      );
    First_appearance_keys_Year_token_count =
      totalKeysYear /
      GetValidTokenCount(
        totalWordLength,
        First_appearance_keys_Year_invalid_count
      );
    First_appearance_now_Book_token_count =
      totalNowBook /
      GetValidTokenCount(
        totalWordLength,
        First_appearance_now_Book_invalid_count
      );
    First_appearance_now_Year_token_count =
      totalNowYear /
      GetValidTokenCount(
        totalWordLength,
        First_appearance_now_Year_invalid_count
      );
    First_appearance_ephhk_Book_token_count =
      totalEphhkBook /
      GetValidTokenCount(
        totalWordLength,
        First_appearance_ephhk_Book_invalid_count
      );
    First_appearance_ephhk_Year_token_count =
      totalEphhkYear /
      GetValidTokenCount(
        totalWordLength,
        First_appearance_ephhk_Year_invalid_count
      );

    // console.log(" First_appearance_keys_Book_token_count " + First_appearance_keys_Book_token_count);
    // console.log(" First_appearance_keys_Year_token_count " + First_appearance_keys_Book_token_count);
    // console.log(" First_appearance_now_Book_token_count " + First_appearance_keys_Book_token_count);
    // console.log(" First_appearance_now_Year_token_count " + First_appearance_keys_Book_token_count);
    // console.log(" First_appearance_ephhk_Year_token_count " + First_appearance_keys_Book_token_count);

    total_part_of_speech = RawNo_token_count_word;
      // Adjective_RawNo +
      // Conjunction_RawNo +
      // Postposition_RawNo +
      // Adverb_RawNo +
      // Noun_RawNo +
      // Determiner_RawNo +
      // Particle_RawNo +
      // Preposition_RawNo +
      // Verb_RawNo;
    Content_word_RawNo = Noun_RawNo + Verb_RawNo + Adjective_RawNo;
    Adjective_percentage = (Adjective_RawNo / total_part_of_speech) * 100;
    Conjunction_percentage = (Conjunction_RawNo / total_part_of_speech) * 100;
    Postposition_percentage = (Postposition_RawNo / total_part_of_speech) * 100;
    Adverb_percentage = (Adverb_RawNo / total_part_of_speech) * 100;
    Noun_percentage = (Noun_RawNo / total_part_of_speech) * 100;
    Determiner_percentage = (Determiner_RawNo / total_part_of_speech) * 100;
    Particle_percentage = (Particle_RawNo / total_part_of_speech) * 100;
    Preposition_percentage = (Preposition_RawNo / total_part_of_speech) * 100;
    Verb_percentage = (Verb_RawNo / total_part_of_speech) * 100;
    Content_word_Percentage = (Content_word_RawNo / total_part_of_speech) * 100;

    if (totalNumberOfSpecialSentence > 0) {
      CharacterNo_Sentence = totalWordLength / totalNumberOfSpecialSentence;
      WordNo_Sentence = RawNo_token_count_word / totalNumberOfSpecialSentence;
    }
    // console.log("CharacterNo_Sentence totalWordLength: " + totalWordLength + " totalNumberOfSpecialSentence: " + totalNumberOfSpecialSentence + " CharacterNo_Sentence " + CharacterNo_Sentence);

    // console.log("WordNo_Sentence RawNo_token_count_word: " + RawNo_token_count_word + " totalNumberOfSpecialSentence: " + totalNumberOfSpecialSentence + " WordNo_Sentence " + WordNo_Sentence);
    if (totalNumberOfSpecialClause > 0) {
      CharacterNo_Clause = totalWordLength / totalNumberOfSpecialClause;
      WordNo_Clause = RawNo_token_count_word / totalNumberOfSpecialClause;
    }
    //Type Case
    //init the global count value
    totalWordLength = 0;
    totalWordFreq_CNCORPUS = 0;
    totalWordFreq_SUBTLEX_CH = 0;
    totalWordFreq_Sinica = 0;
    totalWordFreq_Dajun = 0;
    totalWord_RT = 0;
    totalWord_RTSD = 0;
    totalWord_ERR = 0;
    totalStroke = 0;
    totalStroke_less_than_ten = 0;
    totalStroke_ten_to_twenty = 0;
    totalStroke_greater_than_twenty = 0;
    totalCharacterFreq_CNCORPUS = 0;
    totalCharacterFreq_SUBTLEX_CH = 0;
    totalCharacterFreq_Sinica = 0;
    totalCharacterFreq_Dajun = 0;
    totalCharacterFreq_CUHK = 0;
    totalAOA_Cai = 0;
    totalAOA_Cai2 = 0;
    totalAOA_Cai3 = 0;
    totalAOA_Shu = 0;
    totalAOA_Liu = 0;
    totalnMeaing = 0;
    totalChar_RT = 0;
    totalChar_ERR = 0;
    totalHomophone = 0;
    totalKeysBook = 0;
    totalKeysYear = 0;
    totalNowBook = 0;
    totalNowYear = 0;
    totalEphhkBook = 0;
    totalEphhkYear = 0;
    totalNumberOfSpecialSentence = 0;
    totalNumberOfSpecialClause = 0;

    WordFreq_CNCORPUS_invalid_count = 0;
    WordFreq_SUBTLEX_CH_invalid_count = 0;
    WordFreq_Sinica_invalid_count = 0;
    WordFreq_Dajun_invalid_count = 0;
    Word_RT_invalid_count = 0;
    Word_RTSD_invalid_count = 0;
    Word_ERR_invalid_count = 0;
    stroke_invalid_count = 0;

    AOA_Cai_invalid_count = 0;
    AOA_Cai2_invalid_count = 0;
    AOA_Cai3_invalid_count = 0;
    AOA_Shu_invalid_count = 0;
    AOA_Liu_invalid_count = 0;
    nMeaning_invalid_count = 0;
    RT_invalid_count = 0;
    ERR_invalid_count = 0;
    HomophonesNo_of_a_character_invalid_count = 0;

    First_appearance_keys_Book_invalid_count = 0;
    First_appearance_keys_Year_invalid_count = 0;
    First_appearance_now_Book_invalid_count = 0;
    First_appearance_now_Year_invalid_count = 0;
    First_appearance_ephhk_Book_invalid_count = 0;
    First_appearance_ephhk_Year_invalid_count = 0;

    var typeDataList = GetTypeResultList(_resultCsvDataList);
    var typeCharList = GetTypeCharStatus(typeDataList); //check the char should be count or not
    console.log("console:  typeDataList " + typeDataList.length);
    console.log("typeCharList " + JSON.stringify(typeCharList));
    typeDataList.map((item, type_index) => {
      // console.log("item " + JSON.stringify(item));
      // if (index > 0) { //the first row should be header
      var charStatusList = typeCharList[type_index];
      var word = item[1];
      var word_length = Number(item[13]);
      // console.log("word " + word + " Word Length: " + word_length);
      totalWordLength += word_length;
      //Token
      if (word_length == 1) {
        One_character_word_type_account += 1;
        RawNo_OneCharcWord_type_count += 1;
      } else if (word_length == 2) {
        Two_character_word_type_account += 1;
      } else if (word_length == 3) {
        Three_character_type_account += 1;
      } else if (word_length == 4) {
        Four_character_word_type_account += 1;
      } else if (word_length >= 5) {
        Five_and_More_character_word_type_account += 1;
      }
      var WordFreq_CNCORPUS = item[14];
      if (WordFreq_CNCORPUS != "N/A") {
        totalWordFreq_CNCORPUS += Number(WordFreq_CNCORPUS);
      } else {
        if (word_length > 0) {
          WordFreq_CNCORPUS_Difficult_words_type_count += 1;
        }
      }
      // if (WordFreq_CNCORPUS == "N/A") {
      //   WordFreq_CNCORPUS_Difficult_words_type_count += 1;
      // }
      var WordFreq_SUBTLEX_CH = item[15];
      if (WordFreq_SUBTLEX_CH != "N/A") {
        totalWordFreq_SUBTLEX_CH += Number(WordFreq_SUBTLEX_CH);
      } else {
        if (word_length > 0) {
          WordFreq_SUBTLEX_CH_Difficult_words_type_count += 1;
        }
      }
      // if (WordFreq_SUBTLEX_CH == "N/A") {
      //   WordFreq_SUBTLEX_CH_Difficult_words_type_count += 1;
      // }
      var WordFreq_Sinica = item[16];
      if (WordFreq_Sinica != "N/A") {
        totalWordFreq_Sinica += Number(WordFreq_Sinica);
      } else {
        if (word_length > 0) {
          WordFreq_Sinica_Difficult_words_type_count += 1;
        }
      }
      // if (WordFreq_Sinica == "N/A") {
      //   WordFreq_Sinica_Difficult_words_type_count += 1;
      // }
      var WordFreq_Dajun = item[17];
      if (WordFreq_Dajun != "N/A") {
        totalWordFreq_Dajun += Number(WordFreq_Dajun);
      } else {
        if (word_length > 0) {
          WordFreq_Dajun_Difficult_words_type_count += 1;
        }
      }
      // if (WordFreq_Dajun == "N/A") {
      //   WordFreq_Dajun_Difficult_words_type_count += 1;
      // }

      if (word != undefined && word != null && word != "" && word_length > 0) {
        RawNo_type_count_word += 1;
      }

      var Word_RT = item[18];
      if (Word_RT != "N/A") {
        totalWord_RT += Number(Word_RT);
      } else {
        if (word_length > 0) {
          Word_RT_invalid_count += 1;
        }
      }
      // if (Word_RT == "N/A") {
      //   RawNo_low_frequent_words_type_count += 1;
      // }
      var Word_RTSD = item[19];
      if (Word_RTSD != "N/A") {
        totalWord_RTSD += Number(Word_RTSD);
      } else {
        if (word_length > 0) {
          Word_RTSD_invalid_count += 1;
        }
      }
      var Word_ERR = item[20];
      if (Word_ERR != "N/A") {
        // console.log("Word_ERR:  " + Word_ERR);
        totalWord_ERR += Number(Word_ERR);
      } else {
        if (word_length > 0) {
          Word_ERR_invalid_count += 1;
        }
      }

      var strokeIndex = 0;
      for (var i = 21; i < 28; i++) {
        // console.log("charStatusList: " +JSON.stringify(charStatusList)  );

        var status = charStatusList[strokeIndex];
        console.log("status: " + status + " " + strokeIndex);
        var stroke = item[i];
        if (status == "O") {
          if (stroke != "N/A") {
            totalStroke += Number(stroke);
          } else {
            if (word_length > 0) {
              stroke_invalid_count += 1;
            }
          }
          if (typeof stroke == "number") {
            if (stroke < 10) {
              // console.log("stroke is < 10 " + stroke+ typeof(stroke));
              totalStroke_less_than_ten += 1;
            }
            if (stroke >= 10 && stroke <= 20) {
              totalStroke_ten_to_twenty += 1;
            }
            if (stroke > 20) {
              totalStroke_greater_than_twenty += 1;
            }
          }
        }

        strokeIndex++;
      }
      strokeIndex = 0;
      // totalCharacterFreq_CNCORPUS = GetCharCount(item, 30, 37);
      for (var i = 29; i < 36; i++) {
        var data = item[i];
        var status = charStatusList[strokeIndex];
   
        if (status == "O") {
          if (data != "N/A" && word_length > 0) {
            totalCharacterFreq_CNCORPUS += Number(data);
          } else {
            if (word_length > 0) {
              CharacterFreq_CNCORPUS_Difficult_words_type_count += 1;
            }
          }
        }
        // if (data == "N/A") {
        //   CharacterFreq_CNCORPUS_Difficult_words_type_count += 1;
        // }
        strokeIndex++;
      }
      strokeIndex = 0;

      // totalCharacterFreq_SUBTLEX_CH = GetCharCount(item,38,45);
      for (var i = 37; i < 44; i++) {
        var data = item[i];
        var status = charStatusList[strokeIndex];
        var char = item[5 + strokeIndex];
        // console.log("totalCharacterFreq_SUBTLEX_CH Char: " + char + " status " + status+" strokeIndex "+strokeIndex);
        if (status == "O") {
          if (data != "N/A" && word_length > 0) {
            totalCharacterFreq_SUBTLEX_CH += Number(data);
          } else {
            if (word_length > 0) {
              CharacterFreq_SUBTLEX_CH_Difficult_words_type_count += 1;
            }
          }
        }
        strokeIndex++;
      }
      strokeIndex = 0;

      // totalCharacterFreq_Sinica = GetCharCount(item,46,53);
      for (var i = 45; i < 52; i++) {
        var data = item[i];
        var status = charStatusList[strokeIndex];
        if (status == "O") {
          if (data != "N/A" && word_length > 0) {
            totalCharacterFreq_Sinica += Number(data);
          } else {
            if (word_length > 0) {
              CharacterFreq_Sinica_Difficult_words_type_count += 1;
            }
          }
        }
        strokeIndex++
      }
      strokeIndex = 0;

      // totalCharacterFreq_Dajun = GetCharCount(item,54,61);
      for (var i = 53; i < 60; i++) {
        var data = item[i];
        var status = charStatusList[strokeIndex];
        if (status == "O") {
          if (data != "N/A" && word_length > 0) {
            totalCharacterFreq_Dajun += Number(data);
          } else {
            if (word_length > 0) {
              CharacterFreq_DaJun_Difficult_words_type_count += 1;
            }
          }
          // if (data == "N/A") {
          //   CharacterFreq_DaJun_Difficult_words_type_count += 1;
          // }
        }
        strokeIndex++
      }
      strokeIndex = 0;

      // totalCharacterFreq_CUHK = GetCharCount(item,62,69);
      for (var i = 61; i < 68; i++) {
        var data = item[i];
        var status = charStatusList[strokeIndex];
        if (status == "O") {
          if (data != "N/A" && word_length > 0) {
            totalCharacterFreq_CUHK += Number(data);
          } else {
            if (word_length > 0) {
              CharacterFreq_CUHK_Difficult_words_type_count += 1;
            }
          }
        }
        strokeIndex++
      }

      totalAOA_Cai += GetCharCount(item, 69, 76,charStatusList);
      totalAOA_Cai2 += GetCharCount(item, 77, 84,charStatusList);
      totalAOA_Cai3 += GetCharCount(item, 85, 92,charStatusList);
      totalAOA_Shu += GetCharCount(item, 93, 100,charStatusList);
      totalAOA_Liu += GetCharCount(item, 101, 108,charStatusList);
      totalnMeaing += GetCharCount(item, 109, 116,charStatusList);
      totalChar_RT += GetCharCount(item, 117, 124,charStatusList);
      totalChar_ERR += GetCharCount(item, 133, 140,charStatusList);

      AOA_Cai_invalid_count += GetCharInvalidCount(item, 69, 76, word_length,charStatusList);
      AOA_Cai2_invalid_count += GetCharInvalidCount(item, 77, 84, word_length,charStatusList);
      AOA_Cai3_invalid_count += GetCharInvalidCount(item, 85, 92, word_length,charStatusList);
      AOA_Shu_invalid_count += GetCharInvalidCount(item, 93, 100, word_length,charStatusList);
      AOA_Liu_invalid_count += GetCharInvalidCount(item, 101, 108, word_length,charStatusList);
      nMeaning_invalid_count += GetCharInvalidCount(
        item,
        109,
        116,
        word_length,charStatusList
      );
      RT_invalid_count += GetCharInvalidCount(item, 117, 124, word_length,charStatusList);
      ERR_invalid_count += GetCharInvalidCount(item, 133, 140, word_length,charStatusList);
      
      if (typeof item[29] == "number" && word_length == 1) {
        SummedFreq_OneCharcWord_CNCORPUS_type_count += item[29];
      }
      if (typeof item[37] == "number" && word_length == 1) {
        SummedFreq_OneCharcWord_SUBTLEX_CH_type_count += item[37];
      }
      if (typeof item[45] == "number" && word_length == 1) {
        SummedFreq_OneCharcWord_Sinica_type_count += item[45];
      }
      if (typeof item[53] == "number" && word_length == 1) {
        SummedFreq_OneCharcWord_DaJun_type_count += item[53];
      }
      if (typeof item[61] == "number" && word_length == 1) {
        SummedFreq_OneCharcWord_CUHK_type_count += item[61];
      }

      totalHomophone += GetCharCount(item, 150, 157, charStatusList);
      HomophonesNo_of_a_character_invalid_count += GetCharInvalidCount(item, 150, 157, word_length,charStatusList)
      SummedFreq_Homophones_CNCORPUS_type_count += GetCharCount(item, 158, 165,charStatusList);
      SummedFreq_Homophones_SUBTLEX_CH_type_count += GetCharCount(
        item,
        166,
        173,
        charStatusList
      );
      SummedFreq_Homophones_Sinica_type_count += GetCharCount(item, 174, 181,charStatusList);
      SummedFreq_Homophones_DaJun_type_count += GetCharCount(item, 182, 189,charStatusList);
      SummedFreq_Homophones_CUHK_type_count += GetCharCount(item, 190, 197,charStatusList);

      

      totalKeysBook += GetCharCount(item, 198, 205,charStatusList);
      totalKeysYear += GetCharCount(item, 206, 213,charStatusList);
      totalNowBook += GetCharCount(item, 214, 221,charStatusList);
      totalNowYear += GetCharCount(item, 222, 229,charStatusList);
      totalEphhkBook += GetCharCount(item, 230, 237,charStatusList);
      totalEphhkYear += GetCharCount(item, 238, 245,charStatusList);

      First_appearance_keys_Book_invalid_count += GetCharInvalidCount(
        item,
        198,
        205,
        word_length,charStatusList
      );
      First_appearance_keys_Year_invalid_count += GetCharInvalidCount(
        item,
        206,
        213,
        word_length,charStatusList
      );
      First_appearance_now_Book_invalid_count += GetCharInvalidCount(
        item,
        214,
        221,
        word_length,charStatusList
      );
      First_appearance_now_Year_invalid_count += GetCharInvalidCount(
        item,
        222,
        229,
        word_length,charStatusList
      );
      First_appearance_ephhk_Book_invalid_count += GetCharInvalidCount(
        item,
        230,
        237,
        word_length,charStatusList
      );
      First_appearance_ephhk_Year_invalid_count += GetCharInvalidCount(
        item,
        238,
        245,
        word_length,charStatusList
      );

      if (
        word == "。" ||
        word == "！" ||
        word == "——" ||
        word == "……" ||
        word == "？"
      ) {
        totalNumberOfSpecialSentence += 1;
        totalNumberOfSpecialClause += 1;
      }

      if (word == "，" || word == "：" || word == "；") {
        totalNumberOfSpecialClause += 1;
      }

      // }
    });
    console.log("Type word length:   " + totalWordLength);

    RawNo_type_count_character = GetTypeCharCount(_resultCsvDataList);

    WordLength_Mean_type_account = totalWordLength / RawNo_type_count_word;
    console.log("RawNo_type_count_character " + RawNo_type_count_character);
    console.log("WordLength_Mean_type_account " + WordLength_Mean_type_account);

    One_character_word_type_account =
      (100 * One_character_word_type_account) / RawNo_type_count_word;
    Two_character_word_type_account =
      (100 * Two_character_word_type_account) / RawNo_type_count_word;
    Three_character_type_account =
      (100 * Three_character_type_account) / RawNo_type_count_word;
    Four_character_word_type_account =
      (100 * Four_character_word_type_account) / RawNo_type_count_word;
    Five_and_More_character_word_type_account =
      (100 * Five_and_More_character_word_type_account) / RawNo_type_count_word;
    // console.log("One_character_word_type_account "+One_character_word_type_account +" Two_character_word_type_account "+Two_character_word_type_account+" Three_character_type_account "+Three_character_type_account+" Four_character_word_type_account "+Four_character_word_type_account+" Five_and_More_character_word_type_account "+Five_and_More_character_word_type_account);

    WordFreq_CNCORPUS_Mean_type_count =
      totalWordFreq_CNCORPUS /
      GetValidTokenCount(
        RawNo_type_count_word,
        WordFreq_CNCORPUS_Difficult_words_type_count
      );
    WordFreq_CNCORPUS_Difficult_words_percentage_type_count =
      (100 * WordFreq_CNCORPUS_Difficult_words_type_count) /
      RawNo_type_count_word;
    console.log("RawNo_type_count_word " + RawNo_type_count_word);

    WordFreq_SUBTLEX_CH_Mean_type_count =
      totalWordFreq_SUBTLEX_CH /
      GetValidTokenCount(
        RawNo_type_count_word,
        WordFreq_SUBTLEX_CH_Difficult_words_type_count
      );
    WordFreq_SUBTLEX_CH_Difficult_words_percentage_type_count =
      (100 * WordFreq_SUBTLEX_CH_Difficult_words_type_count) /
      RawNo_type_count_word;
    WordFreq_Sinica_Mean_type_count =
      totalWordFreq_Sinica /
      GetValidTokenCount(
        RawNo_type_count_word,
        WordFreq_Sinica_Difficult_words_type_count
      );
    WordFreq_Sinica_Difficult_words_percentage_type_count =
      (100 * WordFreq_Sinica_Difficult_words_type_count) /
      RawNo_type_count_word;
    WordFreq_Dajun_Mean_type_count =
      totalWordFreq_Dajun /
      GetValidTokenCount(
        RawNo_type_count_word,
        WordFreq_Dajun_Difficult_words_type_count
      );
    WordFreq_Dajun_Difficult_words_percentage_type_count =
      (100 * WordFreq_Dajun_Difficult_words_type_count) / RawNo_type_count_word;
    // console.log(
    //   "WordFreq_CNCORPUS_Difficult_words_percentage_type_count " +
    //     WordFreq_CNCORPUS_Difficult_words_percentage_type_count +
    //     " WordFreq_SUBTLEX_CH_Difficult_words_percentage_type_count " +
    //     WordFreq_SUBTLEX_CH_Difficult_words_percentage_type_count +
    //     " WordFreq_Sinica_Difficult_words_percentage_type_count " +
    //     WordFreq_Sinica_Difficult_words_percentage_type_count +
    //     " WordFreq_Dajun_Difficult_words_percentage_type_count " +
    //     WordFreq_Dajun_Difficult_words_percentage_type_count
    // );
    // console.log(
    //   "WordFreq_CNCORPUS_Difficult_words_type_count " +
    //     WordFreq_CNCORPUS_Difficult_words_type_count +
    //     " WordFreq_SUBTLEX_CH_Difficult_words_type_count " +
    //     WordFreq_SUBTLEX_CH_Difficult_words_type_count +
    //     " WordFreq_Sinica_Difficult_words_type_count " +
    //     WordFreq_Sinica_Difficult_words_type_count +
    //     " WordFreq_Dajun_Difficult_words_type_count " +
    //     WordFreq_Dajun_Difficult_words_type_count
    // );
    Ratio_type_token_word = RawNo_type_count_word / RawNo_token_count_word;
    Ratio_type_token_character =
      RawNo_type_count_character / RawNo_token_count_character;
    // console.log("Ratio_type_token_word "+Ratio_type_token_word+" Ratio_type_token_character " + Ratio_type_token_character);
    Once_word =
      (100 * GetOnceWord(_resultCsvDataList)) / RawNo_token_count_word;

    Once_character =
      (100 * GetOnceChar(_resultCsvDataList)) / RawNo_token_count_character;

    Word_RT_type_count_Mean =
      totalWord_RT /
      GetValidTokenCount(RawNo_type_count_word, Word_RT_invalid_count);
    Word_RTSD_type_count_Mean =
      totalWord_RTSD /
      GetValidTokenCount(RawNo_type_count_word, Word_RTSD_invalid_count);
    Word_ERR_type_count_Mean =
      totalWord_ERR /
      GetValidTokenCount(RawNo_type_count_word, Word_ERR_invalid_count);
    RawNo_low_frequent_words_type_count = Word_RT_invalid_count;
    Proportion_low_frequent_words_type_count =
      RawNo_low_frequent_words_type_count / RawNo_type_count_word;

    // console.log(
    //   "Word_RT_type_count_Mean " +
    //     Word_RT_type_count_Mean +
    //     " Word_RTSD_type_count_Mean " +
    //     Word_RTSD_type_count_Mean +
    //     " Word_ERR_type_count_Mean " +
    //     Word_ERR_type_count_Mean +
    //     " Proportion_low_frequent_words_type_count " +
    //     Proportion_low_frequent_words_type_count
    // );

    Stroke_Mean_type_count =
      totalStroke / GetValidTokenCount(RawNo_type_count_character, stroke_invalid_count);
    less_than_10_percentage_type_count =
      (100 * totalStroke_less_than_ten) / RawNo_type_count_character;//GetValidTokenCount(RawNo_type_count_character, stroke_invalid_count);
   
    more_than_20_percentage_type_count =
      (100 * totalStroke_greater_than_twenty) / RawNo_type_count_character;//GetValidTokenCount(RawNo_type_count_character, stroke_invalid_count);
    
    between_10_and_20_percentage_type_count = (100 * totalStroke_ten_to_twenty) / RawNo_type_count_character;//100 - less_than_10_percentage_type_count - more_than_20_percentage_type_count;
        //(100 * totalStroke_ten_to_twenty) / totalWordLength;
    
    // console.log(
    //   "Stroke_Mean_type_count " +
    //     Stroke_Mean_type_count +
    //     " less_than_10_percentage_type_count " +
    //     less_than_10_percentage_type_count +
    //     " between_10_and_20_percentage_type_count " +
    //     between_10_and_20_percentage_type_count +
    //     " more_than_20_percentage_type_count " +
    //     more_than_20_percentage_type_count
    // );

    CharacterFreq_CNCORPUS_Mean_type_count =
      totalCharacterFreq_CNCORPUS / GetValidTokenCount(RawNo_type_count_character, CharacterFreq_CNCORPUS_Difficult_words_type_count);
      console.log("Test CharacterFreq_CNCORPUS_Mean_type_count "+totalCharacterFreq_CNCORPUS +" GetValidTokenCount "+GetValidTokenCount(RawNo_type_count_character, CharacterFreq_CNCORPUS_Difficult_words_type_count)+" RawNo_type_count_character "+RawNo_type_count_character+" CharacterFreq_CNCORPUS_Difficult_words_type_count "+CharacterFreq_CNCORPUS_Difficult_words_type_count )
    CharacterFreq_CNCORPUS_Difficult_words_percentage_type_count =
      (100 * CharacterFreq_CNCORPUS_Difficult_words_type_count) / RawNo_type_count_character;
      // GetValidTokenCount(RawNo_type_count_character, CharacterFreq_CNCORPUS_Difficult_words_type_count);
    CharacterFreq_SUBTLEX_CH_Mean_type_count =
      totalCharacterFreq_SUBTLEX_CH / GetValidTokenCount(RawNo_type_count_character, CharacterFreq_SUBTLEX_CH_Difficult_words_type_count);
    
    CharacterFreq_SUBTLEX_CH_Difficult_words_percentage_type_count =
      (100 * CharacterFreq_SUBTLEX_CH_Difficult_words_type_count) / RawNo_type_count_character;
      // GetValidTokenCount(RawNo_type_count_character, CharacterFreq_SUBTLEX_CH_Difficult_words_type_count);
    
    CharacterFreq_Sinica_Mean_type_count =
      totalCharacterFreq_Sinica / GetValidTokenCount(RawNo_type_count_character, CharacterFreq_Sinica_Difficult_words_type_count);
    CharacterFreq_Sinica_Difficult_words_percentage_type_count =
      (100 * CharacterFreq_Sinica_Difficult_words_type_count) / RawNo_type_count_character//GetValidTokenCount(RawNo_type_count_character, CharacterFreq_Sinica_Difficult_words_type_count);
    
    CharacterFreq_DaJun_Mean_type_count =
      totalCharacterFreq_Dajun / GetValidTokenCount(RawNo_type_count_character, CharacterFreq_DaJun_Difficult_words_type_count);
    CharacterFreq_DaJun_Difficult_words_percentage_type_count =
      (100 * CharacterFreq_DaJun_Difficult_words_type_count) / RawNo_type_count_character//GetValidTokenCount(RawNo_type_count_character, CharacterFreq_DaJun_Difficult_words_type_count);
    
    CharacterFreq_CUHK_Mean_type_count =
      totalCharacterFreq_CUHK / GetValidTokenCount(RawNo_type_count_character, CharacterFreq_CUHK_Difficult_words_type_count);
    CharacterFreq_CUHK_Difficult_words_percentage_type_count =
      (100 * CharacterFreq_CUHK_Difficult_words_type_count) / RawNo_type_count_character//GetValidTokenCount(RawNo_type_count_character, CharacterFreq_CUHK_Difficult_words_type_count);

    // console.log(
    //   "CharacterFreq_CNCORPUS_Mean_type_count " +
    //     CharacterFreq_CNCORPUS_Mean_type_count +
    //     " CharacterFreq_CNCORPUS_Difficult_words_percentage_type_count " +
    //     CharacterFreq_CNCORPUS_Difficult_words_percentage_type_count +
    //     " CharacterFreq_SUBTLEX_CH_Mean_type_count " +
    //     CharacterFreq_SUBTLEX_CH_Mean_type_count +
    //     " CharacterFreq_SUBTLEX_CH_Difficult_words_percentage_type_count " +
    //     CharacterFreq_SUBTLEX_CH_Difficult_words_percentage_type_count +
    //     " CharacterFreq_Sinica_Mean_type_count " +
    //     CharacterFreq_Sinica_Mean_type_count +
    //     " CharacterFreq_Sinica_Difficult_words_percentage_type_count " +
    //     CharacterFreq_Sinica_Difficult_words_percentage_type_count +
    //     " CharacterFreq_DaJun_Mean_type_count " +
    //     CharacterFreq_DaJun_Mean_type_count +
    //     " CharacterFreq_DaJun_Difficult_words_percentage_type_count " +
    //     CharacterFreq_DaJun_Difficult_words_percentage_type_count +
    //     " CharacterFreq_CUHK_Mean_type_count " +
    //     CharacterFreq_CUHK_Mean_type_count +
    //     " CharacterFreq_CUHK_Difficult_words_percentage_type_count " +
    //     CharacterFreq_CUHK_Difficult_words_percentage_type_count
    // );

    AOA_Cai_type_count =
      totalAOA_Cai / GetValidTokenCount(RawNo_type_count_character, AOA_Cai_invalid_count);
    AOA_Cai2_type_count =
      totalAOA_Cai2 /
      GetValidTokenCount(RawNo_type_count_character, AOA_Cai2_invalid_count);
    AOA_Cai3_type_count =
      totalAOA_Cai3 /
      GetValidTokenCount(RawNo_type_count_character, AOA_Cai3_invalid_count);
    AOA_Shu_type_count =
      totalAOA_Shu / GetValidTokenCount(RawNo_type_count_character, AOA_Shu_invalid_count);
    AOA_Liu_type_count =
      totalAOA_Liu / GetValidTokenCount(RawNo_type_count_character, AOA_Liu_invalid_count);
    nMeaing_type_count =
      totalnMeaing /
      GetValidTokenCount(RawNo_type_count_character, nMeaning_invalid_count);

    RT_character_type_count =
      totalChar_RT / GetValidTokenCount(RawNo_type_count_character, RT_invalid_count);
    ERR_character_type_count =
      totalChar_ERR / GetValidTokenCount(RawNo_type_count_character, ERR_invalid_count);
    // console.log(" AOA_Cai_type_count: " + AOA_Cai_type_count + " AOA_Cai2_type_count: " + AOA_Cai2_type_count + " AOA_Cai3_type_count " + AOA_Cai3_type_count + " AOA_Shu_type_count " + AOA_Shu_type_count + " AOA_Liu_type_count " + AOA_Liu_type_count);
    // console.log(" nMeaing_type_count: " + nMeaing_type_count + " RT_character_type_count: " + RT_character_type_count + " ERR_character_type_count " + ERR_character_type_count);

    HomophonesNo_of_a_character_type_count = totalHomophone / GetValidTokenCount(RawNo_type_count_character, HomophonesNo_of_a_character_invalid_count);
    // console.log(" HomophonesNo_of_a_character_type_count " + HomophonesNo_of_a_character_type_count);
    // console.log(" First_appearance_keys_Book_type_count totalKeysBook " + totalKeysBook +" " + RawNo_type_count_character+" "+First_appearance_keys_Book_invalid_count);
      
    First_appearance_keys_Book_type_count =
      totalKeysBook /
      GetValidTokenCount(
        RawNo_type_count_character,
        First_appearance_keys_Book_invalid_count
      );
    First_appearance_keys_Year_type_count =
      totalKeysYear /
      GetValidTokenCount(
        RawNo_type_count_character,
        First_appearance_keys_Year_invalid_count
      );
    First_appearance_now_Book_type_count =
      totalNowBook /
      GetValidTokenCount(
        RawNo_type_count_character,
        First_appearance_now_Book_invalid_count
      );
    First_appearance_now_Year_type_count =
      totalNowYear /
      GetValidTokenCount(
        RawNo_type_count_character,
        First_appearance_now_Year_invalid_count
      );
    First_appearance_ephhk_Book_type_count =
      totalEphhkBook /
      GetValidTokenCount(
        RawNo_type_count_character,
        First_appearance_ephhk_Book_invalid_count
      );
    First_appearance_ephhk_Year_type_count =
      totalEphhkYear /
      GetValidTokenCount(
        RawNo_type_count_character,
        First_appearance_ephhk_Year_invalid_count
      );

    // console.log(
    //   " SummedFreq_OneCharcWord_CNCORPUS_type_count " +
    //     SummedFreq_OneCharcWord_CNCORPUS_type_count +
    //     " SummedFreq_OneCharcWord_SUBTLEX_CH_type_count " +
    //     SummedFreq_OneCharcWord_SUBTLEX_CH_type_count +
    //     " SummedFreq_OneCharcWord_Sinica_type_count " +
    //     SummedFreq_OneCharcWord_Sinica_type_count +
    //     " SummedFreq_OneCharcWord_DaJun_type_count " +
    //     SummedFreq_OneCharcWord_DaJun_type_count +
    //     " SummedFreq_OneCharcWord_CUHK_type_count " +
    //     SummedFreq_OneCharcWord_CUHK_type_count
    // );
    // console.log(" SummedFreq_Homophones_CNCORPUS_type_count " + SummedFreq_Homophones_CNCORPUS_type_count + " SummedFreq_Homophones_SUBTLEX_CH_type_count " + SummedFreq_Homophones_SUBTLEX_CH_type_count + " SummedFreq_Homophones_Sinica_type_count " + SummedFreq_Homophones_Sinica_type_count + " SummedFreq_Homophones_DaJun_type_count " + SummedFreq_Homophones_DaJun_type_count + " SummedFreq_Homophones_CUHK_type_count " + SummedFreq_Homophones_CUHK_type_count);
    // CharacterNo_Sentence = totalWordLength / totalNumberOfSpecialSentence;
    // WordNo_Sentence = RawNo_type_count_word / totalNumberOfSpecialSentence;
    // CharacterNo_Clause = totalWordLength / totalNumberOfSpecialClause;
    // WordNo_Clause = RawNo_type_count_word / totalNumberOfSpecialClause;

    csvData.push([
      WordLength_Mean_token_account,
      One_character_word_token_account,
      Two_character_word_token_account,
      Three_character_token_account,
      Four_character_word_token_account,
      Five_and_More_character_word_token_account,
      WordLength_Mean_type_account,
      One_character_word_type_account,
      Two_character_word_type_account,
      Three_character_type_account,
      Four_character_word_type_account,
      Five_and_More_character_word_type_account,
      WordFreq_CNCORPUS_Mean_token_count,
      WordFreq_CNCORPUS_Difficult_words_token_count,
      WordFreq_CNCORPUS_Difficult_words_percentage_token_count,
      WordFreq_CNCORPUS_Mean_type_count,
      WordFreq_CNCORPUS_Difficult_words_type_count,
      WordFreq_CNCORPUS_Difficult_words_percentage_type_count,
      WordFreq_SUBTLEX_CH_Mean_token_count,
      WordFreq_SUBTLEX_CH_Difficult_words_token_count,
      WordFreq_SUBTLEX_CH_Difficult_words_percentage_token_count,
      WordFreq_SUBTLEX_CH_Mean_type_count,
      WordFreq_SUBTLEX_CH_Difficult_words_type_count,
      WordFreq_SUBTLEX_CH_Difficult_words_percentage_type_count,
      WordFreq_Sinica_Mean_token_count,
      WordFreq_Sinica_Difficult_words_token_count,
      WordFreq_Sinica_Difficult_words_percentage_token_count,
      WordFreq_Sinica_Mean_type_count,
      WordFreq_Sinica_Difficult_words_type_count,
      WordFreq_Sinica_Difficult_words_percentage_type_count,
      WordFreq_Dajun_Mean_token_count,
      WordFreq_Dajun_Difficult_words_token_count,
      WordFreq_Dajun_Difficult_words_percentage_token_count,
      WordFreq_Dajun_Mean_type_count,
      WordFreq_Dajun_Difficult_words_type_count,
      WordFreq_Dajun_Difficult_words_percentage_type_count,
      RawNo_token_count_word,
      RawNo_type_count_word,
      Ratio_type_token_word,
      Once_word,
      RawNo_token_count_character,
      RawNo_type_count_character,
      Ratio_type_token_character,
      Once_character,
      Word_RT_token_count_Mean,
      Word_RTSD_token_count_Mean,
      Word_ERR_token_count_Mean,
      Word_RT_type_count_Mean,
      Word_RTSD_type_count_Mean,
      Word_ERR_type_count_Mean,
      RawNo_low_frequent_words_token_count,
      Proportion_low_frequent_words_token_count,
      RawNo_low_frequent_words_type_count,
      Proportion_low_frequent_words_type_count,
      Stroke_Mean_token_count,
      less_than_10_percentage_token_count,
      between_10_and_20_percentage_token_count,
      more_than_20_percentage_token_count,
      Stroke_Mean_type_count,
      less_than_10_percentage_type_count,
      between_10_and_20_percentage_type_count,
      more_than_20_percentage_type_count,
      CharacterFreq_CNCORPUS_Mean_token_count,
      CharacterFreq_CNCORPUS_Difficult_words_token_count,
      CharacterFreq_CNCORPUS_Difficult_words_percentage_token_count,
      CharacterFreq_CNCORPUS_Mean_type_count,
      CharacterFreq_CNCORPUS_Difficult_words_type_count,
      CharacterFreq_CNCORPUS_Difficult_words_percentage_type_count,
      CharacterFreq_SUBTLEX_CH_Mean_token_count,
      CharacterFreq_SUBTLEX_CH_Difficult_words_token_count,
      CharacterFreq_SUBTLEX_CH_Difficult_words_percentage_token_count,
      CharacterFreq_SUBTLEX_CH_Mean_type_count,
      CharacterFreq_SUBTLEX_CH_Difficult_words_type_count,
      CharacterFreq_SUBTLEX_CH_Difficult_words_percentage_type_count,
      CharacterFreq_Sinica_Mean_token_count,
      CharacterFreq_Sinica_Difficult_words_token_count,
      CharacterFreq_Sinica_Difficult_words_percentage_token_count,
      CharacterFreq_Sinica_Mean_type_count,
      CharacterFreq_Sinica_Difficult_words_type_count,
      CharacterFreq_Sinica_Difficult_words_percentage_type_count,
      CharacterFreq_DaJun_Mean_token_count,
      CharacterFreq_DaJun_Difficult_words_token_count,
      CharacterFreq_DaJun_Difficult_words_percentage_token_count,
      CharacterFreq_DaJun_Mean_type_count,
      CharacterFreq_DaJun_Difficult_words_type_count,
      CharacterFreq_DaJun_Difficult_words_percentage_type_count,
      CharacterFreq_CUHK_Mean_token_count,
      CharacterFreq_CUHK_Difficult_words_token_count,
      CharacterFreq_CUHK_Difficult_words_percentage_token_count,
      CharacterFreq_CUHK_Mean_type_count,
      CharacterFreq_CUHK_Difficult_words_type_count,
      CharacterFreq_CUHK_Difficult_words_percentage_type_count,
      AOA_Cai_token_count,
      AOA_Cai2_token_count,
      AOA_Cai3_token_count,
      AOA_Shu_token_count,
      AOA_Liu_token_count,
      AOA_Cai_type_count,
      AOA_Cai2_type_count,
      AOA_Cai3_type_count,
      AOA_Shu_type_count,
      AOA_Liu_type_count,
      nMeaing_token_count,
      nMeaing_type_count,
      RawNo_OneCharcWord_token_count,
      RawNo_OneCharcWord_type_count,
      SummedFreq_OneCharcWord_CNCORPUS_token_count,
      SummedFreq_OneCharcWord_CNCORPUS_type_count,
      SummedFreq_OneCharcWord_SUBTLEX_CH_token_count,
      SummedFreq_OneCharcWord_SUBTLEX_CH_type_count,
      SummedFreq_OneCharcWord_Sinica_token_count,
      SummedFreq_OneCharcWord_Sinica_type_count,
      SummedFreq_OneCharcWord_DaJun_token_count,
      SummedFreq_OneCharcWord_DaJun_type_count,
      SummedFreq_OneCharcWord_CUHK_token_count,
      SummedFreq_OneCharcWord_CUHK_type_count,
      RT_character_token_count,
      RT_character_type_count,
      ERR_character_token_count,
      ERR_character_type_count,
      HomophonesNo_of_a_character_token_count,
      HomophonesNo_of_a_character_type_count,
      SummedFreq_Homophones_CNCORPUS_token_count,
      SummedFreq_Homophones_CNCORPUS_type_count,
      SummedFreq_Homophones_SUBTLEX_CH_token_count,
      SummedFreq_Homophones_SUBTLEX_CH_type_count,
      SummedFreq_Homophones_Sinica_token_count,
      SummedFreq_Homophones_Sinica_type_count,
      SummedFreq_Homophones_DaJun_token_count,
      SummedFreq_Homophones_DaJun_type_count,
      SummedFreq_Homophones_CUHK_token_count,
      SummedFreq_Homophones_CUHK_type_count,
      First_appearance_keys_Book_token_count,
      First_appearance_keys_Year_token_count,
      First_appearance_now_Book_token_count,
      First_appearance_now_Year_token_count,
      First_appearance_ephhk_Book_token_count,
      First_appearance_ephhk_Year_token_count,
      First_appearance_keys_Book_type_count,
      First_appearance_keys_Year_type_count,
      First_appearance_now_Book_type_count,
      First_appearance_now_Year_type_count,
      First_appearance_ephhk_Book_type_count,
      First_appearance_ephhk_Year_type_count,
      CharacterNo_Sentence,
      WordNo_Sentence,
      CharacterNo_Clause,
      WordNo_Clause,
      Adjective_RawNo,
      Conjunction_RawNo,
      Postposition_RawNo,
      Adverb_RawNo,
      Noun_RawNo,
      Determiner_RawNo,
      Particle_RawNo,
      Preposition_RawNo,
      Verb_RawNo,
      Content_word_RawNo,
      Adjective_percentage,
      Conjunction_percentage,
      Postposition_percentage,
      Adverb_percentage,
      Noun_percentage,
      Determiner_percentage,
      Particle_percentage,
      Preposition_percentage,
      Verb_percentage,
      Content_word_Percentage,
      precedent_RawNO,
      cause_RawNO,
      adversative_RawNO,
      coordination_RawNO,
      additive_RawNO,
      successor_RawNO,
      inference_RawNO,
      condition_RawNO,
      supposition_RawNO,
      concession_RawNO,
      purpose_RawNO,
      frequency_RawNO,
      parenthesis_RawNO,
      abandonment_RawNO,
      result_RawNO,
      comparative_RawNO,
      preference_RawNO,
      summary_RawNO,
      recount_RawNO,
      temporal_RawNO,
    ]);

    // console.log("Count Result test:  " + JSON.stringify(csvData));
    var csvHeader = [
      "WordLength_Mean_token count",
      "One-character word (%)_token count",
      "Two-character word (%)_token count",
      "Three-character (%)_token count",
      "Four-character word (%)_token count",
      "Five- and More-character word (%)_token count",
      "WordLength_Mean_type count",
      "One-character word (%)_type count",
      "Two-character word (%)_type count",
      "Three-character (%)_type count",
      "Four-character word (%)_type count",
      "Five- and More-character word (%)_type count",
      "WordFreq_CNCORPUS_Mean_token count",
      "WordFreq_CNCORPUS_Difficult words_token count",
      "WordFreq_CNCORPUS_Difficult words (%)_token count",
      "WordFreq_CNCORPUS_Mean_type count",
      "WordFreq_CNCORPUS_Difficult words_type count",
      "WordFreq_CNCORPUS_Difficult words (%)_type count",
      "WordFreq_SUBTLEX-CH_Mean_token count",
      "WordFreq_SUBTLEX-CH_Difficult words_token count",
      "WordFreq_SUBTLEX-CH_Difficult words (%)_token count",
      "WordFreq_SUBTLEX-CH_Mean_type count",
      "WordFreq_SUBTLEX-CH_Difficult words_type count",
      "WordFreq_SUBTLEX-CH_Difficult words (%)_type count",
      "WordFreq_Sinica_Mean_token count",
      "WordFreq_Sinica_Difficult words_token count",
      "WordFreq_Sinica_Difficult words (%)_token count",
      "WordFreq_Sinica_Mean_type count",
      "WordFreq_Sinica_Difficult words_type count",
      "WordFreq_Sinica_Difficult words (%)_type count",
      "WordFreq_Dajun_Mean_token count",
      "WordFreq_Dajun_Difficult words_token count",
      "WordFreq_Dajun_Difficult words (%)_token count",
      "WordFreq_Dajun_Mean_type count",
      "WordFreq_Dajun_Difficult words_type count",
      "WordFreq_Dajun_Difficult words (%)_type count",
      "RawNo_token count_word",
      "RawNo_type count_word",
      "Ratio_type_token_word",
      "Once word (%)",
      "RawNo_token count_character",
      "RawNo_type count_character",
      "Ratio_type_token_character",
      "Once character (%)",
      "Word_RT_token count_Mean",
      "Word_RTSD_token count_Mean",
      "Word_ERR_token count_Mean",
      "Word_RT_type count_Mean",
      "Word_RTSD_type count_Mean",
      "Word_ERR_type count_Mean",
      "RawNo_low-frequent words_token count",
      "Proportion_low-frequent words_token count",
      "RawNo_low-frequent words_type count",
      "Proportion_low-frequent words_type count",
      "Stroke_Mean_token count",
      "less than 10 (%)_token count",
      "between 10 and 20 (%)_token count",
      "more than 20 (%)_token count",
      "Stroke_Mean_type count",
      "less than 10 (%)_type count",
      "between 10 and 20 (%)_type count",
      "more than 20 (%)_type count",
      "CharacterFreq_CNCORPUS_Mean_token count",
      "CharacterFreq_CNCORPUS_Difficult words_token count",
      "CharacterFreq_CNCORPUS_Difficult words (%)_token count",
      "CharacterFreq_CNCORPUS_Mean_type count",
      "CharacterFreq_CNCORPUS_Difficult words_type count",
      "CharacterFreq_CNCORPUS_Difficult words (%)_type count",
      "CharacterFreq_SUBTLEX-CH_Mean_token count",
      "CharacterFreq_SUBTLEX-CH_Difficult words_token count",
      "CharacterFreq_SUBTLEX-CH_Difficult words (%)_token count",
      "CharacterFreq_SUBTLEX-CH_Mean_type count",
      "CharacterFreq_SUBTLEX-CH_Difficult words_type count",
      "CharacterFreq_SUBTLEX-CH_Difficult words (%)_type count",
      "CharacterFreq_Sinica_Mean_token count",
      "CharacterFreq_Sinica_Difficult words_token count",
      "CharacterFreq_Sinica_Difficult words (%)_token count",
      "CharacterFreq_Sinica_Mean_type count",
      "CharacterFreq_Sinica_Difficult words_type count",
      "CharacterFreq_Sinica_Difficult words (%)_type count",
      "CharacterFreq_DaJun_Mean_token count",
      "CharacterFreq_DaJun_Difficult words_token count",
      "CharacterFreq_DaJun_Difficult words (%)_token count",
      "CharacterFreq_DaJun_Mean_type count",
      "CharacterFreq_DaJun_Difficult words_type count",
      "CharacterFreq_DaJun_Difficult words (%)_type count",
      "CharacterFreq_CUHK_Mean_token count",
      "CharacterFreq_CUHK_Difficult words_token count",
      "CharacterFreq_CUHK_Difficult words (%)_token count",
      "CharacterFreq_CUHK_Mean_type count",
      "CharacterFreq_CUHK_Difficult words_type count",
      "CharacterFreq_CUHK_Difficult words (%)_type count",
      "AOA_Cai_token count",
      "AOA_Cai2_token count",
      "AOA_Cai3_token count",
      "AOA_Shu_token count",
      "AOA_Liu_token count",
      "AOA_Cai_type count",
      "AOA_Cai2_type count",
      "AOA_Cai3_type count",
      "AOA_Shu_type count",
      "AOA_Liu_type count",
      "nMeaing_token count",
      "nMeaing_type count",
      "RawNo_OneCharcWord_token count",
      "RawNo_OneCharcWord_type count",
      "SummedFreq_OneCharcWord_CNCORPUS_token count",
      "SummedFreq_OneCharcWord_CNCORPUS_type count",
      "SummedFreq_OneCharcWord_SUBTLEX-CH_token count",
      "SummedFreq_OneCharcWord_SUBTLEX-CH_type count",
      "SummedFreq_OneCharcWord_Sinica_token count",
      "SummedFreq_OneCharcWord_Sinica_type count",
      "SummedFreq_OneCharcWord_DaJun_token count",
      "SummedFreq_OneCharcWord_DaJun_type count",
      "SummedFreq_OneCharcWord_CUHK_token count",
      "SummedFreq_OneCharcWord_CUHK_type count",
      "RT_character_token count",
      "RT_character_type count",
      "ERR_character_token count",
      "ERR_characte_type count",
      "HomophonesNo of a character_token count",
      "HomophonesNo of a character_type count",
      "SummedFreq_Homophones_CNCORPUS_token count",
      "SummedFreq_Homophones_CNCORPUS_type count",
      "SummedFreq_Homophones_SUBTLEX-CH_token count",
      "SummedFreq_Homophones_SUBTLEX-CH_type count",
      "SummedFreq_Homophones_Sinica_token count",
      "SummedFreq_Homophones_Sinica_type count",
      "SummedFreq_Homophones_DaJun_token count",
      "SummedFreq_Homophones_DaJun_type count",
      "SummedFreq_Homophones_CUHK_token count",
      "SummedFreq_Homophones_CUHK_type count",
      "First appearance_啟思_Book_token count",
      "First appearance_啟思_Year_token count",
      "First appearance_現代_Book_token count",
      "First appearance_現代_Year_token count",
      "First appearance_我愛學語文_Book_token count",
      "First appearance_我愛學語文_Year_token count",
      "First appearance_啟思_Book_type count",
      "First appearance_啟思_Year_type count",
      "First appearance_現代_Book_type count",
      "First appearance_現代_Year_type count",
      "First appearance_我愛學語文_Book_type count",
      "First appearance_我愛學語文_Year_type count",
      "CharacterNo_Sentence",
      "WordNo_Sentence",
      "CharacterNo_Clause",
      "WordNo_Clause",
      "Adjective_RawNo",
      "Conjunction_RawNo",
      "Postposition_RawNo",
      "Adverb_RawNo",
      "Noun_RawNo",
      "Determiner_RawNo",
      "Particle_RawNo",
      "Preposition_RawNo",
      "Verb_RawNo",
      "Content word_RawNo",
      "Adjective(%)",
      "Conjunction(%)",
      "Postposition(%)",
      "Adverb(%)",
      "Noun(%)",
      "Determiner(%)",
      "Particle(%)",
      "Preposition(%)",
      "Verb(%)",
      "Content word_Percentage",
      "precedent_RawNO",
      "cause_RawNO",
      "adversative_RawNO",
      "coordination_RawNO",
      "additive_RawNO",
      "successor_RawNO",
      "inference _RawNO",
      "condition_RawNO",
      "supposition_RawNO",
      "concession_RawNO",
      "purpose_RawNO",
      "frequency_RawNO",
      "parenthesis_RawNO",
      "abandonment_RawNO",
      "result_RawNO",
      "comparative_RawNO",
      "preference_RawNO",
      "summary_RawNO",
      "recount_RawNO",
      "temporal_RawNO",
    ];
    csvData.unshift(csvHeader);

    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: false,
      showTitle: false,
      title: "",
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      filename: "切詞統計結果",
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(csvData);
  }

  function GetTypeCharStatus(_resultCsvDataList) {
    var strList = [];
    var strDisplayList = [];
    var allCharList = [];
    var allCharListWithoutDup = [];
    console.log("GetTypeCharStatus  Called ");
    _resultCsvDataList.map((data, index) => {
      // if (index > 0) {
      var wordLength = data[13];
      // if (wordLength > 0) {
      var statusList = [];
      var displayList = [];
      for (var i = 5; i < 13; i++) {
        var char = data[i];
        console.log("GetTypeCharStatus char: " + JSON.stringify(char));

        var isExist = allCharList.includes(char);
        if (!isExist && char != "") {
          statusList.push("O");
          allCharListWithoutDup.push(char);
        } else {
          statusList.push("X");
        }
        displayList.push(char);
        allCharList.push(char);
      }
      strList.push(statusList);
      strDisplayList.push(displayList);
      // }
      // }
    });
    console.log(
      "GetTypeCharStatus allCharList: " + JSON.stringify(allCharListWithoutDup)
    );
    return strList;
  }

  function GetTypeCharCount(_resultCsvDataList) {
    var strList = [];
    _resultCsvDataList.map((data, index) => {
      if (index > 0) {
        var wordLength = data[13];
        if (wordLength > 0) {
          for (var i = 5; i < 13; i++) {
            var char = data[i];
            if (char != "") {
              strList.push(char);
            }
          }
        }
      }
    });
    // console.log("GetTypeCharCount strList: " + JSON.stringify(strList));
    const uniqueList = [...new Set(strList)];
    // console.log("GetTypeCharCount uniqueList Length: " + uniqueList.length);
    return uniqueList.length;
  }

  function GetValidTokenCount(total, invalidCount) {
    return total - invalidCount;
  }

  function GetTypeResultList(_resultCsvDataList) {
    //Pick the word

    var typeList = [];
    _resultCsvDataList.map((data, index) => {
      if (index > 0) {
        var word = data[1];
        var isExist = typeList.find((t) => t[1] == word);

        // console.log("GetTypeResultList isExist: " + isExist);

        if (!isExist) {
          //put the data to list
          typeList.push(data);
        }
      }
    });

    return typeList;
  }

  function GetOnceWord(_resultCsvDataList) {
    var onceWord = 0;
    _resultCsvDataList.map((data, index) => {
      if (index > 0) {
        var word = data[1];
        var appearTimes = _resultCsvDataList.filter((t) => t[1] == word);
        if (
          appearTimes.length == 1 &&
          !checkSpecialCharacters(word) &&
          !checkSentence(word) &&
          word != "\n"
        ) {
          onceWord += 1;
        }
      }
    });

    return onceWord;
  }

  function GetOnceChar(_resultCsvDataList) {
    var charList = []; //All word
    var onceChar = 0;
    _resultCsvDataList.map((data, index) => {
      if (index > 0) {
        var word = data[1];
        const sp_word = word.split("");
        sp_word.map((char, index) => {
          charList.push(char);
        });
      }
    });
    _resultCsvDataList.map((data, index) => {
      if (index > 0) {
        var word = data[1];
        const sp_word = word.split("");
        sp_word.map((char, index) => {
          var appearTimes = charList.filter((t) => t == char);
          if (
            appearTimes.length == 1 &&
            !checkSpecialCharacters(word) &&
            !checkSentence(word) &&
            word != "\n"
          ) {
            onceChar += 1;
          }
        });
      }
    });

    return onceChar;
  }

  function GetCharCount(targetData, start_index, end_index,statusList) {
    var total = 0;
    var index = 0;
    for (var i = start_index; i < end_index; i++) {
      if (statusList === undefined) {
        if (targetData[i] != "N/A") {
          total += Number(targetData[i]);
        }
      } else {
        var status = statusList[index];
        if (targetData[i] != "N/A" && status == "O") {
          total += Number(targetData[i]);
        }
        index++;
      }
    }
    return total;
  }

  function GetCharInvalidCount(
    targetData,
    start_index,
    end_index,
    word_length,
    statusList
  ) {
    var total = 0;
    
    var index = 0;

    for (var i = start_index; i < end_index; i++) {
      if (statusList === undefined) {
        //Token case
        if (targetData[i] == "N/A" && word_length > 0) {
          total += 1;
        }
       } else {
        var status = statusList[index];
        if (targetData[i] == "N/A" && status == "O" && word_length > 0) {
          total += 1;
        }
        index++;
        
      }
    }
    return total;
  }

  function get_specialCharacterTag(tag, word) {
    var tagToReturn = tag;
    if (tag === "x" || tag === undefined) {
      var mapOfSpecialCharacterTag = {
        "：": "COLON",
        "，": "COMMA",
        "—": "DASH",
        "！": "EXCLAMATION",
        "「": "OQuotation",
        "」": "CQuotation",
        "《": "PARENTHESIS",
        "》": "PARENTHESIS",
        "『": "PARENTHESIS",
        "』": "PARENTHESIS",
        "、": "PAUSE",
        "。": "PERIOD",
        "？": "QUESTION",
        "；": "SEMICOLON",
        "…": "ETC",
        "(": "PARENTHESIS",
        ")": "PARENTHESIS",
      };
      var newTag = mapOfSpecialCharacterTag[word];
      tagToReturn = newTag === null ? tag : newTag;

      return tagToReturn;
    } else {
      return tagToReturn;
    }
  }

  return (
    <div>
      {check_redirect()}
      {showloading()}
      {alert_save}
      <GridContainer>
        <GridItem xs={7} sm={7} md={7}>
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitle}>{Loc.segment_function}</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <form>
                <GridContainer>
                  <GridItem xs={12}>
                    {Loc.origin_article}
                    <TextField
                      fullWidth={true}
                      //   label=""
                      variant="outlined"
                      multiline
                      rows={5}
                      onChange={(text) => {
                        setTargetText(text.target.value);
                      }}
                      //   defaultValue={targetText}
                      value={targetText}
                    />
                  </GridItem>
                  <GridContainer
                    spacing={12}
                    direction="row"
                    justify="center"
                    alignItems="center"
                  >
                    <ICON_DOWN />
                  </GridContainer>

                  <GridItem xs={12}>
                    {Loc.segment_result}

                    <TextField
                      fullWidth={true}
                      //   label="切詞結果"
                      variant="outlined"
                      multiline
                      rows={5}
                      onChange={(text) => {
                        setResultText(text.target.value);
                      }}
                      value={resultText}
                    />
                  </GridItem>
                </GridContainer>
              </form>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <GridContainer
                spacing={2}
                direction="row"
                justify="center"
                alignItems="center"
              >
                {/* <script src="https://pulipulichen.github.io/jieba-js/require-jieba-js.js"></script> */}
                <GridItem xs={12} align="center">
                  <Button
                    color="rose"
                    onClick={() => {
                      set_isLoading(true);
                      console.log("success: targetText ", targetText);

                      const body = {
                        targetText: targetText,
                      };
                      fetchAPI
                        .do_fetch("post", "admin/segment-chinese", body)
                        .then(
                          (res) => {
                            set_isLoading(false);
                            var strResult = "";
                            var tags = [];
                            console.log("success: ", res.data);
                            if (res.data.new_result.length > 0) {
                              var dataList = res.data.new_result;
                              dataList.map((data, index) => {
                                if (index > 0 && index < dataList.length) {
                                  strResult += ",";
                                }
                                strResult += data.word;
                                data.tag = get_specialCharacterTag(
                                  data.tag,
                                  data.word
                                );

                                tags.push(data.tag);
                              });
                              console.log("success: strResult ", strResult);

                              setResultText(strResult);
                              setResultTag(tags);
                            }
                            setShowSave(true);
                          },
                          (error) => {
                            set_isLoading(false);
                            console.log("failed: ", error);
                            showAlert(false, Loc.failed, Loc.data_fetch_failed);
                          }
                        );
                    }}
                  >
                    <ICON_RENEW />
                    {Loc.segment_word}
                  </Button>
                  {(isEdit || showSave) && (
                    <>
                      <Button
                        color="success"
                        onClick={() => {
                          downloadResult(resultText, resultTag);
                        }}
                      >
                        <ICON_DOWNLOAD />
                        {Loc.download_excel}
                      </Button>
                    </>
                  )}
                  <hr />

                  {/* <GridItem xs={6}>
                    {"IP Address"}
                    <TextField
                      fullWidth={true}
                      variant="outlined"
                      onChange={(text) => {
                        setData_ip(text.target.value);
                      }}
                      value={data_ip}
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    {"Port"}
                    <TextField
                      fullWidth={true}
                      variant="outlined"
                      onChange={(text) => {
                        setData_port(text.target.value);
                      }}
                      value={data_port}
                    />
                  </GridItem>
                  <GridItem xs={6}>
                    {"Task"}
                    <TextField
                      fullWidth={true}
                      variant="outlined"
                      onChange={(text) => {
                        setData_task(text.target.value);
                      }}
                      value={data_task}
                    />
                  </GridItem>
                  <GridItem xs={6} align="center">
                    <Button
                      color="success"
                      onClick={() => {
                        //call
                        const body = {
                          ip_address: data_ip,
                          port: data_port,
                          text: targetText,
                          task: data_task,
                        };

                        // fetchAPI.do_fetch_fast_api("post", body).then(
                        fetchAPI
                          .do_fetch_hanlp_api("post", "/user/get_sdp", body)
                          .then(
                            (res) => {
                              // console.log("do_fetch_fast_api: ", res.data);
                              const blob = new Blob([res.data], {
                                type: "text/plain",
                              });
                              const url = URL.createObjectURL(blob);
                              const link = document.createElement("a");
                              var today = new Date();
                              var date =
                                today.getUTCFullYear() +
                                "-" +
                                today.getUTCMonth() +
                                "-" +
                                today.getDate();
                              var time =
                                today.getHours() +
                                ":" +
                                today.getMinutes() +
                                ":" +
                                today.getSeconds();
                              link.download =
                                "conll-" +
                                data_task +
                                "-" +
                                date +
                                "-" +
                                time +
                                ".txt";
                              link.href = url;
                              link.click();
                            },
                            (error) => {
                              console.log("failed: ", error);
                            }
                          );
                      }}
                      disabled={targetText == "" || targetText == null}
                    >
                      <ICON_DOWNLOAD />

                      {Loc.txt_download}
                    </Button>
                  </GridItem>
                  <hr /> */}
                </GridItem>

                <GridItem xs={6} align="center">
                  <Button onClick={backClicked}>
                    <ICON_BACK />
                    {Loc.back}
                  </Button>
                </GridItem>
                {(isEdit || showSave) && (
                  <GridItem xs={6} align="center">
                    <Button
                      color="rose"
                      onClick={doneClicked}
                      disabled={fetchingStatus === "fetching" ? true : false}
                    >
                      <ICON_DONE />

                      {Loc.save}
                    </Button>
                  </GridItem>
                )}
              </GridContainer>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
