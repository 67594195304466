/*eslint-disable*/
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
// .
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import SwitchUI from "@material-ui/core/Switch";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
import Contacts from "@material-ui/icons/Contacts";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomTextField from "components/CustomTextField/CustomTextField";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import IconButton from "@material-ui/core/IconButton";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles_switch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

import ICON_BACK from "@material-ui/icons/ArrowBackIos";
import ICON_DONE from "@material-ui/icons/Done";
import ICON_ADD from "@material-ui/icons/Add";
import ICON_DELETE from "@material-ui/icons/Clear";
import ICON_UP from "@material-ui/icons/ExpandLess";

import Datetime from "react-datetime";
import moment from "moment";
import firebase from "connectionHandler/firebase";

const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";
import DataSetType from "Data_set_type";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import { OperationCanceledException } from "typescript";

import DragDropUpload from "components/CustomUpload/DragDropUpload.js";
import MDApp from "../../components/mdedit/markdown";
const { forwardRef, useRef, useImperativeHandle } = React;

// ======================================================
//
//   Task: add a darg drop type
//   Reference: line 582 type_string
//
// ======================================================

export default function Custom_add(props) {
  const [filesArray, setFilesArray] = React.useState([]);

  const [isEdit, setIsEdit] = React.useState(false);
  const [isChangePassword, setIsChangePassword] = React.useState(false);

  const [data_id, setData_id] = React.useState("");
  const [data_name, setData_itemName] = React.useState("");
  const [data_remark, setData_remark] = React.useState("");
  const [data_customFields, setData_customFields] = React.useState([]);
  const [data_UILayout, setData_UILayout] = React.useState([]);
  const [pathToRedirect, setRedirect] = React.useState("");
  const [data_mdText,setMDText] = React.useState("");

  const [customInputStates, setCustomInputStates] = React.useState(null);
  const [productNameState, setcategoryNameState] = React.useState("");
  const [data_otherTableData, setData_otherTableData] = React.useState([]);

  const [fetchingStatus, setFetchingStatus] = React.useState("");
  const [localizationState, setLocalizationState] = React.useState(null);
  const [historyState, setHistoryState] = React.useState(null);

  const [isUploading, set_isUploading] = React.useState(false);
  // alert
  const [alert_save, setSaveAlert] = React.useState(null);
  const hideAlert = () => {
    setSaveAlert(null);
  };

  const useStyles_switch = makeStyles(styles_switch);
  const classes_switch = useStyles_switch();

  React.useEffect(() => {
    // pre load data for edit
    // console.log("testing get state: ", props);
    // console.log("Custom_setting_add::::::  "+JSON.stringify(props.location.state))

    setLocalizationState(props.location.state);
    setHistoryState(props.history.location.state);
    if (localizationState) {
      initCustomInputStates();
      if (localizationState !== null && localizationState.data !== null) {
        setIsEdit(true);
        setData_id(localizationState.data.id);
        setData_customFields(localizationState.data);
        console.log(`localizationState.data: ${JSON.stringify(localizationState.data)}`)
      } else {
        initCustomFields();
      }

      process_UI_Layout(data_customFields);

      handle_selectFromOtherTable();
    }
    // Specify how to clean up after this effect:
    return function cleanup() {};
  }, [localizationState, historyState]);
  //update ui
  function process_UI_Layout(_data_customFields) {
    const new_layout = _renderUILayout(_data_customFields);
    setData_UILayout(new_layout);
  }

  function handle_selectFromOtherTable() {
    const targetDataToLoad = [];
    localizationState.config.createFieldList.map((configItem) => {
      if ((configItem.fieldType === DataSetType.type_select_from_other_dataset) || (configItem.fieldType === DataSetType.type_select_from_other_dataset_single)) {
        targetDataToLoad.push(configItem.otherTableCollectionName);
      }
    });
    console.log("targetDataToLoad: " + targetDataToLoad);
    getCustomizedTableList(targetDataToLoad, [], (preloadedData) => {
      setData_otherTableData(preloadedData);
    });
  }

  const validCheck = (isDone) => {
    console.log("uid: " + data_customFields["uid"]);
    var result = true;
    const _customInputStates = customInputStates;
    var key_pw = "";
    var key_pw_confirm = "";
    var val_account_phone = "";
    var val_account_email = "";
    var val_pw = "";
    var val_pw_confirm = "";

    var list_unique_to_check = [];

    const configList = localizationState.config.createFieldList;
    if (configList !== null) {
      var has_pw = false;
      var has_pw_confirm = false;
      var has_phone = false;

      // check has field and get value
      configList.map((item) => {
        if (item.mandatory) {
          if (
            data_customFields[item.fieldKey] === null ||
            data_customFields[item.fieldKey] === ""
          ) {
            _customInputStates[item.fieldKey] = true;
            result = false;
          }
        } else {
          _customInputStates[item.fieldKey] = false;
        }

        if (item.isUnique) {
          var key = item.fieldKey;
          var val = data_customFields[key];
          // console.log(`item.fieldKey : ${key}  ${data_customFields[key]} `)
          var data = {};
          data[item.fieldKey] = val;
          list_unique_to_check.push(data);
        }

        if (item.fieldType === DataSetType.type_account_email) {
          val_account_email = data_customFields[item.fieldKey];
        }

        if (item.fieldType === DataSetType.type_account_password) {
          has_pw = true;
          key_pw = item.fieldKey;
          val_pw = data_customFields[item.fieldKey];
        }
        if (item.fieldType === DataSetType.type_account_password_confirm) {
          has_pw_confirm = true;
          key_pw_confirm = item.fieldKey;
          val_pw_confirm = data_customFields[item.fieldKey];
        }
        if (item.fieldType === DataSetType.type_account_phone) {
          has_phone = true;
          val_account_phone = data_customFields[item.fieldKey];
          var key_phone = item.fieldKey;

          if (val_account_phone === "") {
            _customInputStates[key_phone] = true;
            result = false;
          }
        }
      });

      // check pw & pw_confirm are not empty
      if (result && has_pw && has_pw_confirm) {
        if (!isEdit || isChangePassword) {
          if (
            val_pw === "" ||
            val_pw_confirm === "" ||
            val_pw !== val_pw_confirm
          ) {
            _customInputStates[key_pw] = true;
            _customInputStates[key_pw_confirm] = true;
            result = false;
          } else {
            _customInputStates[key_pw] = false;
            _customInputStates[key_pw_confirm] = false;
          }
        }
      }

      setCustomInputStates(_customInputStates);
    }
    // return result;
    if (!result) {
      console.log("result is false");
      isDone(result);
    } else if (has_pw || has_phone) {
      handle_firebaseUserRegister(
        data_customFields["uid"] ? data_customFields["uid"] : null,
        (!isEdit || isChangePassword) &&
          val_account_email !== null &&
          val_account_email !== ""
          ? val_account_email
          : null,
        (!isEdit || isChangePassword) && val_pw !== null && val_pw !== ""
          ? val_pw
          : null,
        val_account_phone !== null && val_account_phone !== ""
          ? val_account_phone
          : null,
        (uid) => {
          if (uid !== null) {
            var data = data_customFields;
            data["uid"] = uid;
            // remove pw
            if (key_pw !== null && key_pw_confirm !== null) {
              delete data[key_pw];
              delete data[key_pw_confirm];
            }
            setData_customFields(data);

            isDone(result);
          } else {
            isDone(false);
          }
        }
      );
    } else {
      if (list_unique_to_check.length > 0) {
        handle_checkUniqueField(list_unique_to_check, (checkResult) => {
          if (checkResult !== null) {
            isDone(checkResult);
          } else {
            isDone(false);
          }
        });
      } else {
        isDone(result);
      }
    }
  };

  function handle_checkUniqueField(_uniqueFieldList, callback) {
    const body = {
      collectionName: localizationState.config.collectionName,
      content: {
        uniqueFieldList: _uniqueFieldList,
      },
    };

    // console.log(`body ${JSON.stringify(body)}`)

    fetchAPI.do_fetch("post", "admin/custom-unique-checking", body).then(
      (res) => {
        console.log("success: ", res);
        callback(res.data);
      },
      (error) => {
        console.log("failed: ", error);
        callback(null);
      }
    );
  }

  const handle_firebaseUserRegister = (
    data_uid,
    data_account_email,
    data_account_password,
    data_account_phone = null,
    callback
  ) => {
    const body = {
      id: data_uid,
      content: {
        email: data_account_email,
        password: data_account_password,
        phone: data_account_phone,
      },
    };

    console.log(`body ${JSON.stringify(body)}`);

    fetchAPI.do_fetch("post", "admin/register-user", body).then(
      (res) => {
        console.log("success: ", res);
        callback(res.data.uid);
      },
      (error) => {
        console.log("failed: ", error);
        callback(null);
      }
    );
  };

  const backClicked = () => {
    setRedirect(`${props.location.pathname.replace("-new", "")}`);
  };

  const doneClicked = () => {
    setFetchingStatus("fetching");
    validCheck((isValid) => {
      if (isValid) {
        const idToPass = isEdit ? data_id : null;
        console.log("idToPass: ", idToPass);
        console.log(`data_customFields: ${JSON.stringify(data_customFields)}`);
        var obj = {};
        obj = { ...obj, ...data_customFields };

        const body = {
          collectionName: localizationState.config.collectionName,
          id: idToPass,
          content: obj,
        };
        console.log("Result " + JSON.stringify(body));
        fetchAPI.do_fetch("post", "admin/create-custom", body).then(
          (res) => {
            console.log("success: ", res);
            setFetchingStatus("");
            showAlert(true, Loc.success, Loc.data_save_success);
            // setData_itemName("");
          },
          (error) => {
            console.log("failed: ", error);
            setFetchingStatus("");
            showAlert(false, Loc.failed, Loc.data_save_failed);
          }
        );
      } else {
        // checkInvalidInput();
        setFetchingStatus("");
        showAlert(false, Loc.failed, Loc.data_save_failed);
      }
    });
  };

  // function checkInvalidInput(){
  //   const _customInputStates = customInputStates;

  //   const configList = props.location.state.config.createFieldList;

  //   configList.map(item =>{
  //     if(item.mandatory && (data_customFields[item.fieldKey] === "" || data_customFields[item.fieldKey] === null)){ //is mandatory but the value is empty
  //       console.log(`checkInvalidInput   ${JSON.stringify(data_customFields)}`)
  //       _customInputStates[item.fieldKey] = true;
  //     }

  //   })
  //   console.log(`checkInvalidInput   ${JSON.stringify(_customInputStates)}`)
  //    setCustomInputStates(_customInputStates)

  // }

  function showAlert(issuccess, title, content) {
    setSaveAlert(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => {
          if (issuccess) {
            setRedirect(`${props.location.pathname.replace("-new", "")}`);
          } else {
            hideAlert();
          }
        }}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.info}
        onCancel={() => {
          if (issuccess) {
            setData_itemName("");
            setData_remark("");
            initCustomFields();
            // window.location.reload(false);
            hideAlert();
          } else {
            hideAlert();
          }
        }}
        confirmBtnText={issuccess ? Loc.done : Loc.confirm}
        cancelBtnText={Loc.continue}
        btnSize="lg"
        showCancel={issuccess && !isEdit}
      >
        {content}
      </SweetAlert>
    );
  }

  const check_redirect = () => {
    if (pathToRedirect != "") {
      return (
        <Redirect
          to={{
            pathname: pathToRedirect,
            state: historyState.config,
          }}
        />
      );
    } else {
      return null;
    }
  };

  const _renderUILayout = () => {
    if (!localizationState || !localizationState.config.createFieldList) {
      return null;
    }

    const configList = localizationState.config.createFieldList;
    var customFields = data_customFields;
    if (data_customFields.length === {}) {
      return null;
    }

    const ui_gridItems = configList.map((configItem,index) => {
      if (configItem.fieldType == DataSetType.type_account_password && isEdit) {
        return (
          <>
            <GridItem xs={12} key={configItem.fieldKey}>
              <FormControlLabel
                control={
                  <SwitchUI
                    checked={isChangePassword || false}
                    onChange={
                      (event) => {
                        setIsChangePassword(event.target.checked);
                      }
                      // setData_hasDuration()
                    }
                    classes={{
                      switchBase: classes_switch.switchBase,
                      checked: classes_switch.switchChecked,
                      thumb: classes_switch.switchIcon,
                      track: classes_switch.switchBar,
                    }}
                  />
                }
                classes={{
                  label: classes.label,
                }}
                label={Loc.set_default_password}
              />
            </GridItem>
            {_renderUIComponentByType(customFields, configItem,index)}
          </>
        );
      } else {
        return _renderUIComponentByType(customFields, configItem,index);
      }
    });

    return (
      <CardBody>
        <form>
          <GridContainer>{ui_gridItems}</GridContainer>
        </form>
      </CardBody>
    );
  };

  function initCustomFields() {
    const configList = localizationState.config.createFieldList;
    var customFields = {};

    if (!isEdit) {
      configList.map((item) => {
        //set default value

        var value = null;
        if (item.fieldType === "bool") {
          value = false;
        } else if (item.fieldType === "date") {
          value = new Date();
        } else if (item.fieldType === "MC") {
          value = item.detailFieldList[0].key;
        } else if (item.fieldType === "dragdrop_upload") {
          value = [];
        } else {
          value = item.mandatory ? value : "";
        }

        customFields[item.fieldKey] = value;
        // customFieldList.push(customItem);
      });

      setData_customFields(customFields);
    }
  }

  function initCustomInputStates() {
    const configList = localizationState.config.createFieldList;
    var customInputStates = {};

    configList.map((item) => {
      //set default value
      customInputStates[item.fieldKey] = false;
    });

    setCustomInputStates(customInputStates);
  }

  const childRef = useRef();
  const mdRef = useRef();
  const mdRefs= useRef([]);
  // need create dict first when
  function _renderUIComponentByType(_customFields, _configItem,_index) {

    if (_configItem !== null) {
      const fieldName = _configItem.fieldName;
      const fieldKey = _configItem.fieldKey;
      const type = _configItem.fieldType;
      const isMandatory = _configItem.mandatory;
      const isReadOnly = _configItem.isReadOnly;
      // const data = _customFieldList[index];
      const detailFieldList = _configItem.detailFieldList;

      var fieldNameList = []; //display name
      var fieldKeyList = [];

      detailFieldList.map((item) => {
        fieldNameList.push(item.value);
        fieldKeyList.push(item.key);
      });
      console.log("_customFields" + JSON.stringify(_customFields));
      console.log("_configItem" + JSON.stringify(_configItem));
      // const type = "string";
      if (
        type === DataSetType.type_string ||
        type === DataSetType.type_number
      ) {
        return (
          <GridItem xs={12} key={fieldKey}>
            <CustomInput
              // success={requiredState === "success"}
              error={
                customInputStates === null ? false : customInputStates[fieldKey]
              }
              labelText={`${fieldName}${isMandatory ? "*" : ""}${isReadOnly ? ("("+Loc.isReadOnly+")") : ""}`}
              id={_configItem.fieldKey} //{`${fieldName}_name`}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value:
                  (_customFields[fieldKey] || ((isReadOnly) ? null : (type === DataSetType.type_string ? "" : 0))),
                  disabled: isReadOnly,
                onChange: (event) => {
                  console.log(
                    "event.target.value  type: " + event.target.value
                  );
                  _customFields[fieldKey] =
                    type === DataSetType.type_string
                      ? event.target.value
                      : parseFloat(event.target.value);
                  customInputStates[fieldKey] = false;
                  setData_customFields(_customFields);
                  process_UI_Layout(_customFields);
                  // console.log(
                  //   `data_customFieldList data  ${JSON.stringify(
                  //     data_customFields
                  //   )}`
                  // );
                },
                type: type === DataSetType.type_string ? "text" : "number",
              }}
            />
          </GridItem>
        );
      } 
      else if (type === DataSetType.type_multiline_text) {
        return (
          <GridItem xs={12} key={fieldKey}>
            <TextField
              // success={requiredState === "success"}
              error={
                customInputStates === null ? false : customInputStates[fieldKey]
              }
              labelText={`${fieldName}${isMandatory ? "*" : ""}`}
              id={_configItem.fieldKey} //{`${fieldName}_name`}
              multiline={true}
              rows={3}
              fullWidth={true}
              // formControlProps={{
              //   fullWidth: true,
              // }}
              inputProps={{
                value:
                  _customFields[fieldKey] ||
                  (type === DataSetType.type_multiline_text ? "" : 0),
                onChange: (event) => {
                  console.log(
                    "event.target.value  type: " + event.target.value
                  );
                  _customFields[fieldKey] =
                    type === DataSetType.type_multiline_text
                      ? event.target.value
                      : parseFloat(event.target.value);
                  customInputStates[fieldKey] = false;
                  setData_customFields(_customFields);
                  process_UI_Layout(_customFields);
                  // console.log(
                  //   `data_customFieldList data  ${JSON.stringify(
                  //     data_customFields
                  //   )}`
                  // );
                },
                type:
                  type === DataSetType.type_multiline_text ? "text" : "number",
              }}
            />
          </GridItem>
        );}
        // ===============================================================
        //
        //        This is the function you will be editing
        //
        // ===============================================================
        else if (type === DataSetType.type_md_editor) {
        console.log("Render MD:  "+_customFields[fieldKey]);

        return (
          <GridItem xs={12} key={fieldKey}>
            {fieldName}
            <MDApp
              ref={(el) => { mdRefs.current[_index] = el}}
              value={_customFields[fieldKey]}
              DidAcceptedInput={(input) => {
                _customFields[fieldKey] = input;
                mdRefs.current[_index].update_text(input);
                // setData_customFields({..._customFields});
                // process_UI_Layout({..._customFields});
                console.log("DidAcceptedInput:  "+input)
              }}
            />
          </GridItem>
        );
      } else if (type === DataSetType.type_dragdrop_upload) {
        return (
          <GridItem xs={12} key={fieldKey}>
            <br></br>
            <DragDropUpload
              ref={childRef}
              fileArray={filesArray}
              targetFolder="Kuso/asd/asd"
              DidAcceptedFiles={(acceptedFiles) => {
                // console.log("DidAcceptedFiles");
                // console.log(`DidAcceptedFiles fileArray: ${JSON.stringify(_fileArray)}`)
                // console.log(`DidAcceptedFiles orgFileList: ${JSON.stringify(orgFileList)}`)

                setFilesArray(acceptedFiles);
              }}
              DidAcceptedImage={(acceptedImage) => {
                console.log("DidAcceptedImage: ", acceptedImage);
                const listKey = _configItem.targetListKey;
                let temp = [];
                if (_customFields[listKey]) {
                  temp = [..._customFields[listKey]].filter(
                    (data) => data != ""
                  );
                }
                let mergedArray = [...temp, ...acceptedImage];
                temp != [""]
                  ? (mergedArray = [...temp, ...acceptedImage])
                  : (mergedArray = acceptedImage);
                _customFields[listKey] = mergedArray;
                // console.log("_customFields[listKey]: ", _customFields[listKey]);

                customInputStates[fieldKey] = false;
                setData_customFields(_customFields);
                process_UI_Layout(_customFields);
              }}
            />
            <Button
              color="success"
              round
              onClick={() => childRef.current.submit_image(filesArray, [])}
            >
              Submit
            </Button>
          </GridItem>
        );
      } else if (type === DataSetType.type_date) {
        return (
          <GridItem xs={12} key={fieldKey}>
            <Datetime
              inputProps={{
                placeholder: `${Loc.date}${isMandatory ? "*" : ""}`,
              }}
              timeFormat={true}
              defaultValue={_customFields[fieldKey]}
              value={
                _customFields[fieldKey] === null
                  ? new Date()
                  : moment(_customFields[fieldKey])
              }
              onChange={(m) => {
                if (moment.isMoment(m)) {
                  _customFields[fieldKey] = m.toDate();
                  // console.log(
                  //   `data_customFieldList data   ${JSON.stringify(
                  //     _customFields
                  //   )}`
                  // );
                  setData_customFields(_customFields);
                  process_UI_Layout(_customFields);
                } else {
                  // setFromDateState("error");
                }
              }}
            />
          </GridItem>
        );
      } else if (type === DataSetType.type_bool) {
        return (
          <GridItem xs={12} key={fieldKey}>
            <FormControlLabel
              control={
                <SwitchUI
                  checked={_customFields[fieldKey] || false}
                  onChange={
                    (event) => {
                      _customFields[fieldKey] = event.target.checked;
                      setData_customFields(_customFields);
                      process_UI_Layout(_customFields);
                      // console.log(
                      //   `data_customFieldList data   ${JSON.stringify(
                      //     _customFieldList
                      //   )}`
                      // );
                    }
                    // setData_hasDuration()
                  }
                  classes={{
                    switchBase: classes_switch.switchBase,
                    checked: classes_switch.switchChecked,
                    thumb: classes_switch.switchIcon,
                    track: classes_switch.switchBar,
                  }}
                />
              }
              classes={{
                label: classes.label,
              }}
              label={`${fieldName}${isMandatory ? "*" : ""}`}
            />
          </GridItem>
        );
      } else if (type === DataSetType.type_MC) {
        //MC Case
        return (
          <GridItem xs={12} key={fieldKey}>
            {`${fieldName}${isMandatory ? "*" : ""}`}
            <CustomDropdown
              buttonProps={{
                round: true,
                color: "info",
              }}
              buttonText={
                <span>
                  {getStringToShow(
                    fieldNameList,
                    detailFieldList,
                    _customFields[fieldKey]
                  )}
                </span>
              }
              dropdownList={fieldNameList}
              itemIDList={fieldKeyList}
              onClick={(selectedID) => {
                if (selectedID === null) {
                  _customFields[fieldKey] = "";
                  setData_customFields(_customFieldList);
                } else {
                  _customFields[fieldKey] = selectedID;
                  setData_customFields(_customFields);
                  process_UI_Layout(_customFields);
                  // console.log(
                  //   `data_customFieldList data   ${JSON.stringify(
                  //     _customFields
                  //   )}`
                  // );
                }
              }}
            />
          </GridItem>
        );
      } else if (type === DataSetType.type_account_phone) {
        return (
          <GridItem xs={12} key={fieldKey}>
            <CustomInput
              // success={requiredState === "success"}
              error={
                customInputStates === null ? false : customInputStates[fieldKey]
              }
              labelText={`${fieldName}${isMandatory ? "*" : ""}`}
              id={_configItem.fieldKey} //{`${fieldName}_name`}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: _customFields[fieldKey] || "",
                onChange: (event) => {
                  _customFields[fieldKey] = event.target.value;
                  customInputStates[fieldKey] = false;
                  setData_customFields(_customFields);
                  process_UI_Layout(_customFields);
                },
                type: "tel",
              }}
            />
          </GridItem>
        );
      } else if (type === DataSetType.type_account_email) {
        return (
          <GridItem xs={12} key={fieldKey}>
            <CustomInput
              // success={requiredState === "success"}
              error={
                customInputStates === null ? false : customInputStates[fieldKey]
              }
              labelText={`${fieldName}${isMandatory ? "*" : ""}`}
              id={_configItem.fieldKey} //{`${fieldName}_name`}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: _customFields[fieldKey] || "",
                onChange: (event) => {
                  _customFields[fieldKey] = event.target.value;
                  customInputStates[fieldKey] = false;
                  setData_customFields(_customFields);
                  process_UI_Layout(_customFields);
                },
                type: "email",
              }}
            />
          </GridItem>
        );
      } else if (
        type === DataSetType.type_account_password ||
        type === DataSetType.type_account_password_confirm
      ) {
        return isEdit && !isChangePassword ? null : (
          <GridItem xs={12} key={fieldKey}>
            <CustomInput
              // success={requiredState === "success"}
              error={
                customInputStates === null ? false : customInputStates[fieldKey]
              }
              labelText={`${fieldName}${isMandatory ? "*" : ""}`}
              id={_configItem.fieldKey} //{`${fieldName}_name`}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: _customFields[fieldKey] || "",
                onChange: (event) => {
                  _customFields[fieldKey] = event.target.value;
                  customInputStates[fieldKey] = false;
                  setData_customFields(_customFields);
                  process_UI_Layout(_customFields);
                },
                type: "password",
              }}
            />
          </GridItem>
        );
        // }
      } else if (type === DataSetType.type_upload_image_single) {
        return (
          <GridItem xs={12} align="center" key={fieldKey}>
            <ImageUpload
              imageDidSelect={() => {
                set_isUploading(true);
              }}
              imageDidUpload={(pathInStorage) => {
                if (pathInStorage != null) {
                  _customFields[fieldKey] = pathInStorage;
                  customInputStates[fieldKey] = false;
                  setData_customFields(_customFields);
                  console.log("imageDidUpload called, path: ", pathInStorage);
                  set_isUploading(false);
                }
              }}
              imageUrl={_customFields[fieldKey] || ""}
              targetFolder={fieldKey}
            />
          </GridItem>
        );
      } else if (type === DataSetType.type_select_from_other_dataset_single) {
        return (
          <GridItem xs={12} key={fieldKey}>
            <Card>
              <CardBody>
                <GridContainer>
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    {fieldName}
                  </InputLabel>

                  {_render_cardBody_selectFromOther_single(
                    fieldName,
                    fieldKey,
                    _configItem.otherTableCollectionName,
                    _configItem.otherTableShowFieldName,
                    _customFields
                  )}
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        );
      }else if (type === DataSetType.type_select_from_other_dataset) {
        return (
          <GridItem xs={12} key={fieldKey}>
            <Card>
              <CardBody>
                <GridContainer>
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    {fieldName}
                  </InputLabel>

                  {_render_cardBody_selectFromOther(
                    fieldName,
                    fieldKey,
                    _configItem.otherTableCollectionName,
                    _configItem.otherTableShowFieldName,
                    _customFields
                  )}
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        );
      } 
      else if (type === DataSetType.type_list) 
      {
        return (
          <GridItem xs={12} key={fieldKey}>
            <Card>
              <CardBody>
                <GridContainer>
                  <InputLabel
                    htmlFor="simple-select"
                    className={classes.selectLabel}
                  >
                    {fieldName}
                  </InputLabel>
                  {/* {_customFields[fieldKey] == "" &&  
                  (
                  <GridItem xs={12}>    

                    <Button
                    // aria-label="add"
                    color="success"
                  round
                    onClick={() => {
                      _customFields[fieldKey] = [""];
                      customInputStates[fieldKey] = false;
                      setData_customFields(_customFields);
                      process_UI_Layout(_customFields);


                      console.log("new_customFields:  "+JSON.stringify(_customFields));
                    }}
                  >
                    <ICON_ADD />
                  </Button>
          </GridItem>

                  )} */}
                  {_render_cardBody_list(fieldName, fieldKey, _customFields)}
                </GridContainer>
              </CardBody>
            </Card>
          </GridItem>
        );
      }
      else if (type === DataSetType.type_firebase_geopoint)
      {
        if (_customFields[fieldKey] === undefined || _customFields[fieldKey] === null)
        {
          _customFields[fieldKey] = {
            "isGeoPt": true,
            "_latitude": 0,
            "_longitude": 0
          }
        }
        else
        {
          _customFields[fieldKey] = {
            "isGeoPt": true,
            "_latitude": (_customFields[fieldKey]["_latitude"] == null || _customFields[fieldKey]["_latitude"] == "") ? 0 : _customFields[fieldKey]["_latitude"],
            "_longitude": (_customFields[fieldKey]["_longitude"] == null || _customFields[fieldKey]["_longitude"] == "") ? 0 : _customFields[fieldKey]["_longitude"]
          };
        }

        return (
          <GridItem xs={12} key={fieldKey}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={6}>
                    <CustomInput
                      labelText={`Lat`}
                      id={"_latitude"} //{`${fieldName}_name`}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: (_customFields[fieldKey] == null ? 0 : ((_customFields[fieldKey]["_latitude"] == null || _customFields[fieldKey]["_latitude"] == "") ? 0 : _customFields[fieldKey]["_latitude"])),
                        onChange: (event) => {
                          var lat = (event.target.value == "") ? 0 : parseFloat(event.target.value);
                          var lng = (_customFields[fieldKey] == null ? 0 : (_customFields[fieldKey]["_longitude"] == null ? 0 : _customFields[fieldKey]["_longitude"]));
                          var geoPt = {
                            "isGeoPt": true,
                            "_latitude": lat,
                            "_longitude": lng
                          }
                          _customFields[fieldKey] = geoPt;
                          customInputStates[fieldKey] = false;
                          setData_customFields(_customFields);
                          process_UI_Layout(_customFields);
                        },
                        type: "number",
                      }}
                    />
                  </GridItem>

                  <GridItem xs={6}>
                    <CustomInput
                      labelText={`Lng`}
                      id={"_longitude"} //{`${fieldName}_name`}
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        value: (_customFields[fieldKey] == null ? 0 : ((_customFields[fieldKey]["_longitude"] == null || _customFields[fieldKey]["_longitude"] == "") ? 0 : _customFields[fieldKey]["_longitude"])),
                        onChange: (event) => {
                          var lat = (_customFields[fieldKey] == null ? 0 : (_customFields[fieldKey]["_latitude"] == null ? 0 : _customFields[fieldKey]["_latitude"]));
                          var lng = (event.target.value == "") ? 0 : parseFloat(event.target.value);
                          var geoPt = {
                            "_latitude": lat,
                            "_longitude": lng
                          }
                          _customFields[fieldKey] = geoPt;
                          customInputStates[fieldKey] = false;
                          setData_customFields(_customFields);
                          process_UI_Layout(_customFields);
                        },
                        type: "number",
                      }}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
        </GridItem>
        )
      }
    }
    return [];
  }

  function cardBody_list_addNew(currentList) {
    var newList = currentList;
    if (!newList || newList === null) {
      newList = [];
    }
    newList.push("");
    return newList;
  }

  function _render_cardBody_list(fieldName, fieldKey, _customFields) {
    var newList = _customFields[fieldKey];
    if (!newList || newList === null) {
      newList = cardBody_list_addNew(newList);
    }
    const body_selects = newList.map((item, key) => {
      const isLast = key + 1 === newList.length;
      const isOnlyOne = newList.length === 1;
      const currentValue = item && item.targetID ? item.targetID : "";
      return (
        <>
          <GridContainer>
            <GridItem xs={8} key={key}>
              <CustomInput
                // success={requiredState === "success"}
                // error={productNameState === "error"}
                labelText={"Field Value" + "*"}
                id="field_value"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: newList[key],
                  onChange: (event) => {
                    // data.fieldKey = event.target.value;
                    // setData_createFieldList(_data_createFieldList);

                    // process_UI_Layout(_data_createFieldList);
                    // console.log(`newList  ${JSON.stringify(newList)}`)

                    newList[key] = event.target.value;

                    var new_customFields = _customFields;
                    new_customFields[fieldKey] = newList;
                    customInputStates[fieldKey] = false;
                    setData_customFields(new_customFields);
                    process_UI_Layout(new_customFields);
                  },
                  type: "text",
                }}
              />
            </GridItem>
            <GridItem xs={0.5}>
              <IconButton
                aria-label="up"
                color="default"
                onClick={() => {
                  if (key !== 0) {
                    const targetItem = newList[key - 1];
                    newList[key - 1] = newList[key];
                    newList[key] = targetItem;
                  }

                  var new_customFields = _customFields;
                  new_customFields[fieldKey] = newList;
                  setData_customFields(new_customFields);
                  process_UI_Layout(new_customFields);
                }}
              >
                <ICON_UP />
              </IconButton>
            </GridItem>

            <GridItem xs={0.5}>
              <IconButton
                aria-label="delete"
                color="default"
                onClick={() => {
                  if (!isOnlyOne) {
                    newList.splice(key, 1);
                  } else {
                    newList = [""];
                  }

                  var new_customFields = _customFields;
                  new_customFields[fieldKey] = newList;
                  setData_customFields(new_customFields);
                  process_UI_Layout(new_customFields);
                }}
              >
                <ICON_DELETE />
              </IconButton>
            </GridItem>

            <GridItem xs={12}>
              {!isLast ? null : (
                <Button
                  // aria-label="add"
                  color="success"
                  round
                  onClick={() => {
                    newList = cardBody_list_addNew(newList);
                    var new_customFields = _customFields;
                    new_customFields[fieldKey] = newList;
                    setData_customFields(new_customFields);
                    process_UI_Layout(new_customFields);
                  }}
                >
                  <ICON_ADD />
                </Button>
              )}
            </GridItem>
          </GridContainer>
        </>
      );
    });

    return body_selects;
  }

  function cardBody_selectFromOther_addNew(currentList, targetCollection) {
    var newList = currentList;
    if (!newList || newList === null) {
      newList = [];
    }
    const newData = {
      isRelatedField: true,
      targetID: "",
      targetCollection: "",
    };
    newList.push(newData);
    return newList;
  }

  function _render_cardBody_selectFromOther_single(
    fieldName,
    fieldKey,
    targetCollection,
    showFieldName,
    _customFields
  ) {
    var obj_fromOther_single = _customFields[fieldKey];
    if (!obj_fromOther_single || obj_fromOther_single === null) {
      obj_fromOther_single = {
          targetID: "",
          targetCollection: "",
      };
    }
    var body_selects = (
        <GridContainer>
          <GridItem xs={12}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={obj_fromOther_single.targetID}
                onChange={(event) => {
                  const selectedID = event.target.value;
                  const selectedTargetData = {
                    targetID: selectedID,
                    targetCollection: targetCollection,
                  };

                  var new_customFields = _customFields;
                  new_customFields[fieldKey] = selectedTargetData;
                  customInputStates[fieldKey] = false;
                  setData_customFields(new_customFields);
                  process_UI_Layout(new_customFields);
                }}
                inputProps={{
                  name: fieldName,
                  id: fieldKey,
                }}
              >
                {_render_targetTableDataMenuList(
                  targetCollection,
                  showFieldName
                )}
              </Select>
            </FormControl>
          </GridItem>
        </GridContainer>
      );

    return body_selects;
  }

  function _render_cardBody_selectFromOther(
    fieldName,
    fieldKey,
    targetCollection,
    showFieldName,
    _customFields
  ) {
    var newList = _customFields[fieldKey];
    if (!newList || newList === null) {
      newList = cardBody_selectFromOther_addNew(newList, targetCollection);
    }
    const body_selects = newList.map((item, key) => {
      const isLast = key + 1 === newList.length;
      const isOnlyOne = newList.length === 1;
      const currentValue = item && item.targetID ? item.targetID : "";
      return (
        <GridContainer>
          <GridItem xs={8} key={key}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <Select
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={currentValue}
                onChange={(event) => {
                  const selectedID = event.target.value;
                  const selectedTargetData = {
                    isRelatedField: true,
                    targetID: selectedID,
                    targetCollection: targetCollection,
                  };

                  newList[key] = selectedTargetData;

                  var new_customFields = _customFields;
                  new_customFields[fieldKey] = newList;
                  customInputStates[fieldKey] = false;
                  setData_customFields(new_customFields);
                  process_UI_Layout(new_customFields);
                }}
                inputProps={{
                  name: fieldName,
                  id: fieldKey,
                }}
              >
                {_render_targetTableDataMenuList(
                  targetCollection,
                  showFieldName
                )}
              </Select>
            </FormControl>
          </GridItem>

          <GridItem xs={0.5}>
            <IconButton
              aria-label="up"
              color="default"
              onClick={() => {
                if (key !== 0) {
                  const targetItem = newList[key - 1];
                  newList[key - 1] = newList[key];
                  newList[key] = targetItem;
                }

                var new_customFields = _customFields;
                new_customFields[fieldKey] = newList;
                setData_customFields(new_customFields);
                process_UI_Layout(new_customFields);
              }}
            >
              <ICON_UP />
            </IconButton>
          </GridItem>

          <GridItem xs={0.5}>
            <IconButton
              aria-label="delete"
              color="default"
              onClick={() => {
                if (!isOnlyOne) {
                  newList.splice(key, 1);
                } else {
                  newList[key].targetID = "";
                  newList[key].targetCollection = "";
                }

                var new_customFields = _customFields;
                new_customFields[fieldKey] = newList;
                setData_customFields(new_customFields);
                process_UI_Layout(new_customFields);
              }}
            >
              <ICON_DELETE />
            </IconButton>
          </GridItem>

          <GridItem xs={0.5}>
            {!isLast ? null : (
              <IconButton
                aria-label="add"
                color="default"
                onClick={() => {
                  newList = cardBody_selectFromOther_addNew(
                    newList,
                    targetCollection
                  );
                  var new_customFields = _customFields;
                  new_customFields[fieldKey] = newList;
                  setData_customFields(new_customFields);
                  process_UI_Layout(new_customFields);
                }}
              >
                <ICON_ADD />
              </IconButton>
            )}
          </GridItem>
        </GridContainer>
      );
    });

    return body_selects;
  }

  function _render_targetTableDataMenuList(targetCollection, showFieldKey) {
    if (
      data_otherTableData[targetCollection] &&
      data_otherTableData[targetCollection] !== null
    ) {
      const menuItems = [];
      data_otherTableData[targetCollection].map((item, key) => {
        menuItems.push(
          <MenuItem
            key={key}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={item.id}
          >
            {item[showFieldKey]}
          </MenuItem>
        );
      });

      return menuItems;
    } else {
      return null;
    }
  }

  function getCustomizedTableList(list_targetCollection, list_result, onDone) {
    const targetCollection = list_targetCollection[0];
    var newData = list_result;
    const body = {
      data_targetCollection: targetCollection,
    };
    console.log(`body: ${JSON.stringify(body)}`)
    fetchAPI.do_fetch("post", "admin/list-all", body).then(
      (res) => {
        console.log("success: ", res.data);
        // return res.data.data_list;
        newData[targetCollection] = res.data.data_list;

        var newCollectionList = list_targetCollection;
        newCollectionList.splice(0, 1);
        if (newCollectionList.length === 0) {
          onDone(newData);
        } else {
          getCustomizedTableList(newCollectionList, newData, (res) => {
            onDone(res);
          });
        }
      },
      (error) => {
        console.log("failed: ", error);
        // showAlert(false, Loc.failed, Loc.data_fetch_failed);
        // return [];
        newData[targetCollection] = [];
        var newCollectionList = list_targetCollection;
        newCollectionList.splice(0, 1);
        if (newCollectionList.length === 0) {
          onDone(newData);
        } else {
          getCustomizedTableList(newCollectionList, newData, (res) => {
            onDone(res);
          });
        }
      }
    );
  }

  const showuploading = () => {
    if (isUploading) {
      return (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          closeOnClickOutside={false}
          title={Loc.loading}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnText={Loc.confirm}
          confirmBtnCssClass={
            classes_alert.button + " " + classes_alert.success
          }
          btnSize="lg"
          showConfirm={false}
          showCancel={false}
        >
          {Loc.uploading}
        </SweetAlert>
      );
    } else {
      return null;
    }
  };

  const getStringToShow = (
    fieldNameList,
    detailFieldList,
    selectedID,
    isMandatory
  ) => {
    var strToShow = "";
    strToShow = fieldNameList[0];
    // console.log(`selectedID getStringToShow ${selectedID}` )
    detailFieldList.map((prop) => {
      if (prop.key === selectedID) {
        strToShow = prop.value;
      }
    });

    return strToShow;
  };

  const classes = useStyles();
  const classes_alert = useStyles_alert();

  function handle_viewWidth()
  {
    if (localizationState != null && localizationState.config != null && localizationState.config.viewWidth != null)
    {
      return localizationState.config.viewWidth;
    }
    else
    {
      return 12;
    }
  }

  return (
    <div>
      {check_redirect()}
      {showuploading()}
      {alert_save}
      <GridContainer>
        <GridItem xs={handle_viewWidth()}>
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitle}>{Loc.basic_info}</h4>
              </CardText>
            </CardHeader>

            {_renderUILayout()}

            <CardFooter className={classes.justifyContentCenter}>
              <GridContainer
                spacing={2}
                direction="row"
                justify="center"
                alignItems="center"
              >
                <GridItem xs={6} align="center">
                  <Button onClick={backClicked}>
                    <ICON_BACK />
                    {Loc.back}
                  </Button>
                </GridItem>

                <GridItem xs={6} align="center">
                  <Button
                    color="rose"
                    onClick={doneClicked}
                    disabled={fetchingStatus === "fetching" ? true : false}
                  >
                    <ICON_DONE />
                    {isEdit ? Loc.save : Loc.create}
                  </Button>
                </GridItem>
              </GridContainer>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
