/*eslint-disable*/
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomDropdown from 'components/CustomDropdown/CustomDropdown.js';
import InputLabel from "@material-ui/core/InputLabel";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import moment from 'moment';
import StoreDropdown from 'views/CommonUseComponents/StoreDropdown.js';
import Dropdown from 'views/CommonUseComponents/Dropdown.js';

// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
import Contacts from "@material-ui/icons/Contacts";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SwitchUI from "@material-ui/core/Switch";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles_switch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import ImageUpload from "components/CustomUpload/ImageUpload.js";

import ICON_BACK from "@material-ui/icons/ArrowBackIos";
import ICON_DONE from "@material-ui/icons/Done";


const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);
const useStyles_switch = makeStyles(styles_switch);

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";

export default function Expenditure_add(props) {

      // type validation
  const [isEdit, setIsEdit] = React.useState(false);

  const [data_id, setData_id] = React.useState("");
  const [data_name, setData_itemName] = React.useState("");
  const [data_amount, setData_amount] = React.useState("");
  const [data_remark, setData_remark] = React.useState("");

  const data_targetCollection = "Expenditure";

  const [data_date, setData_date] = React.useState(new Date());
  const [data_expenditureType, setData_expenditureType] = React.useState("other");
  const [data_staffID, setData_staffID] = React.useState("");
  const [data_storeID, setData_storeID] = React.useState("");
  const [data_storeName, setData_storeName] = React.useState("");
  const [data_staffName, setData_staffName] = React.useState("");
  
  const [data_dataListArray, setData_dataListArray] = React.useState(null);
  const [data_isPreLoadData, setData_preLoadData] = React.useState(false);

  const [pathToRedirect, setRedirect] = React.useState("");

  const [productNameState, setItemNameState] = React.useState("");
  const [dateState, setdateState] = React.useState("");

  const [fetchingStatus, setFetchingStatus] = React.useState("");
  const [data_adminInfo,setData_adminInfo] = React.useState(null)
  // const [isPreLoadedData, setIsPreLoadedData] = React.useState(false);
  // const [data_store, setData_store] = React.useState(null);
  // const [data_staff, setData_staff] = React.useState(null);
  const [locationState, setLocationState] = React.useState(null);
  const [historyState, setHistoryState] = React.useState(null);

  const [isConfidential,setIsConfidential]= React.useState(false);

  // alert
  const [alert_save, setSaveAlert] = React.useState(null);
  const hideAlert = () => {
    setSaveAlert(null);
  }
  const classes_switch = useStyles_switch();
  
  React.useEffect(() => {  
    var preloadListStr = localStorage.getItem("preloadDataList")
    var preloadList = JSON.parse(preloadListStr);

    if (!locationState || locationState == null) {
        setLocationState(props.location.state);
        setHistoryState(props.history.location.state);    
        setData_adminInfo(props.location.state.config.adminInfo)
      } else{

          if (data_dataListArray === null )
          {
              // const stateConfig = locationState.config;
              if (preloadList["Store"].length > 0) {
                setData_storeID(preloadList["Store"][0].id);
                setData_storeName(preloadList["Store"][0].itemName);
              }
              var adminInfo = props.location.state.config.adminInfo;
              // console.log("Admin Store :"+ adminInfo)

              if(adminInfo.storeID){
                console.log("Admin Store :"+ adminInfo.storeName)
                setData_storeID(adminInfo.storeID);
                setData_storeName(adminInfo.storeName);
              }
              // console.log("data_adminInfo "+JSON.stringify(props.location.state.config.adminInfo))
      
              if (preloadList["Staff"].length > 0) {
                setData_staffID(preloadList["Staff"][0].id);
                setData_staffName(preloadList["Staff"][0].itemName);
              }
              
              setData_dataListArray(preloadList);
          }

          // set to edit
          if (data_dataListArray !== null && locationState !== null && locationState.data !== null && !data_isPreLoadData)
          {
            
              const stateData = locationState.data;
              setIsEdit(true);
              setData_preLoadData(true);
            
              setData_id(stateData.id);
              setData_itemName(stateData.itemName);
              setData_amount(stateData.amount);
              setData_remark(stateData.remark);
              setData_date(stateData.date);
              setData_storeID(stateData.storeID);
              setData_staffID(stateData.staffID);
              setData_storeName(stateData.storeName);
              setData_staffName(stateData.staffName);
              setIsConfidential(stateData.isConfidential);
              setData_expenditureType(stateData.type);
              
          }
        
      }

    // Specify how to clean up after this effect:
    return function cleanup() {
      
    };
  }, [data_dataListArray,locationState]);

  // function do_preLoadData()
  // {
  //   return new Promise((onDone) => {
  //       // get store data
  //       do_loadStoreList().then(storeData => {
  //           if (storeData != null)
  //           {
  //               setData_store(storeData);
  //               // get staff data
  //               do_loadStaffList().then(staffData => {
  //                   if (staffData != null)
  //                   {
  //                       setData_staff(staffData);
  //                       onDone(true);
  //                   }
  //                   else
  //                   {
  //                       onDone(false);
  //                   }
  //               })
  //           }
  //           else
  //           {
  //               onDone(false);
  //           }
  //       })
  //   })
  // }

  function do_loadStoreList()
  {
    return new Promise((onDone) => {
        const body = {
            "data_targetCollection":"Store",
          }
          fetchAPI.do_fetch('post', 'admin/list-all', body)
          .then((res) => {
            console.log("success: ", res.data);
            onDone(res.data.data_list);
          }, error => {
            console.log("failed: ", error);
            showAlert(false, Loc.failed, "Load store faild");
            onDone(null);
          })
    })
  }
  function do_loadStaffList()
  {
    return new Promise((onDone) => {
        const body = {
            "data_targetCollection":"Staff",
          }
          fetchAPI.do_fetch('post', 'admin/list-all', body)
          .then((res) => {
              console.log("success: ", res.data);
              onDone(res.data.data_list);
          }, error => {
            console.log("failed: ", error);
            showAlert(false, Loc.failed, "Load staff faild");
            onDone(null);
          })
    })
  }

  const validCheck = () => {
    var result = true;
    if (data_name == "")
    {
        result = false;
    }
    else if (dateState === "error")
    {
        result = false;
    }
    return result;
  }

  const backClicked = () => {
    setRedirect("/admin/expenditure-list");
  }

  const doneClicked =() => {
    setFetchingStatus("fetching");
    if (validCheck())
    {
      // console.log("data_date  :"  +data_date)
        const idToPass = isEdit ? data_id : null;
        console.log("idToPass: ", idToPass);
        const body = {
            "id":idToPass,
            "content":{
                "itemName":data_name,
                "amount":data_amount,
                "remark":data_remark,
                "date":new Date(data_date).getTime(),
                "storeID":data_storeID,
                "storeName":data_storeName,
                "staffName":data_staffName,
                "type":data_expenditureType,
                "staffID":(data_expenditureType != "commission") ? "" : data_staffID,
                "isConfidential":isConfidential
            }
        }
        fetchAPI.do_fetch('post', 'admin/create-expenditure', body)
        .then((res) => {
            console.log("success: ", res);
            setFetchingStatus("");
            showAlert(true, Loc.success, Loc.data_save_success);
            // setData_itemName("");
        }, error => {
            console.log("failed: ", error);
            setFetchingStatus("");
            showAlert(false, Loc.failed, Loc.data_save_failed);
        })
    }
    else
    {
        setItemNameState("error");
        setFetchingStatus("");
        showAlert(false, Loc.failed, Loc.data_save_failed);
    }
  }

  function showAlert(issuccess, title, content){
    setSaveAlert(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => {
            if (issuccess)
            {
                // hideAlert()
                setRedirect("/admin/expenditure-list");
            }
            else
            {
                hideAlert()
            }
        }}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.info}
        onCancel={() => {
            if (issuccess)
            {
                setData_itemName("");
                setData_amount("");
                setData_remark("");
                setData_date(new Date());
                // window.location.reload(false);
                hideAlert()
            }
            else
            {
                hideAlert()
            }
            
        }}
        confirmBtnText={issuccess ? Loc.done : Loc.confirm}
        cancelBtnText={Loc.continue}
        btnSize="lg"
        showCancel={issuccess && !isEdit}
      >
        {content}
      </SweetAlert>
    );
  }
const handleChange_type = event => {
    setData_expenditureType(event.target.value);
    console.log("handleChange_type: "+event.target.value)
    if(event.target.value == "commission"){
    setData_itemName(getStringToShow(
      Loc.staff_default,
      data_dataListArray == null
        ? []
        : data_dataListArray["Staff"],
      "Staff",
       data_staffID
    )+Loc.commission_expenditure)
      
    }else{
      setData_itemName("")
    }
};


  const check_redirect = () => {
      if (pathToRedirect != "")
      {
          return (<Redirect to={{ pathname: pathToRedirect, state: historyState.config }} />);
      }
      else
      {
          return null;
      }
  }

  const imageDidUpload = (pathInStorage) => {
      if (pathInStorage != null)
      {
        setData_imageUrl(pathInStorage);
        console.log("imageDidUpload called, path: ", pathInStorage);
      }
  }

  const getStringToShow = (defaultStr, fieldList, fieldName, id) => {
    console.log(`getStringToShow ${fieldName} ===>${id}`);
    var strToShow = defaultStr;

    if (fieldList.length > 0) {
      const fieldNameList = getDropDownNameList(fieldName);
      strToShow = fieldNameList[0];
      fieldList.map((prop, key) => {
        if (prop.id === id) {
          strToShow = prop.itemName;
        }
      });
    }
    return strToShow;
  };


  const getDropDownNameList = (fieldName) => {
    const fieldList =
      data_dataListArray == null ? [] : data_dataListArray[fieldName];
    const fieldNameList = [];
    if (fieldList.length > 0) {
      fieldList.map((item) => {
        fieldNameList.push(item.itemName);
      });
    }
    return fieldNameList;
  };

  const getSelectMenuItem = (fieldName) => {
    const fieldList =
      data_dataListArray == null ? [] : data_dataListArray[fieldName];
    const menuItems = [];

    // console.log(`getSelectMenuItem   `+ JSON.stringify(data_dataListArray))

    if (fieldList && fieldList.length > 0) {
      fieldList.map((item, key) => {
        menuItems.push(
          <MenuItem
            key={key}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={item.id}
          >
            {item.itemName}
          </MenuItem>
        );
      });
    }

    return menuItems;
  };

    const classes = useStyles();
    const classes_alert = useStyles_alert();
    
    return (
    <div>
        {check_redirect()}
        {alert_save}
        <GridContainer>
        <GridItem xs={6}>
            <Card>
            <CardHeader color="rose" text>
                <CardText color="rose">
                    <h4 className={classes.cardTitle}>{Loc.expenditure_add}</h4>
                </CardText>
            </CardHeader>
            <CardBody>
                <form>
                <GridContainer>

                <GridItem sm={4}>
                <FormControl
                    fullWidth
                    className={
                      classes.selectFormControl +
                      " " +
                      classes.formControlMargins
                    }
                  >
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={data_storeID}
                      onChange={(event) => {
                        const selectedID = event.target.value;
                        setData_storeName(
                          getStringToShow(
                            Loc.store_default,
                            data_dataListArray == null
                              ? []
                              : data_dataListArray["Store"],
                            "Store",
                            selectedID
                          )
                        );
                        setData_storeID(selectedID);
                        console.log("selectedID  " + selectedID);
                        console.log("setData_storeName  " + data_storeName);
                      }}
                      inputProps={{
                        name: data_storeName,
                        id: data_storeID,
                      }}
                    >
                      {getSelectMenuItem("Store")}
                    </Select>
                  </FormControl>
                    </GridItem>
                    <GridItem sm={4}>
                    {data_expenditureType != "commission" ? null :
                    <FormControl
                    fullWidth
                    className={
                      classes.selectFormControl +
                      " " +
                      classes.formControlMargins
                    }
                  >
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={data_staffID}
                      onChange={(event) => {
                        const selectedID = event.target.value;
                        setData_staffID(selectedID);
                        setData_staffName(
                          getStringToShow(
                            Loc.staff_default,
                            data_dataListArray == null
                              ? []
                              : data_dataListArray["Staff"],
                            "Staff",
                            selectedID
                          )
                        );

                        setData_itemName(getStringToShow(
                            Loc.staff_default,
                            data_dataListArray == null
                              ? []
                              : data_dataListArray["Staff"],
                            "Staff",
                            selectedID
                          )+Loc.commission_expenditure)
                      }}
                      inputProps={{
                        name: data_staffName,
                        id: data_staffID,
                      }}
                    >
                      {getSelectMenuItem("Staff")}
                      {/* {} */}
                    </Select>
                  </FormControl>}
                    </GridItem>
                    <GridItem sm={4}>
                        <FormControl fullWidth>
                            <Datetime
                                timeFormat={true}
                                defaultValue={data_date}
                                value={data_date == null ? new Date(): moment(data_date)}
                                inputProps={{ 
                                    placeholder: Loc.sales_date,
                                }}
                                onChange={m => {
                                    if (moment.isMoment(m))
                                    {
                                        setdateState("success");
                                        setData_date(m.toDate());
                                    }
                                    else{
                                        setdateState("error");
                                    }
                                }}
                            />
                        </FormControl>
                    </GridItem>

                    <GridItem xs={12}>
                        <CustomInput
                            // success={requiredState === "success"}
                            error={productNameState === "error"}
                            labelText={Loc.expenditure_name+"*"}
                            id="expenditure_name"
                            formControlProps={{
                            fullWidth: true
                            }}
                            inputProps={{
                                value: data_name,
                                onChange: event => {
                                    setItemNameState("success");
                                    setData_itemName(event.target.value);
                                },
                                type: "text",
                            }}
                        />
                    </GridItem>  

                    <GridItem xs={12}>
                        <CustomInput
                            labelText={Loc.expenditure_amount}
                            id="expenditure_amount"
                            formControlProps={{
                            fullWidth: true
                            }}
                            inputProps={{
                                value: data_amount,
                                onChange: event => {
                                    setData_amount(event.target.value);
                                },
                                type: "number",
                            }}
                        />
                    </GridItem>  

                    <GridItem xs={12} sm={6}>
                        <div
                            className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                            }
                        >
                        <FormControlLabel
                        control={
                            <Radio
                            checked={data_expenditureType === "inventory-in"}
                            onChange={handleChange_type}
                            value="inventory-in"
                            name={Loc.purchases_expenditure}
                            aria-label="inventory-in"
                            icon={
                                <FiberManualRecord
                                className={classes.radioUnchecked}
                                />
                            }
                            checkedIcon={
                                <FiberManualRecord
                                className={classes.radioChecked}
                                />
                            }
                            classes={{
                                checked: classes.radio,
                                root: classes.radioRoot
                            }}
                            />
                        }
                        classes={{
                            label: classes.label,
                            root: classes.labelRoot
                        }}
                        label={Loc.purchases_expenditure}
                        />
                        </div>
                        <div
                            className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                            }
                        >
                        <FormControlLabel
                        control={
                            <Radio
                            checked={data_expenditureType === "commission"}
                            onChange={handleChange_type}
                            value="commission"
                            name={Loc.commission_expenditure}
                            aria-label="commission"
                            icon={
                                <FiberManualRecord
                                className={classes.radioUnchecked}
                                />
                            }
                            checkedIcon={
                                <FiberManualRecord
                                className={classes.radioChecked}
                                />
                            }
                            classes={{
                                checked: classes.radio,
                                root: classes.radioRoot
                            }}
                            />
                        }
                        classes={{
                            label: classes.label,
                            root: classes.labelRoot
                        }}
                        label={Loc.commission_expenditure}
                        />
                        </div>
                        <div
                            className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                            }
                        >
                        <FormControlLabel
                            control={
                                <Radio
                                checked={data_expenditureType === "other"}
                                onChange={handleChange_type}
                                value="other"
                                name={Loc.other_expenditure}
                                aria-label="other"
                                icon={
                                    <FiberManualRecord
                                    className={classes.radioUnchecked}
                                    />
                                }
                                checkedIcon={
                                    <FiberManualRecord
                                    className={classes.radioChecked}
                                    />
                                }
                                classes={{
                                    checked: classes.radio,
                                    root: classes.radioRoot
                                }}
                                />
                            }
                            classes={{
                                label: classes.label,
                                root: classes.labelRoot
                            }}
                            label={Loc.other_expenditure}
                        />
                        </div>
                        <div
                            className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                            }
                        >
                        <FormControlLabel
                            control={
                                <Radio
                                checked={data_expenditureType === "discount_allowed_sales"}
                                onChange={handleChange_type}
                                value="discount_allowed_sales"
                                name={Loc.discount_allowed_sales}
                                aria-label="discount_allowed_sales"
                                icon={
                                    <FiberManualRecord
                                    className={classes.radioUnchecked}
                                    />
                                }
                                checkedIcon={
                                    <FiberManualRecord
                                    className={classes.radioChecked}
                                    />
                                }
                                classes={{
                                    checked: classes.radio,
                                    root: classes.radioRoot
                                }}
                                />
                            }
                            classes={{
                                label: classes.label,
                                root: classes.labelRoot
                            }}
                            label={Loc.discount_allowed_sales}
                        />
                        </div>

                        <div
                            className={
                            classes.checkboxAndRadio +
                            " " +
                            classes.checkboxAndRadioHorizontal
                            }
                        >
                        <FormControlLabel
                            control={
                                <Radio
                                checked={data_expenditureType === "discount_allowed_prepaid"}
                                onChange={handleChange_type}
                                value="discount_allowed_prepaid"
                                name={Loc.discount_allowed_prepaid}
                                aria-label="discount_allowed_prepaid"
                                icon={
                                    <FiberManualRecord
                                    className={classes.radioUnchecked}
                                    />
                                }
                                checkedIcon={
                                    <FiberManualRecord
                                    className={classes.radioChecked}
                                    />
                                }
                                classes={{
                                    checked: classes.radio,
                                    root: classes.radioRoot
                                }}
                                />
                            }
                            classes={{
                                label: classes.label,
                                root: classes.labelRoot
                            }}
                            label={Loc.discount_allowed_prepaid}
                        />
                        </div>
                    </GridItem>
                            <GridItem xs={12}>
                            <FormControlLabel
            control={
              <SwitchUI
                checked={isConfidential}
                onChange={() => {
                  setIsConfidential(!isConfidential)
                  // handleSwitchConfig(r.key,configList,allConfigList);
                }}
                // value={FALSE}
                classes={{
                  switchBase: classes_switch.switchBase,
                  checked: classes_switch.switchChecked,
                  thumb: classes_switch.switchIcon,
                  track: classes_switch.switchBar,
                }}
              />
            }
            classes={{
              label: classes.label,
            }}
            label={Loc.confidential}
          />
                            </GridItem>
                    <GridItem xs={12}>
                    <CustomInput
                        labelText={Loc.remark}
                        id="remark"
                        formControlProps={{
                        fullWidth: true
                        }}
                        inputProps={{
                            value: data_remark,
                            onChange: event => {
                                setData_remark(event.target.value);
                            },
                            type: "text",
                        }}
                    />
                    </GridItem>                 

                </GridContainer>
                
                </form>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
                <GridContainer spacing={2}
                direction="row"
                justify="center"
                alignItems="center">
                    <GridItem xs={6} align="center">
                        <Button onClick={backClicked}>
                        <ICON_BACK />
                        {Loc.back}
                        </Button>
                    </GridItem>

                    <GridItem xs={6} align="center">
                        <Button color="rose" onClick={doneClicked} disabled={fetchingStatus==="fetching" ? true : false}>
                        <ICON_DONE />
                        {isEdit ? Loc.save : Loc.create}
                        </Button>
                    </GridItem>
                </GridContainer>
            </CardFooter>
            </Card>
        </GridItem>
        </GridContainer>
    </div>
  );
}