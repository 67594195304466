import Loc from "localization";

import ICON_ADD from "@material-ui/icons/Add";
import ICON_STAFFLIST from "@material-ui/icons/ListAlt";
import ICON_SALES from "@material-ui/icons/TrendingUp";
import ICON_CUSTOMER_NEW from "@material-ui/icons/PersonAdd";
import ICON_CUSTOMER_REPAYMENT from "@material-ui/icons/AttachMoney";
import ICON_SALES_LIST from '@material-ui/icons/Receipt';
import ICON_SEARCH from '@material-ui/icons/Search';

const dashboardPermissionData = [
  {
    path: "/segment-new-no-sdp",
    name: "切詞-V1",
    color: "success",
    icon: ICON_SALES_LIST,
    type:"shortcut",
    stateType: "type_1"
  },
    {
      path: "/segment-new",
      name: "切詞-V2",
      color: "success",
      icon: ICON_SALES_LIST,
      type:"shortcut",
      stateType: "type_1"
  },
    
];

export default dashboardPermissionData;