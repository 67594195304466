/*eslint-disable*/
import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import Checkbox from "@material-ui/core/Checkbox";
import InputAdornment from "@material-ui/core/InputAdornment";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Dropdown from "views/CommonUseComponents/Dropdown.js";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";

// material ui icons
import MailOutline from "@material-ui/icons/MailOutline";
import Contacts from "@material-ui/icons/Contacts";
import Check from "@material-ui/icons/Check";
import Close from "@material-ui/icons/Close";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import IconButton from "@material-ui/core/IconButton";
import SwitchUI from "@material-ui/core/Switch";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardText from "components/Card/CardText.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

// style for this view
import styles from "assets/jss/material-dashboard-pro-react/views/validationFormsStyle.js";
import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import ImageUpload from "components/CustomUpload/ImageUpload.js";

import ICON_BACK from "@material-ui/icons/ArrowBackIos";
import ICON_DONE from "@material-ui/icons/Done";

const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";
import ICON_ADD from "@material-ui/icons/Add";
import ICON_DELETE from "@material-ui/icons/Clear";
import ICON_UP from "@material-ui/icons/ExpandLess";
import styles_switch from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";
import DataSetType from "Data_set_type";

export default function Product_add(props) {
  // type validation

  const [isEdit, setIsEdit] = React.useState(false);

  const [data_id, setData_id] = React.useState("");
  const [data_name, setData_itemName] = React.useState("");

  //--- "Field Name" "Field ID"
  const [data_createFieldList, setData_createFieldList] = React.useState([]);
  //---
  const [data_UILayout, setData_UILayout] = React.useState([]);
  const [data_localizationKey, setData_localizationKey] = React.useState("");
  const [data_collectionName, setData_collectionName] = React.useState("");

  const [data_setDataTypeList, setData_setDataTypeList] = React.useState(null);
  const [data_setDataTypeID, setData_setDataTypeID] = React.useState("");
  const [
    data_setDataTypeListCount,
    setData_setDataTypeListCount,
  ] = React.useState(0);
  const [data_customizedTable, setData_customizedTable] = React.useState([]);

  const [data_shortTerm, setData_shortTerm] = React.useState("");
  const [data_pathName, setData_pathName] = React.useState("");
  const [data_pathNameEdited, setData_pathNameEdited] = React.useState(false);
  const [data_orderBy,setData_orderBy] = React.useState("");
  const [data_orders,setData_orders] = React.useState("");
  const [data_viewWidth,setData_viewWidth] = React.useState(12);
  const [data_tableOrder,setData_tableOrder] = React.useState(0);
  

  const [isLoading, set_isLoading] = React.useState(false);
  const [data_page, set_page] = React.useState(0);
  const [data_pageCount, set_pageCount] = React.useState(50);

  const [pathToRedirect, setRedirect] = React.useState("");

  const [localizationKeyState, setLocalizationKeyState] = React.useState(false);
  const [collectionNameState, setCollectionNameState] = React.useState(false);

  const [showExcelImport, set_showExcelImport] = React.useState(false);
  const [showExcelExport, set_showExcelExport] = React.useState(false);

  const [shortTermState, setShortTermState] = React.useState(false);
  const [pathNameState, setPathNameState] = React.useState(false);
  const [orderByState, setOrderByState] = React.useState(false);
  const [viewWidthStatus, setViewWidthState] = React.useState(false);
  const [tableOrderStatus, setTableOrderStatus] = React.useState(false);
  
  

  const [fetchingStatus, setFetchingStatus] = React.useState("");
  const [historyState, setHistoryState] = React.useState(null);
  // alert
  const [alert_save, setSaveAlert] = React.useState(null);
  const hideAlert = () => {
    setSaveAlert(null);
  };

  const useStyles_switch = makeStyles(styles_switch);
  const classes_switch = useStyles_switch();

  React.useEffect(() => {
    setHistoryState(props.history.location.state);
    // pre load data for edit
    if (props.location.state.data != null) {
      var dataToEdit = props.location.state.data;
      console.log(`dataToEdit: ${JSON.stringify(dataToEdit)}`)
      setIsEdit(true);
      setData_id(dataToEdit.id);
      setData_itemName(dataToEdit.itemName);
      setData_localizationKey(dataToEdit.localizationKey);
      setData_collectionName(dataToEdit.collectionName);
      setData_shortTerm(dataToEdit.shortTerm);
      setData_pathName(dataToEdit.pathName);
      setData_orderBy(dataToEdit.orderBy);
      setData_orders(dataToEdit.orders);
      setData_viewWidth(dataToEdit.viewWidth);
      setData_tableOrder(dataToEdit.tableOrder);
      setData_setDataTypeID(dataToEdit.setDataManagementTypeID);
      setData_createFieldList(dataToEdit.createFieldList);
      set_showExcelExport(dataToEdit.showExcelExport);
      set_showExcelImport(dataToEdit.showExcelImport);
    }

    getSetDataManagementTypeConfigList();
    process_UI_Layout(data_createFieldList);
    // Specify how to clean up after this effect:
    return function cleanup() {};
  }, []);

  function process_UI_Layout(_data_createFieldList) {
    const new_layout = _renderUILayout(_data_createFieldList);
    setData_UILayout(new_layout);
  }

  function getSetDataManagementTypeConfigList() {
    set_isLoading(true);
    const body = {
      page: data_page,
      pageCount: data_pageCount,
    };

    fetchAPI
      .do_fetch("post", "admin/list-set-data-management-type-config", body)
      .then(
        (res) => {
          // set_isLoading(false);
          getCustomizedTableList();
          console.log("success: ", res.data);
          setData_setDataTypeList(res.data.data_list);
          setData_setDataTypeListCount(res.data.count);
        },
        (error) => {
          console.log("failed: ", error);
          set_isLoading(false);
          showAlert(false, Loc.failed, Loc.data_fetch_failed);
        }
      );
  }

  function getCustomizedTableList() {
    const body = {
      data_targetCollection: "SetDataManagementConfig",
    };

    fetchAPI.do_fetch("post", "admin/list-all", body).then(
      (res) => {
        set_isLoading(false);
        console.log("success: ", res.data);
        setData_customizedTable(res.data.data_list);
      },
      (error) => {
        console.log("failed: ", error);
        set_isLoading(false);
        showAlert(false, Loc.failed, Loc.data_fetch_failed);
      }
    );
  }

  const validCheck = () => {
    var result = true;
    if (
      data_localizationKey == "" ||
      data_collectionName == "" ||
      data_setDataTypeID == "" ||
      data_shortTerm == "" ||
      data_pathName == "" ||
      !checkCreateFieldList()
    ) {
      result = false;
    }
    return result;
  };

  const backClicked = () => {
    setRedirect("/admin/data-set-management-list");
  };

  const doneClicked = () => {
    setFetchingStatus("fetching");
    if (validCheck()) {
      const idToPass = isEdit ? data_id : null;
      console.log("idToPass: ", idToPass);
      const body = {
        id: idToPass,
        content: {
          localizationKey: data_localizationKey,
          collectionName: data_collectionName,
          shortTerm: data_shortTerm,
          pathName: data_pathName,
          setDataManagementTypeID: data_setDataTypeID,
          orderBy:data_orderBy,
          orders:data_orders,
          viewWidth: data_viewWidth,
          tableOrder: data_tableOrder,

          createFieldList: data_createFieldList,
          showExcelImport: showExcelImport,
          showExcelExport: showExcelExport,
        },
      };
      fetchAPI
        .do_fetch("post", "admin/create-set-data-management-config", body)
        .then(
          (res) => {
            console.log("success: ", res);
            setFetchingStatus("");
            showAlert(true, Loc.success, Loc.data_save_success);
            // setData_itemName("");
          },
          (error) => {
            console.log("failed: ", error);
            setFetchingStatus("");
            showAlert(false, Loc.failed, Loc.data_save_failed);
          }
        );
    } else {
      if (data_collectionName === "") {
        setCollectionNameState(true);
      }
      if (data_localizationKey === "") {
        setLocalizationKeyState(true);
      }
      if (data_shortTerm === "") {
        setShortTermState(true);
      }
      if (data_pathName === "") {
        setPathNameState(true);
      }
      if(data_orderBy === ""){ setOrderByState(true)}
      if(data_orders === ""){ setOrdersState(true)}

      setFetchingStatus("");
      showAlert(false, Loc.failed, Loc.data_save_failed);
    }
  };

  function checkCreateFieldList() {
    const configList = data_createFieldList;
    if (configList.length === 0) {
      return false;
    } else {
      var isValid = true;
      configList.map((item) => {
        if (item.fieldType !== "MC") {
          if (item.fieldKey === "" || item.fieldName === "") {
            isValid = false;
          }
        } else {
          var detailFieldList = item.detailFieldList;
          if (detailFieldList.length > 0) {
            detailFieldList.map((detailItem) => {
              console.log(
                "detailItemdetailItemdetailItem  " + JSON.stringify(detailItem)
              );
              if (detailItem.key === "" || detailItem.value === "") {
                isValid = false;
              }
            });
          } else {
            isValid = false;
          }
        }
      });
      return isValid;
    }
  }

  function showAlert(issuccess, title, content) {
    setSaveAlert(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => {
          if (issuccess) {
            // hideAlert()
            setRedirect("/admin/data-set-management-list");
          } else {
            hideAlert();
          }
        }}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.info}
        onCancel={() => {
          if (issuccess) {
            setData_itemName("");

            setData_id("");

            setData_localizationKey("");
            setData_collectionName("");
            setData_shortTerm("");
            setData_pathName("");
            setData_setDataTypeID("");
            set_showExcelExport(false);
            set_showExcelImport(false);
            setData_createFieldList([]);
            setData_orderBy("createDate");
            setData_orders("desc");
            setData_viewWidth(12);
            hideAlert();
          } else {
            hideAlert();
          }
        }}
        confirmBtnText={issuccess ? Loc.done : Loc.confirm}
        cancelBtnText={Loc.continue}
        btnSize="lg"
        showCancel={issuccess && !isEdit}
      >
        {content}
      </SweetAlert>
    );
  }

  const handleChange_gender = (event) => {
    setData_gender(event.target.value);
  };

  const check_redirect = () => {
    if (pathToRedirect != "") {
      return (
        <Redirect
          to={{
            pathname: pathToRedirect,
            state: historyState.config,
          }}
        />
      );
    } else {
      return null;
    }
  };

  const imageDidUpload = (pathInStorage) => {
    if (pathInStorage != null) {
      setData_imageUrl(pathInStorage);
      console.log("imageDidUpload called, path: ", pathInStorage);
    }
  };

  function _renderUILayout(_data_createFieldList) {
    return (
      <>
        {" "}
        {/* {Collection name: (inputview)} */}
        <GridItem xs={3}>
          <CustomInput
            // success={requiredState === "success"}
            error={localizationKeyState}
            labelText={"Localization Key" + "*"}
            id="localization_key"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: data_localizationKey,
              onChange: (event) => {
                setLocalizationKeyState(false);
                setData_localizationKey(event.target.value);
              },
              type: "text",
            }}
          />
        </GridItem>
        <GridItem xs={3}>
          <CustomInput
            // success={requiredState === "success"}
            error={collectionNameState}
            labelText={"Collection Name" + "*"}
            id="collection_name"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: data_collectionName,
              onChange: (event) => {
                setCollectionNameState(false);
                setData_collectionName(event.target.value);
                if (!data_pathNameEdited) {
                  setData_pathName(event.target.value);
                }
              },
              type: "text",
            }}
          />
        </GridItem>
        
        <GridItem xs={3}>
          <CustomInput
            // success={requiredState === "success"}
            error={pathNameState}
            labelText={"Path" + "*"}
            id="path"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: data_pathName,
              onChange: (event) => {
                setPathNameState(false);
                if (!data_pathNameEdited) {
                  setData_pathNameEdited(true);
                }
                setData_pathName(event.target.value);
              },
              type: "text",
            }}
          />
        </GridItem>
        <GridItem xs={3}>
          <CustomInput
            // success={requiredState === "success"}
            error={shortTermState}
            labelText={"Short term" + "*"}
            id="short_term"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: data_shortTerm,
              onChange: (event) => {
                setShortTermState(false);
                setData_shortTerm(event.target.value);
              },
              type: "text",
            }}
          />
        </GridItem>
        <GridItem xs={3}>
          <GridContainer>
            <GridItem xs={6}>
              <CustomInput
                // success={requiredState === "success"}
                error={orderByState}
                labelText={"Order By" + "*"}
                id="orderBy"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: data_orderBy,
                  onChange: (event) => {
                    setOrderByState(false);
                    setData_orderBy(event.target.value);
                  },
                  type: "text",
                }}
              />
            </GridItem>
            
            <GridItem xs={6}>
              <CustomDropdown
                buttonProps={{
                  round: true,
                  color: "info",
                }}
                buttonText={
                  <span>
                    {data_orders}
                  </span>
                }
                dropdownList={["desc", "asc"]}
                itemIDList={["desc", "asc"]}
                onClick={(selected_orders) => {
                  setData_orders(selected_orders);
                  console.log(`selected orders  ${selected_orders}`);
                }}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
        
        <GridItem xs={3}>
          <GridContainer>
            <GridItem xs={6}>
              <CustomInput
              // success={requiredState === "success"}
              error={viewWidthStatus}
              labelText={"View Width" + "*"}
              id="viewWidth"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                value: data_viewWidth,
                onChange: (event) => {
                  setViewWidthState(false);
                  setData_viewWidth(event.target.value);
                },
                type: "number",
              }}
            />
            </GridItem>
            <GridItem xs={6}>
              <CustomInput
                error={tableOrderStatus}
                labelText={"Table Order" + "*"}
                id="tableOrder"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  value: data_tableOrder,
                  onChange: (event) => {
                    setTableOrderStatus(false);
                    setData_tableOrder(event.target.value);
                  },
                  type: "number",
                }}
              />
            </GridItem>
          </GridContainer>
        </GridItem>
        <GridItem xs={3}>
          <GridContainer>
            <GridItem xs={6}>
            <FormControlLabel
              control={
                <SwitchUI
                  checked={showExcelImport || false}
                  onChange={() => {
                    console.log("show_excel_import  " + showExcelImport);
                    set_showExcelImport(!showExcelImport);
                  }}
                  // value={FALSE}
                  classes={{
                    switchBase: classes_switch.switchBase,
                    checked: classes_switch.switchChecked,
                    thumb: classes_switch.switchIcon,
                    track: classes_switch.switchBar,
                  }}
                />
              }
              classes={{
                label: classes.label,
              }}
              label={Loc.show_excel_import}
            />
          </GridItem>
          <GridItem xs={6}>
            <FormControlLabel
              control={
                <SwitchUI
                  checked={showExcelExport}
                  onChange={() => {
                    set_showExcelExport(!showExcelExport);
                  }}
                  // value={FALSE}
                  classes={{
                    switchBase: classes_switch.switchBase,
                    checked: classes_switch.switchChecked,
                    thumb: classes_switch.switchIcon,
                    track: classes_switch.switchBar,
                  }}
                />
              }
              classes={{
                label: classes.label,
              }}
              label={Loc.show_excel_export}
            />
          </GridItem>
          </GridContainer>
        </GridItem>
        
        {_renderSetDataTypeDrowdown()}
        {_renderTableCardView()}
      </>
    );
  }

  function _renderSetDataTypeDrowdown() {
    if (data_setDataTypeList === null) {
      return null;
    }
    // console.log(`_renderSetDataTypeDrowdown    ${JSON.stringify(data_setDataTypeList)}`)

    var fieldNameList = [];
    var fieldIDList = [];

    if (data_setDataTypeList.length > 0) {
      data_setDataTypeList.map((item) => {
        fieldNameList.push(item.setDataName);
        fieldIDList.push(item.id);
      });
    }

    //default ID
    if (
      data_setDataTypeList !== null &&
      data_setDataTypeList.length > 0 &&
      data_setDataTypeID == ""
    ) {
      const setDataType = data_setDataTypeList[0];
      setData_setDataTypeID(setDataType.id);
    }

    return (
      <GridItem xs={12}>
        <CustomDropdown
          buttonProps={{
            round: true,
            color: "info",
          }}
          buttonText={
            <span>
              {getSetDataStringToShow(fieldNameList, data_setDataTypeList)}
            </span>
          }
          dropdownList={fieldNameList}
          itemIDList={fieldIDList}
          onClick={(selectedID) => {
            setData_setDataTypeID(selectedID);
            // console.log(`selectedID  ${selectedID}`);
          }}
        />
      </GridItem>
    );
  }

  const getSetDataStringToShow = (fieldNameList, fieldList) => {
    var strToShow = fieldNameList[0];
    fieldList.map((prop, key) => {
      if (prop.id === data_setDataTypeID) {
        strToShow = prop.setDataName;
      }
    });
    return strToShow;
  };

  const getStringToShow = (
    _data_createFieldList,
    fieldList,
    fieldNameList,
    index
  ) => {
    var strToShow = fieldNameList[0];
    const createFieldListData = _data_createFieldList[index];
    fieldList.map((prop, key) => {
      if (prop.id === createFieldListData["fieldType"]) {
        strToShow = prop.itemName;
      }
    });

    return strToShow;
  };

  function _renderTableDetailCardView(index) {
    var createFieldList = data_createFieldList;
    var data = createFieldList[index];

    var detailFieldList = data.detailFieldList;
    //detail field list should be get from create
    const ui_gridItems = detailFieldList.map((item, _index) => {
      return _renderTableDetailCardRow(
        data_createFieldList,
        detailFieldList,
        index,
        _index
      );
    });

    return (
      <Card>
        <CardBody>
          <GridContainer>{ui_gridItems}</GridContainer>
          <Button
            color="success"
            round
            onClick={() => {
              detailFieldList.push({ key: "", value: "" });
              data.detailFieldList = detailFieldList;
              setData_createFieldList(createFieldList);
              // updateCustomInputStates()
              process_UI_Layout(createFieldList);
            }}
          >
            <ICON_ADD />
          </Button>
        </CardBody>
      </Card>
    );
  }

  function _renderTableDetailCardRow(
    _data_createFieldList,
    _data_detailFieldList,
    createFieldIndex,
    index
  ) {
    var data = _data_detailFieldList[index];
    return (
      <>
        <GridItem xs={5}>
          <CustomInput
            // success={requiredState === "success"}
            // error={productNameState === "error"}
            labelText={"Key" + "*"}
            id="field_key"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: data.key,
              onChange: (event) => {
                data.key = event.target.value;
                setData_createFieldList(_data_createFieldList);
                process_UI_Layout(_data_createFieldList);
              },
              type: "text",
            }}
          />
        </GridItem>
        <GridItem xs={5}>
          <CustomInput
            // success={requiredState === "success"}
            // error={productNameState === "error"}
            labelText={"Value" + "*"}
            id="field_value"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: data.value,
              onChange: (event) => {
                data.value = event.target.value;
                setData_createFieldList(_data_createFieldList);
                process_UI_Layout(_data_createFieldList);
                // console.log(`_data_createFieldList  ${JSON.stringify(_data_createFieldList)}`)
              },
              type: "text",
            }}
          />
        </GridItem>
        <GridItem xs={2}>
          <IconButton
            aria-label="delete"
            color="secondary"
            onClick={() => {
              removeTableDetailCardRow(
                index,
                createFieldIndex,
                _data_createFieldList
              );
            }}
          >
            <ICON_DELETE />
          </IconButton>
        </GridItem>
      </>
    );
  }

  function removeTableDetailCardRow(
    index,
    createFieldIndex,
    _data_createFieldList
  ) {
    _data_createFieldList[createFieldIndex].detailFieldList.splice(index, 1);
    setData_createFieldList(_data_createFieldList);
    process_UI_Layout(_data_createFieldList);
  }

  function _renderTableCardView() {
    const fieldList = [
      { itemName: Loc.string, id: DataSetType.type_string },
      { itemName: Loc.number, id: DataSetType.type_number },
      { itemName: Loc.bool, id: DataSetType.type_bool },
      { itemName: Loc.multi_selection, id: DataSetType.type_MC },
      { itemName: Loc.date, id: DataSetType.type_date },
      { itemName: { divider: true }, id: "" },
      { itemName: Loc.account_phone, id: DataSetType.type_account_phone },
      { itemName: Loc.account_email, id: DataSetType.type_account_email },
      { itemName: Loc.account_password, id: DataSetType.type_account_password },
      {
        itemName: Loc.account_password_confirm,
        id: DataSetType.type_account_password_confirm,
      },
      { itemName: { divider: true }, id: "" },
      {
        itemName: Loc.upload_image_single,
        id: DataSetType.type_upload_image_single,
      },
      // { itemName: Loc.upload_image_multi, id: DataSetType.type_upload_image_multi },
      { itemName: { divider: true }, id: "" },
      {
        itemName: Loc.select_from_dataset_single,
        id: DataSetType.type_select_from_other_dataset_single,
      },
      {
        itemName: Loc.select_from_dataset_multi,
        id: DataSetType.type_select_from_other_dataset,
      },
      { itemName: Loc.type_list, id: DataSetType.type_list },
      {
        itemName: Loc.multiline_text,
        id: DataSetType.type_multiline_text,
      },
      {
        itemName: Loc.dragdrop_upload,
        id: DataSetType.type_dragdrop_upload,
      },
      { itemName: Loc.md_editor, id: DataSetType.type_md_editor },
      { itemName: { divider: true }, id: "" },
      { itemName: Loc.type_firebase_geopoint, id: DataSetType.type_firebase_geopoint },
    ];

    const fieldNameList = [];
    const fieldIDList = [];

    fieldList.map((item) => {
      fieldNameList.push(item.itemName);
      fieldIDList.push(item.id);
    });

    var createFieldList = data_createFieldList;

    const ui_gridItems = createFieldList.map((item, index) => {
      return _renderTableCardRow(
        data_createFieldList,
        fieldList,
        fieldNameList,
        fieldIDList,
        index
      );
    });

    return (
      <Card>
        <CardBody>
          <GridContainer>{ui_gridItems}</GridContainer>
          <Button
            color="success"
            round
            onClick={() => {
              createFieldList.push({
                fieldKey: "",
                fieldName: "",
                targetListKey: "",
                fieldType: "string",
                detailFieldList: [],
                isShowInTableView: false,
                mandatory: false,
                isUnique: false,
              });

              setData_createFieldList(createFieldList);
              process_UI_Layout(createFieldList);
              console.log("click Add ");
            }}
          >
            <ICON_ADD />
          </Button>
        </CardBody>
      </Card>
    );
  }

  function render_dragDropRelatedView(fieldType, val, handleOnChange)
  {
    if (fieldType === DataSetType.type_dragdrop_upload)
    {
      return (
        <GridItem md={3}>
          <CustomInput
            // success={requiredState === "success"}
            // error={productNameState === "error"}
            labelText={"Target list key"}
            id="target_list_key"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: val,
              onChange: handleOnChange,
              type: "text",
            }}
          />
        </GridItem>
      )
    }
    else
    {
      return (<GridItem md={3}></GridItem>);
    }
  }

  function _renderTableCardRow(
    _data_createFieldList,
    fieldList,
    fieldNameList,
    fieldIDList,
    index
  ) {
    const data = _data_createFieldList[index];
    var isShowCardView = data.fieldType == DataSetType.type_MC ? true : false;
    var isShowSelectFromOtherTable =
      (data.fieldType == DataSetType.type_select_from_other_dataset) || (data.fieldType == DataSetType.type_select_from_other_dataset_single);
    return (
      <GridItem xs={12} key={index.toString()}>
        <GridContainer>
          <GridItem lg={4}>
            <GridContainer>
              <GridItem md={3}>
                <CustomInput
                  // success={requiredState === "success"}
                  // error={productNameState === "error"}
                  labelText={"Field Key" + "*"}
                  id="field_key"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: data.fieldKey,
                    onChange: (event) => {
                      data.fieldKey = event.target.value;
                      setData_createFieldList(_data_createFieldList);

                      process_UI_Layout(_data_createFieldList);
                      console.log(
                        `_data_createFieldList  ${JSON.stringify(
                          _data_createFieldList
                        )}`
                      );
                    },
                    type: "text",
                  }}
                />
              </GridItem>
              <GridItem md={3}>
                <CustomInput
                  // success={requiredState === "success"}
                  // error={productNameState === "error"}
                  labelText={"Field Name" + "*"}
                  id="field_name"
                  formControlProps={{
                    fullWidth: true,
                  }}
                  inputProps={{
                    value: data.fieldName,
                    onChange: (event) => {
                      data.fieldName = event.target.value;
                      setData_createFieldList(_data_createFieldList);
                      process_UI_Layout(_data_createFieldList);
                      // console.log(`_data_createFieldList  ${JSON.stringify(_data_createFieldList)}`)
                    },
                    type: "text",
                  }}
                />
              </GridItem>
              {render_dragDropRelatedView(data.fieldType, data.targetListKey, (event) => {
                data.targetListKey = event.target.value;
                setData_createFieldList(_data_createFieldList);
                process_UI_Layout(_data_createFieldList);
                // console.log(`_data_createFieldList  ${JSON.stringify(_data_createFieldList)}`)
              })}
              <GridItem md={3}>
                <CustomDropdown
                  buttonProps={{
                    round: true,
                    color: "info",
                  }}
                  buttonText={
                    <span>
                      {getStringToShow(
                        _data_createFieldList,
                        fieldList,
                        fieldNameList,
                        index
                      )}
                    </span>
                  }
                  dropdownList={fieldNameList}
                  itemIDList={fieldIDList}
                  onClick={(selectedID) => {
                    if (
                      selectedID === DataSetType.type_account_email ||
                      selectedID === DataSetType.type_account_password ||
                      selectedID === DataSetType.type_account_password_confirm ||
                      selectedID === DataSetType.type_upload_image_single ||
                      selectedID === DataSetType.type_upload_image_multi
                    ) {
                      // check if already in  field list
                      var contain_account_email = false;
                      var contain_account_password = false;
                      var contain_account_password_confirm = false;
                      var contain_uploadImage_single = false;
                      var contain_uploadImage_multi = false;
                      _data_createFieldList.map((d) => {
                        if (d.fieldType === DataSetType.type_account_email) {
                          contain_account_email = true;
                        }
                        if (d.fieldType === DataSetType.type_account_password) {
                          contain_account_password = true;
                        }
                        if (
                          d.fieldType === DataSetType.type_account_password_confirm
                        ) {
                          contain_account_password_confirm = true;
                        }
                        if (d.fieldType === DataSetType.type_upload_image_single) {
                          contain_uploadImage_single = true;
                        }
                        if (d.fieldType === DataSetType.type_upload_image_multi) {
                          contain_uploadImage_multi = true;
                        }
                      });

                      // already contain
                      if (
                        (contain_account_email &&
                          selectedID === DataSetType.type_account_email &&
                          selectedID !== data.fieldType) ||
                        (contain_account_password &&
                          selectedID === DataSetType.type_account_password &&
                          selectedID !== data.fieldType) ||
                        (contain_account_password_confirm &&
                          selectedID ===
                            DataSetType.type_account_password_confirm &&
                          selectedID !== data.fieldType) ||
                        (contain_uploadImage_single &&
                          selectedID === DataSetType.type_upload_image_single &&
                          selectedID !== data.fieldType) ||
                        (contain_uploadImage_multi &&
                          selectedID === DataSetType.type_upload_image_multi &&
                          selectedID !== data.fieldType)
                      ) {
                        showAlert(false, Loc.failed, Loc.data_set_type_duplicated);
                      }
                      // not contain pw but select pw_confirm
                      else if (
                        (!contain_account_password ||
                          data.fieldType === DataSetType.type_account_password) &&
                        selectedID === DataSetType.type_account_password_confirm
                      ) {
                        showAlert(
                          false,
                          Loc.failed,
                          Loc.data_set_type_password_missing
                        );
                      } else {
                        data.fieldType = selectedID;
                        setData_createFieldList(_data_createFieldList);
                        process_UI_Layout(_data_createFieldList);
                      }
                    } else {
                      data.fieldType = selectedID;
                      setData_createFieldList(_data_createFieldList);
                      process_UI_Layout(_data_createFieldList);
                    }
                  }}
                />
              </GridItem>
              
            </GridContainer>
          </GridItem>
          
          <GridItem lg={8}>
            <GridContainer>
              <GridItem md={2}></GridItem>
              <GridItem md={2}>
                <div align="right">
                  <FormControlLabel
                    control={
                      <SwitchUI
                        checked={
                          data.fieldType === DataSetType.type_account_password ||
                          data.fieldType === DataSetType.type_account_password_confirm
                            ? false 
                            : data.isReadOnly}
                        onChange={
                          (event) => {
                            data.isReadOnly = event.target.checked;
                            setData_createFieldList(_data_createFieldList);
                            process_UI_Layout(_data_createFieldList);
                          }
                        }
                        disabled={
                          data.fieldType === DataSetType.type_account_password ||
                          data.fieldType === DataSetType.type_account_password_confirm
                          ? true
                          : false
                        }
                        classes={{
                          switchBase: classes_switch.switchBase,
                          checked: classes_switch.switchChecked,
                          thumb: classes_switch.switchIcon,
                          track: classes_switch.switchBar,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                    }}
                    label={Loc.isReadOnly}
                  />
                </div>
              </GridItem>
              <GridItem md={2}>
                <div align="right">
                  <FormControlLabel
                    control={
                      <SwitchUI
                        checked={
                          data.fieldType === DataSetType.type_account_phone ||
                          data.fieldType === DataSetType.type_account_email ||
                          data.fieldType === DataSetType.type_account_password ||
                          data.fieldType === DataSetType.type_account_password_confirm
                            ? true
                            : data.isReadOnly ? false : (data.mandatory || false)
                        }
                        onChange={
                          (event) => {
                            data.mandatory = event.target.checked;
                            setData_createFieldList(_data_createFieldList);
                            process_UI_Layout(_data_createFieldList);
                          }
                          // setData_hasDuration()
                        }
                        disabled={
                          data.fieldType === DataSetType.type_account_phone ||
                          data.fieldType === DataSetType.type_account_email ||
                          data.fieldType === DataSetType.type_account_password ||
                          data.fieldType === DataSetType.type_account_password_confirm ||
                          data.isReadOnly
                            ? true
                            : false
                        }
                        classes={{
                          switchBase: classes_switch.switchBase,
                          checked: classes_switch.switchChecked,
                          thumb: classes_switch.switchIcon,
                          track: classes_switch.switchBar,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                    }}
                    label={Loc.mandatory}
                  />
                </div>
              </GridItem>
              <GridItem md={2}>
                <div align="right">
                  <FormControlLabel
                    control={
                      <SwitchUI
                        checked={
                          data.fieldType === DataSetType.type_upload_image_single ||
                          data.fieldType === DataSetType.type_upload_image_multi ||
                          data.fieldType === DataSetType.type_account_password ||
                          data.fieldType === DataSetType.type_account_password_confirm
                            ? false
                            : data.isShowInTableView || false
                        }
                        onChange={(event) => {
                          data.isShowInTableView = event.target.checked;
                          setData_createFieldList(_data_createFieldList);
                          process_UI_Layout(_data_createFieldList);
                        }}
                        disabled={
                          data.fieldType === DataSetType.type_upload_image_single ||
                          data.fieldType === DataSetType.type_upload_image_multi ||
                          data.fieldType === DataSetType.type_account_password ||
                          data.fieldType === DataSetType.type_account_password_confirm ||
                          data.fieldType === DataSetType.type_select_from_other_dataset
                            ? true
                            : false
                        }
                        classes={{
                          switchBase: classes_switch.switchBase,
                          checked: classes_switch.switchChecked,
                          thumb: classes_switch.switchIcon,
                          track: classes_switch.switchBar,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                    }}
                    label={Loc.show_in_table_view}
                  />
                </div>
              </GridItem>
              
              <GridItem md={4}>
                <GridContainer>
                <GridItem xs={3}></GridItem>
                <GridItem xs={3}></GridItem>
                <GridItem xs={3}>
                  <IconButton
                    aria-label="up"
                    color="default"
                    onClick={() => {
                      moveUpTableCardRow(index, _data_createFieldList);
                    }}
                  >
                    <ICON_UP />
                  </IconButton>
                </GridItem>

                <GridItem xs={3}>
                  <IconButton
                    aria-label="delete"
                    color="secondary"
                    onClick={() => {
                      removeTableCardRow(index, _data_createFieldList);
                    }}>
                    <ICON_DELETE />
                  </IconButton>
                </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>

            {isShowCardView ? _renderTableDetailCardView(index) : null}
            {isShowSelectFromOtherTable
              ? _renderSelectFromOtherTable(index)
            : null}
        </GridContainer>
        <hr />
      </GridItem>
    );
  }

  function _renderSelectFromOtherTable(index) {
    var createFieldList = data_createFieldList;
    var data = createFieldList[index];

    return (
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem xs={6}>
              <GridContainer>
                <GridItem xs={6}>
                  <FormControl
                    fullWidth
                    className={
                      classes.selectFormControl +
                      " " +
                      classes.formControlMargins
                    }
                  >
                    <InputLabel
                      htmlFor="simple-select"
                      className={classes.selectLabel}
                    >
                      {"From Table"}
                    </InputLabel>
                    <Select
                      MenuProps={{
                        className: classes.selectMenu,
                      }}
                      classes={{
                        select: classes.select,
                      }}
                      value={data.otherTableID ? data.otherTableID : ""}
                      onChange={(event) => {
                        const selectedID = event.target.value;
                        var selectedTableName = "";
                        var selectedCollectionName = "";
                        data_customizedTable.map((item) => {
                          if (item.id === selectedID) {
                            selectedTableName = item.localizationKey;
                            selectedCollectionName = item.collectionName;
                          }
                        });
                        data.otherTableID = selectedID;
                        data.otherTableCollectionName = selectedCollectionName;
                        data.otherTableName = selectedTableName;
                        setData_createFieldList(createFieldList);
                        process_UI_Layout(createFieldList);
                      }}
                      inputProps={{
                        name: data.otherTableName,
                        id: data.otherTableID,
                      }}
                    >
                      {getSelectMenuItem()}
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={6}>
                  <CustomInput
                    // success={requiredState === "success"}
                    // error={productNameState === "error"}
                    labelText={"Show Field Name" + "*"}
                    id="show_field_name"
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      value: data.otherTableShowFieldName
                        ? data.otherTableShowFieldName
                        : "",
                      onChange: (event) => {
                        data.otherTableShowFieldName = event.target.value;
                        setData_createFieldList(createFieldList);
                        process_UI_Layout(createFieldList);
                      },
                      type: "text",
                    }}
                  />
                </GridItem>
              </GridContainer>
            </GridItem>
            <GridItem xs={6}></GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    );
  }

  const getSelectMenuItem = () => {
    const menuItems = [];

    if (data_customizedTable && data_customizedTable.length > 0) {
      data_customizedTable.map((item, key) => {
        // console.log("getSelectMenuItem item.id:" + item.id);
        menuItems.push(
          <MenuItem
            key={key}
            classes={{
              root: classes.selectMenuItem,
              selected: classes.selectMenuItemSelected,
            }}
            value={item.id}
          >
            {item.localizationKey}
          </MenuItem>
        );
      });
    }

    return menuItems;
  };

  function moveUpTableCardRow(index, _data_createFieldList) {
    if (index !== 0) {
      // get item
      const targetSwapItem = _data_createFieldList[index - 1];

      // do swap
      _data_createFieldList[index - 1] = _data_createFieldList[index];
      _data_createFieldList[index] = targetSwapItem;

      // save and update
      setData_createFieldList(_data_createFieldList);
      process_UI_Layout(_data_createFieldList);
    }
  }

  function removeTableCardRow(index, _data_createFieldList) {
    const isPwType =
      _data_createFieldList[index].fieldType ===
      DataSetType.type_account_password;
    // remove field
    _data_createFieldList.splice(index, 1);
    // remove related field
    if (isPwType) {
      // check if contain pw_confirm
      var indexOfRelatedField = -1;
      for (var i = 0; i < _data_createFieldList.length; i++) {
        var d = _data_createFieldList[i];
        if (d.fieldType === DataSetType.type_account_password_confirm) {
          indexOfRelatedField = i;
          break;
        }
      }

      if (indexOfRelatedField !== -1) {
        _data_createFieldList.splice(indexOfRelatedField, 1);
      }
    }

    setData_createFieldList(_data_createFieldList);
    process_UI_Layout(_data_createFieldList);
  }

  const classes = useStyles();
  const classes_alert = useStyles_alert();

  return (
    <div>
      {check_redirect()}
      {alert_save}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="rose" text>
              <CardText color="rose">
                <h4 className={classes.cardTitle}>{Loc.basic_info}</h4>
              </CardText>
            </CardHeader>
            <CardBody>
              <form>
                <GridContainer>{_renderUILayout()}</GridContainer>
              </form>
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <GridContainer
                spacing={2}
                direction="row"
                justify="center"
                alignItems="center"
              >
                <GridItem xs={6} align="center">
                  <Button onClick={backClicked}>
                    <ICON_BACK />
                    {Loc.back}
                  </Button>
                </GridItem>

                <GridItem xs={6} align="center">
                  <Button
                    color="rose"
                    onClick={doneClicked}
                    disabled={fetchingStatus === "fetching" ? true : false}
                  >
                    <ICON_DONE />
                    {isEdit ? Loc.save : Loc.create}
                  </Button>
                </GridItem>
              </GridContainer>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
