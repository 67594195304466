import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footer/Footer.js";

import {routes, SystemType} from "routes.js";

import styles from "assets/jss/material-dashboard-pro-react/layouts/authStyle.js";

import register from "assets/img/register.jpeg";
import login from "assets/img/login.jpeg";
import lock from "assets/img/lock.jpeg";
import error from "assets/img/clint-mckoy.jpg";
import pricing from "assets/img/bg-pricing.jpeg";
import firebase from 'connectionHandler/firebase';
import fetchAPI from "connectionHandler/FetchAPI.js";
import Loc from "localization";
import SweetAlert from "react-bootstrap-sweetalert";

import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);

export default function AdminAuth(props) {
  const { ...rest } = props;
  // ref for the wrapper div
  const wrapper = React.createRef();
  // auth
  const [isSignedIn, setSignedIn] = React.useState(false);
  // styles
  const classes = useStyles();
  const classes_alert = useStyles_alert();
  // alert
  const [alert,setAlert] = React.useState(null);
  const hideAlert = () => {
    setAlert(null);
  }

  React.useEffect(() => {
    const subscription_auth = add_authListener();
    
    document.body.style.overflow = "unset";
    
    // Specify how to clean up after this effect:
    return function cleanup() {
      // console.log("cleanup called in Auth");
      subscription_auth();
    };
  }, []);
  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/admin-auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getBgImage = () => {
    if (window.location.pathname.indexOf("/auth/login-staff") !== -1) {
      return register;
    } else if (window.location.pathname.indexOf("/admin-auth/login-admin") !== -1) {
      return login;
    } else if (window.location.pathname.indexOf("/auth/pricing-page") !== -1) {
      return pricing;
    } else if (
      window.location.pathname.indexOf("/auth/lock-screen-page") !== -1
    ) {
      return lock;
    } else if (window.location.pathname.indexOf("/auth/error-page") !== -1) {
      return error;
    }
  }; 
  const getActiveRoute = routes => {
    let activeRoute = Loc.app_name;
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };

  const getActiveRoutePath = routes => {
    let activeRoute = "";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].path;
        }
      }
    }
    return activeRoute;
  };

  function add_authListener()
  {
    // console.log("add_authListener called in Auth");
    return firebase.auth().onAuthStateChanged(function(user) {
      if (user) {
        
        // User is signed in.
        // console.log("Checking, signed in");
        firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then(function(idToken) {
          // Send token to your backend via HTTPS
          // ...
          console.log("Auth idToken: ", idToken);
        }).catch(function(error) {
          // Handle error
        });
        

        // get admin info
        const body = {
          "id":firebase.auth().currentUser.uid
        }
        fetchAPI.do_fetch('post', 'admin/get-adminInfo', body)
        .then((res) => {
          setSignedIn(true);
        }, error => {
          firebase.auth().signOut().then(function() {
            console.log("Sign-out successful.");
            showAlert_fail(Loc.loginFail_title, Loc.loginFail_content);
            // Sign-out successful.
            setSignedIn(false);
          }).catch(function(error) {
            console.log("Sign-out fail, ", error);
            // An error happened.
            setSignedIn(false);
          });
        })
      } else {
        setSignedIn(false);
        firebase.auth().signOut().then(function() {
          console.log("Sign-out successful.");
          showAlert_fail(Loc.loginFail_title, Loc.loginFail_content);
          // Sign-out successful.
          setSignedIn(false);
        }).catch(function(error) {
          console.log("Sign-out fail, ", error);
          // An error happened.
          setSignedIn(false);
        });
      }
    });
  }

  function showAlert_fail(title, content){
    setAlert(
      <SweetAlert
        error
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={title}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.info}
        confirmBtnText={Loc.confirm}
        btnSize="lg"
        timeout={2000}
      >
        {content}
      </SweetAlert>
    );
  }
  

  function check_isSignedIn()
  {
    if (isSignedIn)
    {
      return (<Redirect to="/admin" />);
    }
    else
    {
      return (null);
    }
  }

  return (
    <div>
      {check_isSignedIn()}
      <AuthNavbar brandText={getActiveRoute(routes)} {...rest} />
      <div className={classes.wrapper} ref={wrapper}>
        <div
          className={classes.fullPage}
          style={{ backgroundImage: "url(" + getBgImage() + ")" }}
        >
          <Switch>
            {getRoutes(routes)}
            <Redirect from="/admin-auth" to="/admin-auth/login-admin" />
          </Switch>
          <Footer white />
        </div>
      </div>
    </div>
  );
}
