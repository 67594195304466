import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons
import Assignment from "@material-ui/icons/Assignment";
import Dvr from "@material-ui/icons/Edit";
import Favorite from "@material-ui/icons/Favorite";
import Close from "@material-ui/icons/Close";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import ReactTable from "components/ReactTable/ReactTable.js";
import PagingTable from "views/CommonUseComponents/PagingTable.js";
import SearchBar from "views/CommonUseComponents/SearchBar.js";
import Table from "components/Table/Table.js";
import moment from "moment";

import { dataTable } from "variables/general.js";

import { cardTitle } from "assets/jss/material-dashboard-pro-react.js";

import SweetAlert from "react-bootstrap-sweetalert";

import Loc from "localization";
import fetchAPI from "connectionHandler/FetchAPI.js";
import DataSetType from "Data_set_type";

import styles_alert from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import styles_tableBtn from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import styles_checkbox from "assets/jss/material-dashboard-pro-react/customCheckboxRadioSwitch.js";

import ICON_ADD from "@material-ui/icons/Add";
import Recover from "@material-ui/icons/Replay";

import { saveAs } from "file-saver";
import { ExportToCsv } from "export-to-csv";
import Papa from "papaparse/papaparse.min";

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Check from "@material-ui/icons/Check";

const styles = {
  cardIconTitle: {
    ...cardTitle,
    marginTop: "15px",
    marginBottom: "0px",
  },
};

const useStyles = makeStyles(styles);
const useStyles_alert = makeStyles(styles_alert);
const useStyles_tableBtn = makeStyles(styles_tableBtn);
const useStyles_checkbox = makeStyles(styles_checkbox);
export default function Custom_list(props) {
  // alert
  const [alert_info, setAlert_info] = React.useState(null);
  const [alert_confirm, setAlert_confirm] = React.useState(null);
  const [alert_upload, setAlert_upload] = React.useState(null);

  const hideAlert = () => {
    setAlert_info(null);
    setAlert_confirm(null);
    setAlert_upload(null);
  };

  const [pathToRedirect, setRedirect] = React.useState("");
  const [itemToEdit, setItemToEdit] = React.useState(null);

  const [data_page, set_page] = React.useState(0);
  const [data_pageCount, set_pageCount] = React.useState(50);
  const [data_isLoaded, set_isLoaded] = React.useState(false);
  const [isLoading, set_isLoading] = React.useState(false);
  const [data_dataList, setData_dataList] = React.useState(null);
  const [data_dataListCount, setData_dataListCount] = React.useState(0);
  const [localizationState, setLocalizationState] = React.useState(null);
  const [showDeletedData, setShowDeletedData] = React.useState(false);
  const [showExtendedTable, setShowExtendedTable] = React.useState(false);

  const [data_startDate, setData_startDate] = React.useState(new Date());
  const [data_endDate, setData_endDate] = React.useState(new Date());
  const [data_createFieldList, setData_createFieldList] = React.useState(null);

  const [totalProcessCount, setTotalProcessCount] = React.useState(0);
  const [currentProcessCount, setCurrentProcessCount] = React.useState(0);
  const [showExcelImport, set_showExcelImport] = React.useState(false);
  const [showExcelExport, set_showExcelExport] = React.useState(false);

  const [checked, setChecked] = React.useState([]);

  const classes_checkbox = useStyles_checkbox();

  React.useEffect(() => {
    if (localizationState == null) {
      const {
        createFieldList,
        showExcelImport,
        showExcelExport,
      } = props.location.state;
      setLocalizationState(props.location.state);
      setData_createFieldList(createFieldList);

      if (showExcelImport) {
        set_showExcelImport(showExcelImport);
      } else {
        set_showExcelImport(false);
      }

      if (showExcelExport) {
        set_showExcelExport(showExcelExport);
      } else {
        set_showExcelExport(false);
      }
    } else {
      // console.log(`Custom_list props =======>   ${JSON.stringify(props.location.state)}`)
      if (localizationState) {
        if (data_dataList != null) {
          processDataWithActions();
        }

        if (!data_isLoaded && data_pageCount >= 0 && data_page >= 0) {
          set_isLoaded(true);
          do_loadDataList();
        }
      }
    }
    // Specify how to clean up after this effect:
    return function cleanup() {};
  }, [
    data_pageCount,
    data_page,
    data_dataList,
    localizationState,
    showDeletedData,
    data_isLoaded,
    checked,
    // selectList
  ]);

  //Excel
  var xls = require("exceljs");

  const inputAlert = () => {
    setAlert_upload(
      <>
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          title={Loc.input_excel_data_handle}
          onConfirm={() => downloadTemplate()}
          onCancel={() => hideAlert()}
          confirmBtnCssClass={
            classes_alert.button + " " + classes_alert.success
          }
          cancelBtnCssClass={classes_alert.button + " " + classes_alert.danger}
          confirmBtnText={Loc.download_excel_data}
          cancelBtnText={Loc.back}
          showCancel
        >
          <input type="file" onChange={onFileChange} />
          <br />
          {Loc.or}
        </SweetAlert>
      </>
    );
  };

  function downloadTemplate(withData) {
    var csvData = [];
    var fieldNameCsvRowList = ["FIELD_NAME"];
    var fieldKeyCsvRowStr = ["FIELD_KEY"];
    var fieldTypeCsvRowStr = ["FIELD_TYPES"];

    data_createFieldList.map((fieldData) => {
      if (fieldData.isReadOnly == undefined || fieldData.isReadOnly == false)
      {
        const type = fieldData.fieldType;
        if (
          type == DataSetType.type_string ||
          type == DataSetType.type_bool ||
          type == DataSetType.type_number ||
          type == DataSetType.type_account_phone ||
          type == DataSetType.type_account_email ||
          type == DataSetType.type_account_password ||
          type == DataSetType.type_MC ||
          type == DataSetType.type_list || 
          type == DataSetType.type_multiline_text ||
          type == DataSetType.type_select_from_other_dataset_single || 
          type == DataSetType.type_firebase_geopoint
        ) {
          const { detailFieldList,fieldName,fieldKey } = fieldData;

          if (type == DataSetType.type_MC) {
            var options = "";
            detailFieldList.map((detail, index) => {
              options += detail.key;
              if (index < detailFieldList.length - 1) {
                options += ",";
              }
            });
            fieldNameCsvRowList.push(fieldName + `(${options})`);
            fieldTypeCsvRowStr.push("string");
            fieldKeyCsvRowStr.push(fieldData.fieldKey);
          }
          // else if (type == DataSetType.type_list){
            
          //   // data_dataList.map((data, index) => {
          //    if (data[fieldKey]){
          //       const targetList = data[fieldKey];
          //       fieldNameCsvRowList.push(fieldName);
          //       fieldTypeCsvRowStr.push("list");
          //       fieldKeyCsvRowStr.push(fieldKey);
                
          //       // targetList.map((target,index) => {
          //       //   fieldNameCsvRowList.push(fieldName+"_"+index);
          //       //   // fieldKeyCsvRowStr.push("")
          //       //   fieldTypeCsvRowStr.push("string");
          //       //   fieldKeyCsvRowStr.push(fieldKey+"_"+index);

          //       // })
          //     }
          //   // })

          // } 
          else {
            fieldNameCsvRowList.push(fieldData.fieldName);
            fieldTypeCsvRowStr.push(type);
            fieldKeyCsvRowStr.push(fieldData.fieldKey);

          }
        }
      }
    });

    csvData.push(fieldNameCsvRowList);
    csvData.push(fieldKeyCsvRowStr);
    csvData.push(fieldTypeCsvRowStr);

    if (withData) {
      
      data_dataList
        // .filter(v => v.isDeleted != true)
        .map((prop, key) => {
          const item_id = prop.id;
         
          // var createFieldList = localizationState.createFieldList;
          var customItemsList = [];
          
          fieldKeyCsvRowStr.map((fieldKey,_index) => {
            const _type = fieldTypeCsvRowStr[_index];
           
            // if (item.fieldType === DataSetType.type_bool) {
            //   customItemsList.push(prop[fieldKey].toString());
            // } else

              if (
                prop[fieldKey] === null ||
                prop[fieldKey] === "" ||
                prop[fieldKey] === undefined
              ) {
                customItemsList.push("");
              } else {
                if (_type == DataSetType.type_list){
                  // console.log(
                  //   "data_dataList type: " + JSON.stringify(prop[fieldKey])
                    
                  // );
                  customItemsList.push(prop[fieldKey].join(","));
                }
                else if(_type == DataSetType.type_select_from_other_dataset_single)
                {
                  const dic_selectFromOther = prop[fieldKey];
                  if (dic_selectFromOther)
                  {
                    customItemsList.push(dic_selectFromOther.targetCollection + " -> " + dic_selectFromOther.targetID);
                  }
                }
                else if( _type == DataSetType.type_firebase_geopoint)
                {
                  var map_pt = prop[fieldKey];
                  var lat = map_pt["_latitude"]
                  var lng = map_pt["_longitude"]
                  var str_pt = lat+","+lng
                  customItemsList.push(str_pt);
                }
                else
                {
                  customItemsList.push(prop[fieldKey]);
                }
              }

            
          });
          csvData.push(customItemsList);

          
        });
        // console.log("csvData  :"+JSON.stringify(csvData));
    }

    // setData_csvData(csvData)

    const options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalSeparator: ".",
      showLabels: false,
      showTitle: false,
      title: "",
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
      filename: cardTitleStr + " template",
      // headers: ['Column 1', 'Column 2', etc...] <-- Won't work with useKeysAsHeaders present!
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(csvData);
  }

  async function operation() {
    var workbook = new xls.Workbook();

    workbook.created = new Date();

    workbook.modified = new Date();

    const worksheet = workbook.addWorksheet("sheet");

    //calcualte part

    const headerEndColumn = String.fromCharCode(
      65 + (getExcelColumns().length - 1)
    );
    //merge cell
    worksheet.mergeCells("A1:" + headerEndColumn + "1");
    worksheet.mergeCells("A2:" + headerEndColumn + "2");

    worksheet.columns = getExcelColumns();

    //columns
    const titleCol = worksheet.getColumn(2);

    // // report_itemName
    titleCol.header = cardTitleStr;
    titleCol.alignment = { vertical: "middle", horizontal: "center" };

    // dateRow.value =
    //   moment(data_startDate).format("YYYY/MM/DD") +
    //   " ~ " +
    //   moment(data_endDate).format("YYYY/MM/DD"); //the dates
    // dateRow.alignment = { vertical: "middle", horizontal: "center" };

    //Fixed Items
    const rows = [getTableHeaderList(true), getEmptyStringArray()];
    // console.log("getExcelData()   "+JSON.stringify(getExcelData()))
    worksheet.addRows(rows);
    //add data part
    worksheet.addRows(getExcelData());

    // worksheet.addRows([
    //   ["", "", "", ""],
    //   [
    //     net_profit_or_lost < 0 ? Loc.report_net_loss : Loc.report_net_income,
    //     "",
    //     "",
    //     roundValue(net_profit_or_lost).toLocaleString(),
    //   ],

    // ]);

    //export excel
    const buffer = await workbook.xlsx.writeBuffer();
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const fileExtension = ".xlsx";

    const blob = new Blob([buffer], { type: fileType });

    const fileName = `${cardTitleStr}_${moment(data_startDate).format(
      "YYYY-MM-DD-hh-mm-ss"
    )}~${moment(data_endDate).format("YYYY-MM-DD-hh-mm-ss")}`;
    saveAs(blob, fileName + fileExtension);
  }

  function getExcelColumns() {
    var columns = [{ header: "", key: "number", width: 20 }];

    if (localizationState && localizationState.createFieldList !== null) {
      var createFieldList = localizationState.createFieldList;

      createFieldList.map((item) => {
        if (item.isShowInTableView) {
          columns.push({ header: "", key: item.fieldName, width: 20 });
        }
      });
    }

    return columns;
  }

  function getEmptyStringArray() {
    var strArray = [];
    if (getExcelColumns().length > 0) {
      for (let i = 0; i < getExcelColumns().length; i++) {
        strArray.push("");
      }
      return strArray;
    }
  }

  function getExcelData() {
    const newData = data_dataList.map((prop, key) => {
      var createFieldList = localizationState.createFieldList;
      var customItemsList = [];
      createFieldList.map((item) => {
        if (item.isShowInTableView) {
          if (item.fieldType === DataSetType.type_bool) {
            customItemsList.push(prop[item.fieldKey].toString());
          } else if (
            prop[item.fieldKey] === null ||
            prop[item.fieldKey] === ""
          ) {
            customItemsList.push("-");
          } else {
            customItemsList.push(prop[item.fieldKey]);
          }
        }
      });
      // console.log("customItemsList    "+JSON.stringify(customItemsList))
      var newDataList = [key + 1, ...customItemsList];

      return newDataList;
    });
    return newData;
  }

  function do_loadDataList() {
    console.log(JSON.stringify(props.location.state.collectionName));

    if (localizationState.collectionName !== null) {
      set_isLoading(true);
      const body = {
        collectionName: localizationState.collectionName,
        page: data_page,
        pageCount: data_pageCount,
        showDeletedData: showDeletedData,
        orderBy: localizationState.orderBy,
        orders: localizationState.orders
      };
      fetchAPI.do_fetch("post", "admin/list-custom", body).then(
        (res) => {
          set_isLoading(false);
          console.log("success: ", res.data);
          setData_dataList(res.data.data_list);
          setData_dataListCount(res.data.count);
        },
        (error) => {
          console.log("failed: ", error);
          set_isLoading(false);
          showAlert(false, Loc.failed, Loc.data_fetch_failed);
        }
      );
    }
  }

  function do_deleteData(targetID, uid) {
    if (localizationState.collectionName !== null) {
      var body = {
        idList: [targetID],
        collectionName: localizationState.collectionName,
        targetID: targetID,
      };
      if (uid !== null && uid !== "") {
        var list_uid = [];
        list_uid.push(uid);
        body["uidList"] = list_uid;
      }
      const path = showDeletedData
        ? "admin/recover-deleted"
        : "admin/del-custom";
      fetchAPI.do_fetch("post", path, body).then(
        (res) => {
          console.log("success: ", res.data);
          do_loadDataList();
          // showAlert(
          //   true,
          //   Loc.success,
          //   showDeletedData ? Loc.data_recover_success : Loc.data_delete_success
          // );
        },
        (error) => {
          console.log("failed: ", error);
          showAlert(
            false,
            Loc.failed,
            showDeletedData ? Loc.data_recover_failed : Loc.data_delete_failed
          );
        }
      );
    }
  }

  function del_multi_doc(checkedList, onHoldList) {
    if (checked.length > 0) {
      var newList = checkedList;
      var newOnHoldList = onHoldList;

      const targetID = newList[0];

      return new Promise((onDone) => {
        // if (localizationState.collectionName !== null) {
        // var body = {
        //   idList: newList,
        //   collectionName: localizationState.collectionName,
        // };

        // const path = "admin/del-custom";
        // fetchAPI.do_fetch("post", path, body).then(
        //   (res) => {
        //     console.log("del_multi_doc: " + targetID);
        do_deleteData(targetID, []);
        newList.splice(0, 1);
        if (newList.length > 0) {
          del_multi_doc(newList, newOnHoldList).then((finalOnHoldList) => {
            onDone(finalOnHoldList);
          });
        } else {
          onDone(newOnHoldList);
        }
        // }
        // ,
        // (error) => {
        //   console.log("failed: ", error);
        //   showAlert(
        //     false,
        //     Loc.failed,
        //     showDeletedData
        //       ? Loc.data_recover_failed
        //       : Loc.data_delete_failed
        //   );
        // }
        // );
        // }
      });
    }
  }


  const handleToggle = (value) => {
    const currentIndex = checked.indexOf(value);
    var newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
    console.log("handleToggle  called  " + JSON.stringify(newChecked));
  };

  function getMCString(createFieldItem,key){

    const targetList = createFieldItem.detailFieldList;
    var targetObj = targetList.find(data => data.key === key);
    var displayStr = targetObj ? targetObj.value:""; 

    return displayStr;
  }

  function processDataWithActions() {
    // console.log("processDataWithActions  called  "+JSON.stringify(checked))
    var _selectList = {};
    data_dataList.map((data) => {
      _selectList[data.id] = false;
    });

    // console.log("_selectedList  " + JSON.stringify(_selectList))
    // setSelectList(_selectList)

    const newData = data_dataList
      // .filter(v => v.isDeleted != true)
      .map((prop, key) => {
        const item_id = prop.id;

        var createFieldList = localizationState.createFieldList;
        var customItemsList = [];
        createFieldList.map((item) => {
          if (item.isShowInTableView) {
            if (item.fieldType === DataSetType.type_bool) {

              customItemsList.push(prop[item.fieldKey].toString());

            } else if (item.fieldType === DataSetType.type_MC) {
              customItemsList.push(getMCString(item,prop[item.fieldKey]));
              // console.log("DataSetType.type_MC  " + JSON.stringify(getMCString(item,prop[item.fieldKey].toString())))

            } else if (item.fieldType === DataSetType.type_list || item.fieldType === DataSetType.type_dragdrop_upload) {
              const targetList = prop[item.fieldKey];
              if (targetList){

                const listUI = targetList.map((text) => {
                  
                    return(<GridItem xs={12} key={text}>
                  {/* <p> */}
                    {text}
                   {/* </p> */}
                  </GridItem>)
    
                  
                });
                customItemsList.push(listUI)
              }else{
                customItemsList.push("-");
              }
            }
            else if (item.fieldType == DataSetType.type_firebase_geopoint)
            {
              var map_pt = prop[item.fieldKey];
              var lat = map_pt["_latitude"]
              var lng = map_pt["_longitude"]
              var str_pt = lat+","+lng
              customItemsList.push(str_pt);
            }
            else if (item.fieldType == DataSetType.type_select_from_other_dataset_single)
            {
              const dic_selectFromOther = prop[item.fieldKey];
              if (dic_selectFromOther)
              {
                customItemsList.push(dic_selectFromOther.targetCollection + " -> " + dic_selectFromOther.targetID);
              }
            }
                else if (
              prop[item.fieldKey] === null ||
              prop[item.fieldKey] === ""
            ) {
              customItemsList.push("-");
            } else {
              customItemsList.push(prop[item.fieldKey]);
            }
          }
        });
        var newDataList = [
          <GridItem xs={12} sm={6}>
            <div
              className={
                classes_checkbox.checkboxAndRadio +
                " " +
                classes_checkbox.checkboxAndRadioHorizontal
              }
            >
              <FormControlLabel
                control={
                  <Checkbox
                    key={key}
                    className={classes.positionAbsolute}
                    tabIndex={-1}
                    onClick={() => handleToggle(item_id)}
                    checked={checked.indexOf(item_id) != -1}
                    checkedIcon={
                      <Check className={classes_checkbox.checkedIcon} />
                    }
                    icon={<Check className={classes_checkbox.uncheckedIcon} />}
                    classes={{
                      checked: classes_checkbox.checked,
                      root: classes_checkbox.checkRoot,
                    }}
                  />
                }
              />
            </div>
          </GridItem>,
          key + 1,
          ...customItemsList,
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a edit kind of action */}
            <Button
              justIcon
              round
              simple
              onClick={() => {
                // var state = {...props.location.state,data:prop}
                var state = {
                  data: prop,
                  config: localizationState,
                };

                setItemToEdit(state);
                setRedirect(`${props.location.pathname}-new`);
              }}
              color="warning"
              className="edit"
            >
              <Dvr />
            </Button>{" "}
            {/* use this button to remove the data row */}
            <Button
              justIcon
              round
              simple
              onClick={() => {
                showConfirmDeleteAlert(item_id, prop.uid);
              }}
              color="danger"
              className="remove"
            >
              {showDeletedData ? <Recover /> : <Close />}
            </Button>{" "}
          </div>,
        ];

        return newDataList;
      });

    setData(newData);
  }

  function showConfirmDeleteAlert(targetID, uid) {
    setAlert_confirm(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        closeOnClickOutside={false}
        title={
          showDeletedData ? Loc.confirm_recover_title : Loc.confirm_delete_title
        }
        onConfirm={() => {
          hideAlert();
          do_deleteData(targetID, uid);
        }}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        cancelBtnCssClass={classes_alert.button + " " + classes_alert.danger}
        confirmBtnText={
          showDeletedData
            ? Loc.confirm_recover_confirm
            : Loc.confirm_delete_confirm
        }
        cancelBtnText={Loc.cancel}
        showCancel
      >
        {Loc.are_you_sure}
      </SweetAlert>
    );
  }

  function showAlert(issuccess, title, content) {
    setAlert_info(
      <SweetAlert
        error={!issuccess}
        success={issuccess}
        closeOnClickOutside={false}
        style={{ display: "block", marginTop: "-100px" }}
        title={title}
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnText={Loc.confirm}
        confirmBtnCssClass={classes_alert.button + " " + classes_alert.success}
        btnSize="lg"
      >
        {content}
      </SweetAlert>
    );
  }

  const check_redirect = () => {
    if (pathToRedirect != "") {
      if (itemToEdit != null) {
        return (
          <Redirect
            to={{
              pathname: pathToRedirect,
              state: itemToEdit,
            }}
          />
        );
      } else {
        return <Redirect to={pathToRedirect} />;
      }
    } else {
      return null;
    }
  };
  const showloading = () => {
    var processPercentage = Math.ceil(
      (currentProcessCount / totalProcessCount) * 100
    );
    if (isLoading) {
      return (
        <SweetAlert
          style={{ display: "block", marginTop: "-100px" }}
          closeOnClickOutside={false}
          title={Loc.loading}
          onConfirm={() => hideAlert()}
          onCancel={() => hideAlert()}
          confirmBtnText={Loc.confirm}
          confirmBtnCssClass={
            classes_alert.button + " " + classes_alert.success
          }
          btnSize="lg"
          showConfirm={false}
          showCancel={false}
        >
          {Loc.please_wait}
          <br />
          {isNaN(processPercentage) ? "" : processPercentage + " %"}
        </SweetAlert>
      );
    } else {
      return null;
    }
  };

  function getTableHeaderList(withoutAction) {
    var tableHeaderList = ["", "#"];

    if (localizationState && localizationState.createFieldList !== null) {
      var createFieldList = localizationState.createFieldList;
      // console.log(`customFieldList  ${JSON.stringify(data_dataList)}`)
      createFieldList.map((item) => {
        if (item.isShowInTableView) {
          tableHeaderList.push(item.fieldName);
        }
      });
    }
    if (!withoutAction || withoutAction == null) {
      tableHeaderList.push(Loc.actions);
    }

    return tableHeaderList;
  }

  function getCustomCellClassesList() {
    var customHeadCellClassesList = [classes_tableBtn.left];

    if (localizationState && localizationState.createFieldList != null) {
      var createFieldList = localizationState.createFieldList;
      createFieldList.map((item) => {
        if (item.isShowInTableView) {
          customHeadCellClassesList.push(classes_tableBtn.center);
        }
      });
      customHeadCellClassesList.push(classes_tableBtn.right);
    }

    return customHeadCellClassesList;
  }

  function getCustomCellStylePos() {
    var customHeaderCellPos = [0];
    if (localizationState && localizationState.createFieldList != null) {
      const len = localizationState.createFieldList.length;
      for (var i = 0; i <= len; i++) {
        customHeaderCellPos.push(i + 1);
      }
    }
    return customHeaderCellPos;
  }

  const _renderSearchBar = () => {
    var searchFieldList = [
      {
        itemName: Loc.search_by_date,
        id: "date",
        key: ["createDate"],
        type: "date",
        isPreloadField: false,
      },
    ];

    if (localizationState && localizationState !== null) {
      var createFieldList = localizationState.createFieldList;

      createFieldList.map((data) => {
        const { fieldName, fieldKey, fieldType, isShowInTableView } = data;
        var _fieldKey = fieldKey == "uid" ? "u_i_d":fieldKey;
        var customSearchField = {
          itemName: fieldName,
          id: _fieldKey,
          key: [_fieldKey],
          type: fieldType,
          isPreloadField: false,
          isCustomField: true,
        };
        if (isShowInTableView) {
          searchFieldList.push(customSearchField);
        }
      });

      console.log("render search bar " + JSON.stringify(searchFieldList));

      return (
        <SearchBar
          searchFieldList={searchFieldList}
          apiPath={"admin/list-custom"}
          loadDataList={() => do_loadDataList()}
          searchResultDataList={(dataList) => {
            setData_dataList(dataList);
          }}
          searchResultDataCount={(count) => {
            setData_dataListCount(count);
          }}
          showDeletedData={showDeletedData}
          locationState={localizationState}
          didSelectedShowDeletedData={(_showDeletedData) => {
            setShowDeletedData(_showDeletedData);
            set_isLoaded(false);
          }}
          showExtendedTable={(bool) => {
            setShowExtendedTable(bool);
          }}
          searchPeriod={(from, to) => {
            setData_startDate(from);
            setData_endDate(to);
          }}
          collectionName={localizationState.collectionName}
        />
      );
    }
  };

  function _renderPagingTable() {
    return (
      <PagingTable
        tableHead={getTableHeaderList()}
        tableData={data}
        customCellClasses={getCustomCellClassesList()}
        customClassesForCells={getCustomCellStylePos()}
        customHeadCellClasses={getCustomCellClassesList()}
        customHeadClassesForCells={getCustomCellStylePos()}
        gotoPageClicked={(targetPage) => {
          set_page(targetPage);
          set_isLoaded(false);
        }}
        changedPageCount={(newPageCount) => {
          set_page(0);
          set_pageCount(newPageCount);
          set_isLoaded(false);
        }}
        totalDataCount={data_dataListCount}
        page={data_page}
        pageCount={data_pageCount}
      />
    );
  }

  function _renderExtendedTable() {
    return (
      <Table
        tableHead={getTableHeaderList()}
        tableData={data}
        customCellClasses={getCustomCellClassesList()}
        customClassesForCells={getCustomCellStylePos()}
        customHeadCellClasses={getCustomCellClassesList()}
        customHeadClassesForCells={getCustomCellStylePos()}
      />
    );
  }

  const updateData = (result) => {
    var dataList = result.data;
    console.log("csv updated data => "+JSON.stringify(dataList));

    //get data from row four
    const uploadTimes = Math.ceil((dataList.length - 2) / 100);
    // console.log("csv uploadTimes => "+uploadTimes);

    var rowList = [];
    for (var i = 0; i < uploadTimes; i++) {
      rowList.push(i * 100);
    }

    var headerMap = [null];
    var typeMap = {};

    const dataKeys = dataList[0];
    const dataTypes = dataList[1];
    var index = 0;
    for (const key in dataKeys) {
      const dataType = dataTypes[key];

      if (index > 0) {
        if (isVaildField(dataType)) {
          headerMap[index] = dataKeys[key];
          typeMap[dataKeys[key]] = dataType;
        } else {
          headerMap[index] = null;
          typeMap[dataKeys[key]] = null;
        }
      }

      index++;
    }
    // console.log(`typeMap: ${JSON.stringify(typeMap)}`)
    var csvObjList = [];
    // console.log("dataList  "+JSON.stringify(dataList[0]))

    // console.log("dataList.length: " + dataList.length);
    for (var i = 2; i < dataList.length; i++) {
      var data = dataList[i];
      var csvObj = {};

      var index = 0;
      for (const key in data) {
        var _key = headerMap[index];
        // var _type = typeMap[index]
        if (_key != null) {
          // console.log("csv _key => "+_key);
          // if(isVaildField(_type)){
          const dataType = dataTypes[key];
          // console.log("dataType: " + dataType);
          var data_content = data[key];
          if(dataType == DataSetType.type_number)
          {
            data_content = parseFloat(data[key]);
          }
          else if (dataType == DataSetType.type_list)
          {
            const targetData = data[key]; // should be strings
            data_content = targetData.split(",");
          }
          else if (dataType == DataSetType.type_firebase_geopoint)
          {
            const targetData = data[key];
            var arr = targetData.split(",");
            data_content = {
              "isGeoPt": true,
              "_latitude": parseFloat(arr[0]),
              "_longitude": parseFloat(arr[1])
            }
          }
          else if (dataType == DataSetType.type_select_from_other_dataset_single)
          {
            // console.log("is type_select_from_other_dataset_single");
            const targetData = data[key];
            var arr = targetData.split(" -> ");
            data_content = {
              "targetCollection": arr[0],
              "targetID": arr[1]
            };

            // console.log(`checking selectFromOtherTable: ${JSON.stringify(data_content)}`);
          } else if (dataType == DataSetType.type_bool) {
            var isTrueSet = (data[key].toLowerCase() === 'true');
            data_content = isTrueSet;
          }

          csvObj[_key] = data_content;
        }
        index++;
      }

      
      if (Object.keys(csvObj).length != 0)
      {
        csvObjList.push(csvObj);
      }
      
      console.log(`csvObjList: ${JSON.stringify(csvObjList)}`);
      // pureDataList.push(data)
    }
    // console.log("csv data => "+JSON.stringify(csvObjList)+" uploadTimes: "+(dataList.length));

    if (uploadTimes > 0) {
      setTotalProcessCount(uploadTimes);
      set_isLoading(true);
      uploadCsvDatas(csvObjList, rowList, [], 0, typeMap).then((item) => {
        set_isLoading(false);
        setCurrentProcessCount(0);
        setTotalProcessCount(0);
        set_isLoaded(false);
      });
    }

    // console.log("csv csvObjList => "+JSON.stringify(csvObjList));
  };

  function isVaildField(type) {
    var isVaild = false;

    if (
      type == DataSetType.type_string ||
      type == DataSetType.type_bool ||
      type == DataSetType.type_number ||
      type == DataSetType.type_account_phone ||
      type == DataSetType.type_account_email ||
      type == DataSetType.type_account_password ||
      type == DataSetType.type_list ||
      type == DataSetType.type_multiline_text ||
      type == DataSetType.type_select_from_other_dataset_single ||
      type == DataSetType.type_firebase_geopoint
    ) {
      isVaild = true;
    }

    return isVaild;
  }

  const handle_firebaseUserRegister = (
    data_account_email,
    data_account_password,
    data_account_phone,
    callback
  ) => {
    console.log("handle_firebaseUserRegister called");
    const body = {
      id: null,
      content: {
        email: data_account_email === "" ? null : data_account_email,
        password: data_account_password === "" ? null : data_account_password,
        phone: data_account_phone == "" ? null : data_account_phone,
      },
    };

    // console.log(`body ${JSON.stringify(body)}`)

    fetchAPI.do_fetch("post", "admin/register-user", body).then(
      (res) => {
        console.log("success: ", res);
        callback(res.data.uid);
      },
      (error) => {
        callback(null);
        console.log("failed: ", error);
      }
    );
  };

  function batchProcess_firebaseRegister(list_org, list_processed, typeMap) {
    return new Promise((onDone) => {
      var newDataList = list_org;
      if (newDataList.length > 0) {
        var newData = newDataList[0];
        newDataList.splice(0, 1);
        var email = "";
        var password = "";
        var phone = "";

        for (const _key in newData) {
          // const value = data[_key]
          const type = typeMap[_key];
          if (type == DataSetType.type_account_phone) {
            phone = newData[_key];
          }

          if (type == DataSetType.type_account_email) {
            email = newData[_key];
          }

          if (type == DataSetType.type_account_password) {
            password = newData[_key];
          }
        }

        handle_firebaseUserRegister(email, password, phone, (uid) => {
          if (uid !== null) {
            console.log("uid: " + uid);
            newData["uid"] = uid;
            list_processed.push(newData);
          }

          if (newDataList.length > 0) {
            return batchProcess_firebaseRegister(
              newDataList,
              list_processed,
              typeMap).then((final_list_processed) => {
                onDone(final_list_processed);
              });
          } 
          else 
          {
            onDone(list_processed);
          }
        });
      }
    });
  }

  function handle_insertToDatabase(
    newDataList,
    processDataList,
    rowList,
    onHoldList,
    _currentProcessCount,
    typeMap
  ) {
    console.log("handle_insertToDatabase called");
    return new Promise((onDone) => {
      var newProcessCount = _currentProcessCount;
      var newList = rowList;
      var newOnHoldList = onHoldList;
      var newProcessCount = _currentProcessCount;

      const body = {
        collectionName: localizationState.collectionName,
        contentList: newDataList,
      };
      fetchAPI.do_fetch("post", "admin/create-custom-process", body).then(
        (res) => {
          newProcessCount = newProcessCount + 1;
          setCurrentProcessCount(newProcessCount);
          newList.splice(0, 1);

          if (newList.length > 0) {
            // var currentListCount = newList.length
            uploadCsvDatas(
              processDataList,
              newList,
              newOnHoldList,
              newProcessCount,
              typeMap
            ).then((finalOnHoldList) => {
              onDone(finalOnHoldList);
            });
          } else {
            // setCurrentProcessCount(newProcessCount)
            onDone(newProcessCount);
          }
        },
        (error) => {
          console.log("failed: ", error);
          set_isLoading(false);
          showAlert(false, Loc.failed, Loc.data_fetch_failed);
        }
      );
    });
  }

  function uploadCsvDatas(
    processDataList,
    rowList,
    onHoldList,
    _currentProcessCount,
    typeMap
  ) {
    return new Promise((onDone) => {
      var newList = rowList;

      var newDataList = [];

      for (var i = newList[0]; i < newList[0] + 100; i++) {
        if (i < processDataList.length) {
          var data = processDataList[i];
          newDataList.push(data);
        }
      }
      var list_to_process_firebase = [];
      // console.log("batchProcess_firebaseRegister newDataList "+JSON.stringify(newDataList));
      var dataListForImport = [];
      newDataList.map((data) => {
        var email = "";
        var password = "";
        var phone = "";

        for (const _key in data) {
          // const value = data[_key]
          const type = typeMap[_key];
          if (type == DataSetType.type_account_phone) {
            phone = data[_key];
          }

          if (type == DataSetType.type_account_email) {
            email = data[_key];
          }

          if (type == DataSetType.type_account_password) {
            password = data[_key];
          }
        }
        // check and process firebase register
        if ((email != "" && password != "") || phone != "") {
          list_to_process_firebase.push(data);
        } else {
          dataListForImport.push(data);
        }

        console.log("list_to_process_firebase.length: "+list_to_process_firebase.length);
        console.log(`list_to_process_firebase: ${JSON.stringify(list_to_process_firebase)}`)
      });
      // console.log("dataListForImport: "+JSON.stringify);

      if (list_to_process_firebase.length > 0) {
        batchProcess_firebaseRegister(
          list_to_process_firebase,
          [],
          typeMap
        ).then((list_finished) => {
          console.log(`list_finished: ${JSON.stringify(list_finished)}`)
          // newDataList = {...newDataList, ...list_finished};
          // list_finished.forEach(d => {
          //   newDataList.push(d);
          // });
          console.log("list_finished: " + list_finished);
          dataListForImport = list_finished;
          handle_insertToDatabase(
            dataListForImport,
            processDataList,
            rowList,
            onHoldList,
            _currentProcessCount,
            typeMap
          ).then((res) => {
            onDone(res);
          });
        });
      } else {
        // console.log("handle_insertToDatabase: "+ dataListForImport.length);

        handle_insertToDatabase(
          dataListForImport,
          processDataList,
          rowList,
          onHoldList,
          _currentProcessCount,
          typeMap
        ).then((res) => {
          onDone(res);
        });
      }
    });
  }

  const onFileChange = (event) => {
    // Update the state
    // this.setState({ selectedFile: event.target.files[0] });
    // const fileList = event.target.files;
    // console.log("fileList  :"+fileList)
    const csvfile = event.target.files[0];
    if (csvfile) {
      hideAlert();
      Papa.parse(csvfile, {
        complete: updateData,
        header: true,
      });
    }
    // fileList.map(csvfile=>{

    // })
  };

  const [data, setData] = React.useState([]);
  const classes = useStyles();
  const classes_alert = useStyles_alert();
  const classes_tableBtn = useStyles_tableBtn();

  var cardTitleStr = "";
  if (localizationState && localizationState.localizationKey !== null) {
    cardTitleStr = localizationState.localizationKey;
    if (Loc.getString(localizationState.localizationKey) !== null) {
      cardTitleStr = Loc.getString(localizationState.localizationKey);
    }
  }
  return (
    <div>
      {check_redirect()}
      {showloading()}
      {alert_info}
      {alert_confirm}
      {alert_upload}
      <GridContainer>
        <GridItem xs={12}>
          <Card>
            <CardHeader color="primary" icon>
              <CardIcon color="primary">
                <Assignment />
              </CardIcon>
              <h4 className={classes.cardIconTitle}>{cardTitleStr}</h4>
            </CardHeader>
            <CardBody>
              <Button
                color="success"
                round
                onClick={() => {
                  if (localizationState) {
                    setItemToEdit({
                      data: null,
                      config: localizationState,
                    });
                    setRedirect(`${props.location.pathname}-new`);
                  }
                }}
              >
                <ICON_ADD />
                {Loc.add_new}
              </Button>
              {showExcelExport && (
                <Button
                  color="success"
                  round
                  onClick={() => {
                    // operation();
                    downloadTemplate(true);
                  }}
                >
                  {Loc.download_excel}
                </Button>
              )}

              {/* <button onClick={this.onFileUpload}> 
                  Upload! 
                </button>  */}
              {showExcelImport && (
                <Button color="rose" round onClick={inputAlert}>
                  {Loc.input_excel_data_handle}
                </Button>
              )}
              <Button
                color="rose"
                round
                onClick={() => {
                  var newChecked = [];
                  data_dataList.map((item) => {
                    const id = item.id;
                    newChecked.push(id);
                  });
                  setChecked(newChecked);
                  set_isLoaded(false);
                }}
              >
                {Loc.select_all_data}
              </Button>
              <Button
                color="rose"
                round
                onClick={() => {
                  set_isLoading(true);
                  del_multi_doc(checked, []).then((list) => {
                    console.log("delete all selected data");

                    setChecked([]);
                    set_isLoaded(false);
                  });
                }}
              >
                {Loc.delete_selected_data}
              </Button>

              {_renderSearchBar()}
              {showExtendedTable
                ? _renderExtendedTable()
                : _renderPagingTable()}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
